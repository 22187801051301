import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";

import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";

import { useNavigate } from "react-router-dom";

const DashboardSAdmin = () => {
  // const history = useNavigate();
  // const islogin = localStorage.getItem("login");
  // console.log(islogin);
  // if (islogin !== true) {
  //   history(`${process.env.PUBLIC_URL}/login`);
  // }
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Default" parent="Dashboard" title="Default" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DashboardSAdmin;
