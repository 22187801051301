import React, { Fragment, useState, useEffect } from 'react';
import { Btn, H4, Spinner } from '../../../AbstractElements';
import { Card, CardBody, Col, Table, Form, FormGroup, Label, Row, Modal, Button, CardHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../../config';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import configDB from '../../../Config/ThemeConfig';

const HomeView = () => {

    const token = localStorage.getItem("sessiontoken");
    const tenant_id = localStorage.getItem("tenantId");
    const currentYear = new Date().getFullYear();
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const [type, setType] = useState('monthly')
    const [email, setEmail] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [concessionaireId, setConcessionaireId] = useState('');
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedTenantId, setSelectedTenantId] = useState('');
    const [selectedStoreName, setSelectedStoreName] = useState('');
    const [selectedTcode, setSelectedTcode] = useState('');
    const [selectedUnitCode, setSelectedUnitCode] = useState('');
    const [selectedConcessionaireCode, setSelectedConcessionaireCode] = useState('');
    const [selectedMallCode, setSelectedMallCode] = useState('');
    const [tenantLiveDate, setTenantLiveDate] = useState('');
    const [tenantLiveDateObj, setTenantLiveDateObj] = useState('');
    const [stores, setStores] = useState([]);
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [manualFlag, setManualFlag] = useState(false);
    const [monthlyData, setMonthlyData] = useState([]);
    const [dailyData, setDailyData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
    // const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios.get(`${config.hostname}/tenant/${tenant_id}`, configheader)
            .then((response) => {
                setEmail(response.data.tenant_email_id)
                setConcessionaireId(response.data.tenant_concessionaire_id._id)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        if (email !== '' && concessionaireId !== '') {
            const data = {
                email_id: email,
                concessionaire_id: concessionaireId
            }
            axios.post(`${config.hostname}/tenant/gettenantsbyemailid`, data, configheader)
                .then((response) => {
                    setStores(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, [email, concessionaireId]);

    useEffect(() => {
        const data = {
            tenant_id: selectedTenantId,
            year: selectedYear
        }
        axios.post(`${config.hostname}/tenanthome/getmonthlysales`, data, configheader)
            .then((response) => {
                setMonthlyData(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [selectedYear, selectedTenantId])

    const getDailySales = () => {
        if (selectedTenantId !== '' && selectedMonth !== '' && selectedYear !== '') {
            const data = {
                tenant_id: selectedTenantId,
                month: selectedMonth,
                year: selectedYear
            }
            axios.post(`${config.hostname}/tenanthome/getdailysales`, data, configheader)
                .then((response) => {
                    setDailyData(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const getDeliveryPartner = (tenantId) => {
        setDeliveryPartners([])
        axios.post(`${config.hostname}/tenantdeliverypartner/getdeliverypartner`, { tenant_id: tenantId }, configheader)
            .then((response) => {
                const deliveryPartnerNames = response.data.map(item => item.delivery_partner_id.delivery_partner_name)
                // .filter(name => name !== "Dine In");
                setDeliveryPartners(deliveryPartnerNames);

            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        getDailySales();
    }, [selectedYear, selectedMonth, selectedTenantId])

    const handleTypeChange = (e) => {
        setType(e.target.value)
        // setMonthlyData([]);
        setSelectedMonth('')
        setDailyData([])
    }

    const handleStoreChange = (e) => {
        const storeId = e.target.value
        setSelectedTenantId(storeId)
        if (storeId !== '') {
            const manualFlagEnabled = stores.find(store => store._id === storeId).tenant_manual_flag;
            setManualFlag(manualFlagEnabled)
            const storeName = stores.find(store => store._id === storeId).tenant_store_name;
            setSelectedStoreName(storeName)
            const tcode = stores.find(store => store._id === storeId).tenant_code;
            setSelectedTcode(tcode)
            const unitCode = stores.find(store => store._id === storeId).tenant_unit_code;
            setSelectedUnitCode(unitCode)
            const concessionaireCode = stores.find(store => store._id === storeId).tenant_concessionaire_code;
            setSelectedConcessionaireCode(concessionaireCode)
            const mallCode = stores.find(store => store._id === storeId).tenant_mall_code;
            setSelectedMallCode(mallCode)
            const apikey = stores.find(store => store._id === storeId).tenant_api_key;
            setApiKey(apikey)
            const livedate = stores.find(store => store._id === storeId).tenant_live_date;
            setTenantLiveDate(livedate);
            const liveDateObj = new Date(livedate);
            setTenantLiveDateObj(liveDateObj)
            getDeliveryPartner(storeId);
        }
    }

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value)
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value)
    }

    const handleUpdateClick = (transaction) => {
        setSelectedTransaction(transaction)
        setIsModalOpen(true); // Show modal
    };

    // Function to handle modal close
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const updateSales = async (e) => {
        e.preventDefault();
        let isValid = true;
        let errorMessage = "";

        // Check the main transaction count and total sales
        const mainTransactionCount = Number(selectedTransaction.transaction_count) || 0;
        const mainTotalSales = Number(selectedTransaction.total_sales) || 0;

        if (mainTransactionCount === 0 && mainTotalSales !== 0) {
            isValid = false;
            errorMessage = "If the transaction count is 0, the net sales must be 0.";
        }

        // Loop through the delivery partners to check the counts and corresponding sales
        deliveryPartners.forEach((partner) => {
            const partnerKey = partner.toLowerCase().replace(/ /g, '_');
            const countKey = `${partnerKey}_count`;
            const salesKey = `${partnerKey}_sales`;

            const count = Number(selectedTransaction[countKey]) || 0;
            const sales = Number(selectedTransaction[salesKey]) || 0;

            if (count === 0 && sales !== 0) {
                isValid = false;
                errorMessage = `For ${partner.replace('_', ' ')}, if the count is 0, the sales must be 0.`;
            }
        });

        if (!isValid) {
            // Show an error message if validation fails
            toast.warning(errorMessage);
            return; // Stop the function if validation fails
        }

        const [year, month, day] = selectedTransaction.transaction_date.split('-'); // Split by '-'
        const formattedDate = new Date(`${year}-${month}-${day}T00:00:00Z`).toISOString();


        const authorization = {
            headers: {
                'x-api-key': apiKey
            }
        };

        if (deliveryPartners.length <= 0) {
            const data = {
                concessionaire_code: selectedConcessionaireCode,
                tenant_code: selectedTcode,
                outlet_code: selectedUnitCode,
                mall_code: selectedMallCode,
                transaction_date: formattedDate,
                transaction_count: selectedTransaction.transaction_count,
                net_sales: selectedTransaction.total_sales
            }

            axios.post(`${config.hostname}/salesTransactions`, data, authorization)
                .then(() => {
                    toast.success('Data updated successfully')
                    toggleModal();
                    getDailySales();
                })
                .catch((error) => {
                    toast.error('Error')
                    console.log(error);
                });
        }

        if (deliveryPartners.length > 0) {
            const fbData = {
                concessionaire_code: selectedConcessionaireCode,
                tenant_code: selectedTcode,
                outlet_code: selectedUnitCode,
                mall_code: selectedMallCode,
                transaction_date: formattedDate,
            }
            deliveryPartners.forEach((partner) => {
                const partnerKey = partner.toLowerCase().replace(/ /g, '_');
                fbData[`${partnerKey}_sales`] = selectedTransaction[`${partnerKey}_sales`] || 0;
                fbData[`${partnerKey}_count`] = selectedTransaction[`${partnerKey}_count`] || 0;
            });

            axios.post(`${config.hostname}/deliverypartnersales`, fbData, authorization)
                .then(() => {
                    toast.success('Data updated successfully')
                    toggleModal();
                    getDailySales();
                })
                .catch((error) => {
                    toast.error('Error')
                    console.log(error);
                });
        }


    }

    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        fontSize: '18px', // Make the font bigger
        marginBottom: '20px',
    };

    const thStyle = {
        backgroundColor: primary,
        color: 'white',
        padding: '12px',
        textAlign: 'left',
        fontWeight: 'bold',
    };

    const tdStyle = {
        padding: '12px',
        border: '1px solid #ddd',
    };

    const trStyle = {
        backgroundColor: '#f2f2f2',
    };


    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <FormGroup className="mb-3">
                                <Label htmlFor="property_id">Type</Label>
                                <select name="property_id" id="property_id" className="form-control" value={type}
                                    onChange={handleTypeChange}>
                                    <option value="">Select Type</option>
                                    <option value="daily">Daily</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-3">
                                <Label htmlFor="property_id">Store</Label>
                                <select name="property_id" id="property_id" className="form-control"
                                    onChange={handleStoreChange}>
                                    <option value="">Select Store</option>
                                    {stores.map(store => (
                                        <option key={store._id} value={store._id}>{store.tenant_store_name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="mb-3">
                                <Label htmlFor="year">Year</Label>
                                <select name="year" id="year" className="form-control digits"
                                    onChange={handleYearChange}>
                                    <option value={currentYear}>{currentYear}</option>
                                    <option value={currentYear - 1}>{currentYear - 1}</option>
                                    <option value={currentYear - 2}>{currentYear - 2}</option>
                                </select>
                            </FormGroup>
                        </Col>
                        <Col>
                            {type === 'daily' && (
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Month</Label>
                                    <select name="property_id" id="property_id" className="form-control" value={selectedMonth}
                                        onChange={handleMonthChange}>
                                        <option value="">Select Month</option>
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Col sm="12" lg="12" xl="12">
                        <div className="table-responsive" >
                            <Table style={tableStyle}>
                                <thead >
                                    <tr>
                                        <th style={thStyle} scope="col" >Store Name</th>
                                        <th style={thStyle} scope="col" >Tenant Code </th>
                                        <th style={thStyle} scope="col" >{type === 'monthly' ? 'Month' : 'Date'} </th>
                                        <th style={thStyle} scope="col">Transaction Count </th>
                                        <th style={thStyle} scope="col" >Sales </th>
                                        {(type === 'daily' && manualFlag) && (
                                            <th style={thStyle} scope="col" > Action </th>
                                        )}
                                    </tr>
                                </thead>
                                {type === 'monthly' && (
                                    <tbody >
                                        {monthlyData.map((item, index) => (
                                            <tr key={index}>
                                                <td style={tdStyle}>{item.tenant_name}</td>
                                                <td style={tdStyle}>{item.tenant_code}</td>
                                                <td style={tdStyle}>{item.month}</td>
                                                <td style={tdStyle}>{item.transaction_count}</td>
                                                <td style={tdStyle}>{item.total_sales.toFixed(2)}</td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                )}

                                {type === 'daily' && (
                                    <tbody >
                                        {dailyData.map((item, index) => (
                                            <tr key={index}>
                                                <td style={tdStyle}>{item.tenant_name || selectedStoreName} </td>
                                                <td style={tdStyle}>{item.tenant_code || selectedTcode}</td>
                                                <td style={tdStyle}>{item.transaction_date}</td>
                                                <td style={tdStyle}>{item.transaction_count}</td>
                                                <td style={tdStyle}>{item.total_sales !== '' && item.total_sales.toFixed(2)}</td>
                                                {(type === 'daily' && manualFlag) && (
                                                    <td>

                                                        <button
                                                            style={{
                                                                marginLeft: '10px',
                                                                backgroundColor: ((item.transaction_count !== undefined && item.transaction_count !== null && item.transaction_count !== '' &&
                                                                    item.total_sales !== undefined && item.total_sales !== null && item.total_sales !== '') ||
                                                                    // new Date(item.transaction_date).setHours(0, 0, 0, 0) < tenantLiveDateObj.setHours(0, 0, 0, 0) ||
                                                                    new Date(item.transaction_date).setHours(0, 0, 0, 0) >= currentDate)
                                                                    ? 'grey'  // Grey when disabled
                                                                    : primary,
                                                                color: ((item.transaction_count !== undefined && item.transaction_count !== null && item.transaction_count !== '' &&
                                                                    item.total_sales !== undefined && item.total_sales !== null && item.total_sales !== '') ||
                                                                    // new Date(item.transaction_date).setHours(0, 0, 0, 0) < tenantLiveDateObj.setHours(0, 0, 0, 0) ||
                                                                    new Date(item.transaction_date).setHours(0, 0, 0, 0) >= currentDate)
                                                                    ? '#ffffff'
                                                                    : '#ffffff',
                                                                border: 'none',
                                                                padding: '5px 10px',
                                                                cursor: ((item.transaction_count !== undefined && item.transaction_count !== null && item.transaction_count !== '' &&
                                                                    item.total_sales !== undefined && item.total_sales !== null && item.total_sales !== '') ||
                                                                    // new Date(item.transaction_date).setHours(0, 0, 0, 0) < tenantLiveDateObj.setHours(0, 0, 0, 0) ||
                                                                    new Date(item.transaction_date).setHours(0, 0, 0, 0) >= currentDate)
                                                                    ? 'not-allowed'
                                                                    : 'pointer', // Not-allowed cursor when disabled
                                                                opacity: ((item.transaction_count !== undefined && item.transaction_count !== null && item.transaction_count !== '' &&
                                                                    item.total_sales !== undefined && item.total_sales !== null && item.total_sales !== '') ||
                                                                    // new Date(item.transaction_date).setHours(0, 0, 0, 0) < tenantLiveDateObj.setHours(0, 0, 0, 0) ||
                                                                    new Date(item.transaction_date).setHours(0, 0, 0, 0) >= currentDate)
                                                                    ? 0.6
                                                                    : 1, // Slightly transparent when disabled
                                                            }}
                                                            className="fa fa-pencil btn-sm"
                                                            type="button"

                                                            disabled={(item.transaction_count !== undefined && item.transaction_count !== null && item.transaction_count !== '' &&
                                                                item.total_sales !== undefined && item.total_sales !== null && item.total_sales !== '') ||
                                                                // new Date(item.transaction_date).setHours(0, 0, 0, 0) < tenantLiveDateObj.setHours(0, 0, 0, 0) ||    //disble dates before live date
                                                                new Date(item.transaction_date).setHours(0, 0, 0, 0) >= currentDate}
                                                            onClick={() => handleUpdateClick(item)}
                                                        >
                                                            Update
                                                        </button>


                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                )}
                            </Table>
                        </div>
                        {/* Modal */}
                        {selectedTransaction && (
                            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                                <div className="modal-header">
                                    <h5 className="modal-title">Update Sales Data</h5>
                                    <button type="button" className="close" onClick={toggleModal}>
                                        <span>&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <Form className='theme-form' onSubmit={updateSales}>
                                        <div className="form-group">
                                            <label>Transaction Date:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={selectedTransaction.transaction_date}
                                                disabled
                                            />
                                        </div>
                                        {deliveryPartners.length <= 0 && (
                                            <>
                                                <div className="form-group">
                                                    <label>Transaction Count</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        value={selectedTransaction.transaction_count}
                                                        onChange={(e) => {
                                                            setSelectedTransaction({
                                                                ...selectedTransaction,
                                                                transaction_count: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Net Sales</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        value={selectedTransaction.total_sales}
                                                        onChange={(e) => {
                                                            setSelectedTransaction({
                                                                ...selectedTransaction,
                                                                total_sales: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        {deliveryPartners.map((partner, index) => (
                                            <>
                                                <div className="form-group">
                                                    <label>{partner.replace('_', ' ')} Count</label>
                                                    <input
                                                        type="number"
                                                        required
                                                        className="form-control"
                                                        value={selectedTransaction[partner.toLowerCase().replace(/ /g, '_') + '_count'] || ''}
                                                        onChange={(e) => {
                                                            setSelectedTransaction({
                                                                ...selectedTransaction,
                                                                [partner.toLowerCase().replace(/ /g, '_') + '_count']: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>{partner.replace('_', ' ')} Sales</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        required
                                                        value={selectedTransaction[partner.toLowerCase().replace(/ /g, '_') + '_sales'] || ''}
                                                        onChange={(e) => {
                                                            setSelectedTransaction({
                                                                ...selectedTransaction,
                                                                [partner.toLowerCase().replace(/ /g, '_') + '_sales']: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </>

                                        ))}
                                        <div className="modal-footer">
                                            <Button color="primary" type='submit'
                                            // onClick={() => updateSales()}
                                            >
                                                Update Sales
                                            </Button>
                                            <Button color="secondary" onClick={toggleModal}>
                                                Close
                                            </Button>
                                        </div>
                                    </Form>
                                </div>

                            </Modal>
                        )}
                    </Col>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default HomeView