import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Spinner } from '../../../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Table, Form, FormGroup, Label, Row } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { exportToExcel, exportToPDF } from '../ExportReport';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const MissingDatesView = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [properties, setProperties] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [group_name, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [tenant_id, setTenantId] = useState('');
    const [ReportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const yesterday = today.toISOString().split('T')[0];
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        setTenants(filteredTenants);
    }, [property_id, group_name, allTenants]);


    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const generateReport = async (e) => {
        e.preventDefault();

        //Validation for start date and end date
        if (startDate && endDate && endDate < startDate) {
            setValidationMessage('End date cannot be less than start date');
            return;
        } else {
            setValidationMessage('');
        }

        const data = {
            concessionaire_id,
            property_access_id: property_access,
            start_date: startDate,
            end_date: endDate,
            group_name,
            property_id,
            tenant_id,
        };

        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getmissingdates`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                insertToLog(" generated Missing dates report")
                console.log(response.data);
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };


    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };



    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const pageCount = Math.ceil(ReportData.length / itemsPerPage);



    const sortedReportData = React.useMemo(() => {
        let sortableData = [...ReportData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [ReportData, sortConfig]);

    const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = key => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'asc') {
                return <FontAwesomeIcon icon={faSortUp} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
            if (sortConfig.direction === 'desc') {
                return <FontAwesomeIcon icon={faSortDown} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
        }

    };

    const handleExport = (format) => {
        if (ReportData.length > 0) {

            const formatDateRange = (startDate, endDate) => {
                if (startDate && endDate) {
                    return `From ${startDate} To ${endDate}`;
                } else if (startDate) {
                    return `From ${startDate}`;
                } else if (endDate) {
                    return `To ${endDate}`;
                } else {
                    return '';
                }
            };
            const metadata = [
                [formatDateRange(startDate, endDate)],
                ['Property', property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'],
                ['Group', group_name ? group_name : 'All'],
                ['Tenant', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'],
                ['Exported By', login_name],
                ['Exported On', new Date().toLocaleString()]
            ];
            const reportColumns = [
                { header: 'Tenant Name', key: 'tenant_store_name' },
                { header: 'Group Name', key: 'tenant_group_name' },
                { header: 'Unit Code', key: 'unit_code' },
                { header: 'Tenant Code', key: 'tenant_code' },
                { header: 'Property', key: 'property_name' },
                { header: 'Total Missing Dates', key: 'total_missing_dates' },
                { header: 'Missing Dates', key: 'missing_dates' }
            ];
            if (format === 'excel') {
                exportToExcel("Missing Dates Report", metadata, reportColumns, ReportData, "MissingDates.xlsx");
                insertToLog(" exported missing dates report excel")
            } else if (format === 'pdf') {
                exportToPDF("Missing Dates Report", metadata, reportColumns, ReportData, "MissingDates.pdf");
                insertToLog(" exported missing dates report to pdf")
            }
        }
    };

    const reset = () => {
        setStartDate('')
        setEndDate('')
        setPropertyId('');
        setGroupName('');
        setTenantId('');
        setReportData([])
    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="startdate">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={startDate}
                                        max={yesterday} onChange={handleStartDateChange} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="enddate">End Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={endDate}
                                        max={yesterday} onChange={handleEndDateChange} />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('pdf'),
                                        onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                </Btn>
                                {tooltip === 'Export to PDF' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive" >
                                <Table>
                                    <thead >
                                        <tr>
                                            <th scope="col" onClick={() => requestSort('tenant_store_name')}>Tenant Name {getSortIcon('tenant_store_name')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_group_name')}>Group Name {getSortIcon('tenant_group_name')}</th>
                                            <th scope="col" onClick={() => requestSort('unit_code')}>Unit Code {getSortIcon('unit_code')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_code')}>Tenant Code {getSortIcon('tenant_code')}</th>
                                            <th scope="col" onClick={() => requestSort('property_name')}>Property{getSortIcon('property_name')}</th>
                                            <th scope="col" onClick={() => requestSort('total_missing_dates')} >Total Missing days {getSortIcon('total_missing_dates')}</th>
                                            <th scope="col"  >Missing Dates </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {currentPageData.map((item, index) => (
                                            <tr key={index} >
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.tenant_store_name}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.tenant_group_name}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.unit_code}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.tenant_code}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.property_name}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.total_missing_dates}</td>
                                                <td style={{ color: item.total_missing_dates >= 30 ? 'red' : 'black' }}>{item.missing_dates}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {ReportData.length > 50 && (
                                <ReactPaginate
                                    previousLabel={'<<'}
                                    nextLabel={'>>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                />
                            )}
                        </Col>
                    </CardBody>
                </Card>
            )}
        </Fragment>
    )
}

export default MissingDatesView