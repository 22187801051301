import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import { toast } from "react-toastify";
import config from '../../../../config';

const ConcessionaireEdit = () => {

    if (localStorage.getItem("usertype") !== "sadmin") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [concessionaires, setConcessionaire] = useState({});
    const { id } = useParams();
    console.log(id)
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [concessionaire_name, setName] = useState('');
    const [concessionaire_email, setEmail] = useState('');
    const [concessionaire_phone, setPhone] = useState('');
    const [concessionaire_address, setAddress] = useState('');
    const [concessionaire_username, setUsername] = useState('');
    const [concessionaire_password, setPassword] = useState('');
    const [concessionaire_license_start_date, setLicenceStartDate] = useState('');
    const [concessionaire_license_end_date, setLisenseEndDate] = useState('');
    const [concessionaire_active, setActive] = useState('');
    const login_id = localStorage.getItem("loginId");


    useEffect(() => {
        axios.get(`${config.hostname}/concessionaire/${id}`, configheader)
            .then((response) => {
                setName(response.data.concessionaire_name);
                setEmail(response.data.concessionaire_email);
                setPhone(response.data.concessionaire_phone);
                setAddress(response.data.concessionaire_address);
                setUsername(response.data.concessionaire_username);
                setPassword(response.data.concessionaire_password);
                const licensestartdate = new Date(response.data.concessionaire_license_start_date).toISOString().split('T')[0];
                setLicenceStartDate(licensestartdate);
                const licenseenddate = new Date(response.data.concessionaire_license_end_date).toISOString().split('T')[0];
                setLisenseEndDate(licenseenddate);
                setActive(response.data.concessionaire_active);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Error")
            });
    }, [])

    const handleActiveStatusChange = (value) => {
        const isActive = value === 'true';
        setActive(isActive);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            concessionaire_name,
            concessionaire_email,
            concessionaire_phone,
            concessionaire_address,
            concessionaire_username,
            concessionaire_password,
            concessionaire_license_start_date,
            concessionaire_license_end_date,
            concessionaire_active,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/concessionaire/updateconcessionaire/${id}`, data, configheader)
            .then(() => {
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Form className="card" onSubmit={handleSubmit}>
            <Row>
                <Col xl='4'>
                    <Fragment>
                        <Card>
                            <CardHeader>
                                <H4 attrH4={{ className: 'card-title mb-0' }}>{concessionaire_name}</H4>
                                <div className='card-options'>
                                    <a className='card-options-collapse' href='#javascript'>
                                        <i className='fe fe-chevron-up'></i>
                                    </a>
                                    <a className='card-options-remove' href='#javascript'>
                                        <i className='fe fe-x'></i>
                                    </a>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row className='mb-2'>
                                        <div className='profile-title'>
                                            <div className='media'>
                                                <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/user/7.jpg')}` }} />
                                                <div className='media-body'>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <FormGroup className='mb-3'>
                                        <H6 attrH6={{ className: 'form-label' }}>Address</H6>
                                        <Input type='textarea' className='form-control' rows='5' value={concessionaire_address} placeholder="Address"
                                            onChange={(e) => setAddress(e.target.value)}
                                        /><span style={{ color: "red" }}>{errors.company && 'Address is required'} </span>
                                    </FormGroup>

                                </Form>
                            </CardBody>
                        </Card>
                    </Fragment>
                </Col>
                <Col xl='8'>
                    <Fragment>
                        <Form className="card"  >
                            <CardHeader>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Concessionaire Details</H4>
                                <div className="card-options">
                                    <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                    </a>
                                    <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                    </a>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Email</Label>
                                            <Input className="form-control" type="text" value={concessionaire_email} placeholder='Email'
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                        <FormGroup> <Label className="form-label">Phone Number</Label>
                                            <Input className="form-control" type="text" value={concessionaire_phone} placeholder='Phone Number'
                                                onChange={(e) => setPhone(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Username</Label>
                                            <Input className="form-control" type="text" value={concessionaire_username} placeholder='Username'
                                                onChange={(e) => setUsername(e.target.value)} />
                                        </FormGroup>
                                    </Col> */}
                                    {/* <Col sm="6" md="6">
                                        <FormGroup> <Label className="form-label"> Password</Label>
                                            <Input className="form-control" type="password" value={concessionaire_password} placeholder='Password'
                                                onChange={(e) => setPassword(e.target.value)} />
                                        </FormGroup>
                                    </Col> */}
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">License Start Date</Label>
                                            <input className="form-control" type="date" name="licensestart" value={concessionaire_license_start_date}
                                                onChange={(e) => setLicenceStartDate(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                        <FormGroup> <Label className="form-label"> License End Date</Label>
                                            <Input className="form-control" type="date" value={concessionaire_license_end_date}
                                                onChange={(e) => setLisenseEndDate(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Active</Label>
                                            <select name="activestatus" placeholder="Select Active Status" className="form-control digits" required
                                                value={concessionaire_active ? 'true' : 'false'}
                                                onChange={(e) => handleActiveStatusChange(e.target.value === 'true')}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Form>
                    </Fragment>
                </Col>
            </Row >
            <CardFooter className="text-end">
                <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
            </CardFooter>
        </Form>
    )
}

export default ConcessionaireEdit