import React, { Fragment, useState, useEffect, useContext } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Card, CardBody } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { Breadcrumbs } from '../AbstractElements';

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import config from '../config';
import CryptoJS from 'crypto-js';

const ChangePassword = () => {

    const login_id = localStorage.getItem("loginId");
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [togglePassword, setTogglePassword] = useState(false);
    const navigate = useNavigate();


    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const changePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrorMessage('Password Mismatch')
            return;
        }
        else {
            setErrorMessage('')
        }
        const encryptedCurrentPassword = CryptoJS.AES.encrypt(currentPassword, 'S7h^k9Lz*R!8rL1uNb#X@zPQ5uT3gB').toString();
        const encryptedNewPassword = CryptoJS.AES.encrypt(confirmPassword, 'S7h^k9Lz*R!8rL1uNb#X@zPQ5uT3gB').toString();

        const data = {
            current_password: encryptedCurrentPassword,
            new_password: encryptedNewPassword

        };

        // const data = {
        //     current_password: currentPassword,
        //     new_password: confirmPassword

        // };
        axios
            .post(`${config.hostname}/users/updatepassword/${login_id}`, data, configheader)
            .then(() => {
                toast.success("Successfully Updated");
                setErrorMessage('');
            })
            .catch((error) => {
                toast.warning("Existing password is incorrect")
                console.log(error);

            });
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs parent="Change password" title="Change Password" mainTitle="Change Password" />
                <Card>
                    <Row>
                        <Col xl='6' >
                            <CardBody>
                                <Form className="theme-form" autoComplete="off" onSubmit={changePassword}>

                                    <FormGroup>
                                        <Label className="col-form-label">Current Password</Label>
                                        <div >
                                            <input className="form-control" type={togglePassword ? "text" : "password"}
                                                onChange={(e) => setCurrentPassword(e.target.value)} required autocomplete="new-password" />
                                            {/* <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                <span className={togglePassword ? "" : "show"} ></span>
                                            </div> */}
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <Label className="col-form-label">New Password</Label>
                                        <div className="position-relative">
                                            <Input className="form-control" type={togglePassword ? "text" : "password"}
                                                onChange={(e) => setNewPassword(e.target.value)} required autocomplete="new-password" />
                                            {/* <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                <span className={togglePassword ? "" : "show"}></span>
                                            </div> */}
                                        </div>
                                    </FormGroup>
                                    <FormGroup >
                                        <Label className="col-form-label">Confirm Password</Label>
                                        <div className="position-relative">
                                            <Input className="form-control" type={togglePassword ? "text" : "password"}
                                                onChange={(e) => setConfirmPassword(e.target.value)} required autocomplete="new-password" />
                                            {/* <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                                                <span className={togglePassword ? "" : "show"}></span>
                                            </div> */}
                                            {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                                        </div>
                                    </FormGroup>
                                    <div className="position-relative form-group mb-0">
                                        <Btn attrBtn={{ color: "primary", type: "submit", className: "d-block w-100 mt-2" }}>Change Password</Btn>
                                    </div>
                                </Form>

                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Container>
            <ToastContainer />
        </Fragment>
    )
}

export default ChangePassword