import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button, Container, CardFooter, Input } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import axios from "axios";
import config from "../../../../config";
import { toast } from "react-toastify";


const DeliveryPartnerAdd = () => {

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [movedItems, setMovedItems] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);


    useEffect(() => {
        axios
            .get(`${config.hostname}/deliverypartner/getalldeliverypartners`, configheader)
            .then((response) => {
                setDeliveryPartners(response.data);
                setAvailableItems(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, { concessionaire_id }, configheader)
            .then((response) => {
                const partners = response.data.map(partner => ({
                    id: partner.delivery_partner_id._id,
                    text: partner.delivery_partner_id.delivery_partner_name
                }));
                setMovedItems(partners);

                // Filter out movedItems from availableItems
                const updatedAvailableItems = deliveryPartners.filter(ai =>
                    !partners.some(pi => pi.id === ai._id)
                );
                setAvailableItems(updatedAvailableItems);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [deliveryPartners]);

    const handleSelect = (id) => {
        setSelectedItems(prev => prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]);
    };

    const moveSelectedItems = () => {
        const itemsToMove = availableItems.filter(partner => selectedItems.includes(partner._id));
        setMovedItems(prev => [...prev, ...itemsToMove.map(item => ({ id: item._id, text: item.delivery_partner_name }))]);
        setSelectedItems([]);
        setAvailableItems(prev => prev.filter(partner => !selectedItems.includes(partner._id)));
        handleSave(itemsToMove.map(item => item._id))
    };

    const moveBackSelectedItems = () => {
        const itemsToMoveBack = movedItems.filter(partner => selectedItems.includes(partner.id));
        setAvailableItems(prev => [...prev, ...itemsToMoveBack.map(item => ({ _id: item.id, delivery_partner_name: item.text }))]);
        setSelectedItems([]);
        setMovedItems(prev => prev.filter(partner => !selectedItems.includes(partner.id)));
        handleRemove(itemsToMoveBack.map(item => item.id));
    };


    const handleSave = (itemsToAdd) => {

        const data = {
            concessionaire_id,
            delivery_partner_id: itemsToAdd,
            login_id
        };
        console.log(data);

        axios
            .post(`${config.hostname}/concessionairedeliverypartner/createdeliverypartner`, data, configheader)
            .then(() => {
                toast.success("Successfully Added");
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const handleRemove = (itemsToRemove) => {

        const data = {
            concessionaire_id,
            delivery_partner_id: itemsToRemove,
            login_id
        };
        console.log(data);


        axios({
            method: 'delete',
            url: `${config.hostname}/concessionairedeliverypartner/removedeliverypartner`,
            data: data,
            headers: configheader.headers
        })
            .then(() => {
                toast.success("Successfully Removed");
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const filteredAvailableItems = searchTerm
        ? availableItems.filter(partner => partner.delivery_partner_name.toLowerCase().includes(searchTerm.toLowerCase()))
        : availableItems;

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <HeaderCard title="Delivery Partners" />
                                            <CardBody>
                                                <Input
                                                    type="text"
                                                    placeholder="Search delivery partners..."
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    className="mb-3"
                                                />

                                                {filteredAvailableItems.map(partner => (
                                                    <div key={partner._id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(partner._id)}
                                                            onChange={() => handleSelect(partner._id)}
                                                        /> {partner.delivery_partner_name}
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveSelectedItems} color="primary">Add Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <HeaderCard title="Selected Delivery Partners" />
                                            <CardBody>
                                                {movedItems.map((item) => (
                                                    <div key={item.id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(item.id)}
                                                            onChange={() => handleSelect(item.id)}
                                                        /> {item.text}
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveBackSelectedItems} color="primary" className="ml-2">Remove Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};


export default DeliveryPartnerAdd;
