import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Btn, Breadcrumbs, Spinner } from '../../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';
import { Card, CardBody, Col, Container, Row, Form, Input, InputGroup, InputGroupText, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const style2 = { width: 60, fontSize: 14, padding: 4 };

const TenantView = () => {
    const manageTenant = localStorage.getItem("manageTenants");
    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessTenant = localStorage.getItem("accessTenants");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");

    if ((manageTenant === "0" && manageGeneralSettings === "0" && accessTenant === "0" && accessGeneralSettings === "0")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [tenants, setTenants] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const tenant_concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);



    const tenantColumns = [

        {
            name: 'Store Name',
            selector: (row) => row.tenant_store_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '160px',
        },
        {
            name: 'Group Name',
            selector: (row) => row.tenant_group_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '160px',
        },
        {
            name: 'Unit Code',
            selector: (row) => row.tenant_unit_code,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '130px',
            maxWidth: '130px',
        },
        {
            name: 'Tenant Code',
            selector: (row) => row.tenant_code,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '130px',
            maxWidth: '130px',
        },
        {
            name: 'Property',
            selector: (row) => row.tenant_property_id.property_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '120px',
            maxWidth: '300px',
        },
        {
            name: 'Category',
            selector: (row) => row.tenant_category_id.category_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '120px',
            maxWidth: '300px',
        },
        {
            name: 'Subcategory',
            selector: (row) => row.tenant_subcategory_id.subcategory_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '150px',
            maxWidth: '200px',
        },
        {
            name: 'Status',
            selector: (row) => {
                if (row.tenant_disable === true) {
                    return 'Disable';
                } else if (!row.tenant_status) {
                    return '-';
                } else {
                    return row.tenant_status;
                }
            },
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '100px',
            maxWidth: '100px',
        },
    ]

    if ((manageTenant === '1' || manageGeneralSettings === "1")) {
        tenantColumns.push({
            name: 'Action',
            cell: (row) => (
                <div>
                    <span>
                        <Btn
                            attrBtn={{
                                style: { marginLeft: '10px' },
                                color: 'primary',
                                className: 'fa fa-pencil',
                                type: 'button',
                                size: 'sm',
                                onClick: () => handleEditClick(row)
                            }}
                        >
                            Edit
                        </Btn>
                    </span>
                </div>
            ),
            sortable: false,
            center: false,
            minWidth: '150px',
            maxWidth: '150px',
        });
    }
    const handleViewClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/tenant/tenantdetail/${row._id}`);

    }
    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/tenant/tenantedit/${row._id}`);

    }

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        if (searchTerm.trim() === '') {
            setTenants(allTenants); // Reset to all tenants when search is cleared
        } else {
            const filteredTenants = allTenants.filter(tenant =>
                (tenant.tenant_store_name && tenant.tenant_store_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (tenant.tenant_group_name && tenant.tenant_group_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (tenant.tenant_code && tenant.tenant_code.toLowerCase().includes(searchTerm))
            );
            setTenants(filteredTenants);
        }
    };
    const handleExport = () => {

        const formatDate = (dateString) => {
            if (!dateString) return '';
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const exportData = tenants.map(tenant => ({
            'Store Name': tenant.tenant_store_name,
            'Group Name': tenant.tenant_group_name,
            'Unit Code': tenant.tenant_unit_code,
            'Tenant Code': tenant.tenant_code,
            'Property': tenant.tenant_property_id.property_name,
            'Category': tenant.tenant_category_id.category_name,
            'Subcategory': tenant.tenant_subcategory_id.subcategory_name,
            'Status': tenant.tenant_disable ? 'Disable' : tenant.tenant_status ? tenant.tenant_status : '-',
            'Live Date': formatDate(tenant.tenant_live_date),
            // 'Revenue Share Method': tenant.tenant_revenue_share_method_id.revenue_method_name,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Tenants');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Tenants.xlsx');

        insertToLog(' exported tenant list to excel')
    }

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: tenant_concessionaire_id,
            module: "Tenant List",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {

                console.log(error);

            });
    }

    const handleAddClick = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/tenant/newtenant/default`);
    }

    return (
        <Fragment>
            <Breadcrumbs className="breadcrumb breadcrumb-colored m-b-30 bg-primary" parent="Tenant" title="Tenant List" mainTitle="Tenant List" />
            <Container fluid={true}>
                <Row>
                    {(manageTenant === "1" || manageGeneralSettings === "1") && (

                        <div className="text-end mb-3">
                            <Btn attrBtn={{ color: "primary", className: "fa fa-plus", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New Tenant</Btn>
                        </div>
                    )}
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col xl="11">
                                        <InputGroup className='input-group flex-nowrap'>
                                            <Input
                                                className='form-control'
                                                type='search'
                                                placeholder='Search Store Name/Group Name/Tenant Code'
                                                onChange={handleSearchChange}
                                            />
                                            <InputGroupText>
                                                <i className='fa fa-search'></i>
                                            </InputGroupText>
                                        </InputGroup>
                                    </Col>
                                    {(manageTenant === "1" || manageGeneralSettings === "1") && (
                                        <Col xl='1'>
                                            <Btn
                                                attrBtn={{
                                                    color: 'primary',
                                                    //type: 'button',
                                                    onClick: () => handleExport('excel'),
                                                    onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                                    onMouseLeave: handleMouseLeave
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                            </Btn>
                                            {tooltip === 'Export to Excel' && (
                                                <div style={{
                                                    position: 'absolute',
                                                    top: '-30px',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                    backgroundColor: 'black',
                                                    color: 'white',
                                                    padding: '5px 10px',
                                                    borderRadius: '3px',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '12px',
                                                    zIndex: 1,
                                                }}>
                                                    {tooltip}
                                                </div>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {loading === true ? (
                                    <div className="loader-box">
                                        <Spinner attrSpinner={{ className: 'loader-3' }} />
                                    </div>
                                ) : (
                                    <div className='table-responsive product-table'>
                                        <DataTable
                                            pagination
                                            columns={tenantColumns}
                                            data={tenants}
                                            highlightOnHover={true}
                                            striped={true}
                                            responsive={true}
                                            onRowClicked={(row) => handleViewClick(row)}>
                                        </DataTable>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default TenantView