import React, { Fragment, useContext, useState } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';

const NewConcessionaire = () => {

    if (localStorage.getItem("usertype") !== "sadmin") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();


    const login_id = localStorage.getItem("loginId");

    const [concessionaire_name, setName] = useState('');
    const [concessionaire_code, setCode] = useState('');
    const [concessionaire_email, setEmail] = useState('');
    const [concessionaire_phone, setPhone] = useState('');
    const [concessionaire_address, setAddress] = useState('');
    const [concessionaire_username, setUsername] = useState('');
    const [concessionaire_password, setPassword] = useState('');
    const [concessionaire_license_start_date, setLicenceStartDate] = useState('');
    const [concessionaire_license_end_date, setLisenseEndDate] = useState('');
    const [concessionaire_created_Date, setCreatedDate] = useState(new Date().toISOString().substr(0, 10)); // Set default date to current date
    //const [concessionaire_created_by, setCreatedBy] = useState(localStorage.getItem("loginName"));
    const [concessionaire_active, setActive] = useState(true);


    const handleActiveStatusChange = (value) => {
        const isActive = value === 'true';
        setActive(isActive);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("sessiontoken");
        console.log(token);

        const data = {
            concessionaire_name,
            concessionaire_code,
            concessionaire_email,
            concessionaire_phone,
            concessionaire_address,
            concessionaire_username,
            concessionaire_password,
            concessionaire_license_start_date,
            concessionaire_license_end_date,
            concessionaire_active,
            concessionaire_created_Date,
            concessionaire_created_by: login_id,
            login_id
        };
        console.log(data)
        const configheader = {
            headers: {
                Authorization: `${token}`
            }
        };

        axios

            .post(`${config.hostname}/concessionaire`, data, configheader)
            .then(() => {
                toast.success("Successfully Added");
                setName('')
                setCode('')
                setEmail('')
                setPhone('');
                setAddress('');
                setUsername('');
                setPassword('');
                setLicenceStartDate('');
                setLisenseEndDate('');
                setActive(true);
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Concessionaire' title='Add New Concessionaire' mainTitle='Add New Concessionaire' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Concessionaire Name</Label>
                                                    <input className="form-control" type="text" name="concessionairename" placeholder="Concessionaire Name" required
                                                        value={concessionaire_name} onChange={(e) => setName(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.concessionairename && 'Concessionaire name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Concessionaire Code</Label>
                                                    <input className="form-control" type="text" name="concessionairecode" placeholder="Concessionaire Code" required
                                                        value={concessionaire_code} onChange={(e) => setCode(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.concessionairecode && 'Concessionaire code is required'}</span>
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Email</Label>
                                                    <input className="form-control" type="text" name="email" placeholder="Email"
                                                        value={concessionaire_email} onChange={(e) => setEmail(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.email && 'Email id is required'}</span>
                                                </FormGroup>

                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Phone Number</Label>
                                                    <input className="form-control" type="text" name="phone" placeholder="Phone Number" required
                                                        value={concessionaire_phone} onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.phone && 'Phone Number is required'}</span>
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Username</Label>
                                                    <input className="form-control" type="text" name="username" placeholder="Username" required
                                                        value={concessionaire_username} onChange={(e) => setUsername(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.username && 'Username is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Password</Label>
                                                    <input className="form-control" type="password" name="password" placeholder="Password" required
                                                        value={concessionaire_password} onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.password && 'Password is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>License Start Date</Label>
                                                    <input className="form-control" type="date" name="licensestart" placeholder="License Start Date" required
                                                        value={concessionaire_license_start_date} onChange={(e) => setLicenceStartDate(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.licensestart && 'License start date is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>License End Date</Label>
                                                    <input className="form-control" type="date" name="licenceend" placeholder="License End Date" required
                                                        value={concessionaire_license_end_date} onChange={(e) => setLisenseEndDate(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.licenceend && 'License end date is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Address</Label>
                                                    <input className="form-control" type="text" name="address" placeholder="Address" required
                                                        value={concessionaire_address} onChange={(e) => setAddress(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.address && 'Address is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Active</Label>
                                                    <select name="activestatus" id="activestatus" placeholder="Select Active Status" className="form-control digits" required
                                                        onChange={(e) => handleActiveStatusChange(e.target.value)}>
                                                        <option value="true">Yes</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: 'success', className: 'me-3', type: "submit" }} >{Add} </Btn>
                                                <Link to={`${process.env.PUBLIC_URL}/controlpanel/concessionaire/viewconcessionaire/:layout`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewConcessionaire