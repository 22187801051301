import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';
import { toast } from "react-toastify";
import { Typeahead } from 'react-bootstrap-typeahead';
import de from 'date-fns/esm/locale/de/index.js';

const TenantEdit = () => {

    const manageTenant = localStorage.getItem("manageTenants");
    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if ((manageTenant !== "1" && manageGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }
    const { register, formState: { errors } } = useForm();

    const [countries, setCountries] = useState([]);
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [revenueShareMethods, setRevenueShareMethods] = useState([]);
    const [tenant_revenue_share_method_id, setRevenueMethod] = useState('');
    const [tenant, setTenant] = useState({});
    const tenant_concessionaire_id = localStorage.getItem("concessionaireId");
    const [tenant_country_id, setCountryId] = useState('');
    const [tenant_property_id, setPropertyId] = useState('');
    const [tenant_category_id, setCategoryId] = useState('');
    const [tenant_store_type, setStoreType] = useState('');
    const [tenant_current_status, setCurrentStatus] = useState('');
    const [tenant_subcategory_id, setSubcategoryId] = useState('');
    const [tenant_group_name, setGroupName] = useState('');
    const [tenant_store_name, setStoreName] = useState('');
    const [tenant_email_id, setEmailId] = useState('');
    const [tenant_address, setAddress] = useState('');
    const [tenant_carpet_area, setCarpetArea] = useState('');
    const [tenant_franchisee, setFranchisee] = useState('');
    const [tenant_chargable_area, setChargableArea] = useState('');
    const [tenant_floor, setFloor] = useState('');
    const [tenant_location, setLocation] = useState('');
    const [tenant_lease_startdate, setLeaseStartDate] = useState('');
    const [tenant_lease_enddate, setLeaseEndDate] = useState('');
    const [tenant_base_rent, setBaseRent] = useState('');
    const [tenant_revenue_share, setRevenueShare] = useState('');
    const [tenant_contact_person, setContactPerson] = useState('')
    const [tenant_contact_number, setContactNumber] = useState('')
    const [tenant_it_contact_person, setITContactPerson] = useState('')
    const [tenant_it_contact_number, setITContactNumber] = useState('');
    const [tenant_pos_name, setPOSName] = useState('')
    const [tenant_modified_date, setModifiedDate] = useState(new Date().toISOString().substr(0, 10));
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [selectedDeliveryPartners, setSelectedDeliveryPartners] = useState([]);
    const [deliveryPartnerId, setDeliveryPartnerId] = useState([]);
    const [charges, setCharges] = useState([]);
    const [charge1, setCharge1] = useState('');
    const [charge2, setCharge2] = useState('');
    const [charge3, setCharge3] = useState('');
    const [charge4, setCharge4] = useState('');
    const [charge5, setCharge5] = useState('');
    // const [chargeValues, setChargeValues] = useState({});
    // const [selectedCharges, setSelectedCharges] = useState([]);
    // const [otherChargeId, setOtherChargeId] = useState([]);
    const login_id = localStorage.getItem("loginId");

    const arrayStoreType = ['Anchor', 'Vanilla', 'Kiosk', 'Other'];
    const arrayCurrentStatus = ['Trading', 'Fitout', 'Closed Temporarily', 'Closed Permanently'];

    const [labelTenantStatus, setLabelTenantStatus] = useState('')
    //const [tenant_created_date, setCreatedDate] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        axios
            .get(`${config.hostname}/country`, configheader)
            .then((response) => {
                const filteredCountries = response.data.filter(country => country.country_concessionaire_id === tenant_concessionaire_id);
                setCountries(filteredCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    useEffect(() => {
        axios
            .get(`${config.hostname}/property`, configheader)
            .then((response) => {
                const filteredProperties = response.data.filter(property => property.property_concessionaire_id._id === tenant_concessionaire_id);
                setProperties(filteredProperties);
                console.log(filteredProperties)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${config.hostname}/category`, configheader)
            .then((response) => {
                const filteredCtaegories = response.data.filter(category => category.category_concessionaire_id === tenant_concessionaire_id);
                setCategories(filteredCtaegories);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${config.hostname}/subcategory`, configheader)
            .then((response) => {
                const filteredSubcategories = response.data.filter(subcategory => subcategory.subcategory_concessionaire_id === tenant_concessionaire_id
                    && subcategory.category_id._id === tenant_category_id);
                setSubcategories(filteredSubcategories);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [tenant_category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyproperty`, { property_id: tenant_property_id }, configheader)
            .then((response) => {
                setAllFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, [tenant_property_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairerevenuemethods/getmethods`, { concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                setRevenueShareMethods(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        const data = {
            concessionaire_id: tenant_concessionaire_id
        }
        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, data, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    delivery_partner_id: item.delivery_partner_id._id,
                    delivery_partner_name: item.delivery_partner_id.delivery_partner_name
                }));
                setDeliveryPartners(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.post(`${config.hostname}/tenantdeliverypartner/getdeliverypartner`, { tenant_id: id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    delivery_partner_id: item.delivery_partner_id._id,
                    delivery_partner_name: item.delivery_partner_id.delivery_partner_name
                }));
                setDeliveryPartnerId(transformedData.delivery_partner_id)
                setSelectedDeliveryPartners(transformedData)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairecharges/getcharges`, { concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    other_charge_id: item.other_charge_id._id,
                    display_name: item.display_name,
                    charge_value: item.charge_value,
                    charge_name: item.other_charge_id.charge_name
                }));
                setCharges(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // useEffect(() => {
    //     axios.post(`${config.hostname}/tenantothercharges/getcharges`, { tenant_id: id }, configheader)
    //         .then((response) => {
    //             const transformedData = response.data.map(item => ({
    //                 other_charge_id: item.other_charge_id._id,
    //                 display_name: item.display_name,
    //                 charge_value: item.charge_value
    //             }));
    //             // setOtherChargeId(transformedData.other_charge_id)
    //             setCharges(transformedData);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, []);

    useEffect(() => {
        axios.get(`${config.hostname}/tenant/${id}`, configheader)
            .then((response) => {
                setTenant(response.data);
                const CountryId = response.data.tenant_country_id._id;
                setCountryId(CountryId);
                const propertyId = response.data.tenant_property_id._id;
                setPropertyId(propertyId);
                const categoryId = response.data.tenant_category_id._id;
                setCategoryId(categoryId);
                const subcategoryId = response.data.tenant_subcategory_id._id;
                setSubcategoryId(subcategoryId);
                const revenueMethodId = response.data.tenant_revenue_share_method_id._id;
                setRevenueMethod(revenueMethodId);
                setStoreName(response.data.tenant_store_name);
                setGroupName(response.data.tenant_group_name);
                setEmailId(response.data.tenant_email_id);
                setAddress(response.data.tenant_address);
                setStoreType(response.data.tenant_store_type);
                setCurrentStatus(response.data.tenant_current_status);
                setFloor(response.data.tenant_floor);
                setLocation(response.data.tenant_location);
                setLeaseStartDate(response.data.tenant_lease_startdate);
                setLeaseEndDate(response.data.tenant_lease_enddate);
                setCarpetArea(response.data.tenant_carpet_area);
                setChargableArea(response.data.tenant_chargable_area);
                setBaseRent(response.data.tenant_base_rent);
                setRevenueShare(response.data.tenant_revenue_share);
                setContactPerson(response.data.tenant_contact_person);
                setContactNumber(response.data.tenant_contact_number);
                setITContactPerson(response.data.tenant_it_contact_person);
                setITContactNumber(response.data.tenant_it_contact_number);
                setPOSName(response.data.tenant_pos_name);
                setFranchisee(response.data.tenant_franchisee);
                const leaseStartDate = new Date(response.data.tenant_lease_startdate).toISOString().split('T')[0];
                setLeaseStartDate(leaseStartDate);
                const leaseEndDate = new Date(response.data.tenant_lease_enddate).toISOString().split('T')[0];
                setLeaseEndDate(leaseEndDate);
                const tenantStatus = response.data.tenant_status;
                const tenantdisable = response.data.tenant_disable;
                if (tenantdisable === true) {
                    setLabelTenantStatus('Reactivate')
                }
                else if (tenantStatus === '' || tenantStatus === 'null') {
                    setLabelTenantStatus('UAT')
                }
                else if (tenantStatus === 'UAT') {
                    setLabelTenantStatus('Live')
                }
                else if (tenantStatus === 'Live') {
                    setLabelTenantStatus('Disable')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const handleCountryChange = (e) => {
        setCountryId(e.target.value);
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
    };

    const handleCategoryChange = (e) => {
        const newCategoryId = e.target.value;
        setCategoryId(newCategoryId);

    };

    const handleSubcategoryChange = (e) => {
        setSubcategoryId(e.target.value);
    };

    const handleStoreTypeChange = (e) => {
        setStoreType(e.target.value);
    };

    const handleCurrentStatusChange = (e) => {
        setCurrentStatus(e.target.value);
    };

    const handleFloorChange = (e) => {
        setFloor(e.target.value);
    };

    const handleFranchiseeChange = (value) => {
        const isFranchisee = value === 'true';
        setFranchisee(isFranchisee);
    };

    const handleRevenueMethodChange = (e) => {
        setRevenueMethod(e.target.value);
    }

    const handlePartnerSelection = (selected) => {
        setSelectedDeliveryPartners(selected);

        // Determine the newly unselected items
        const selectedIds = selected.map(item => item.delivery_partner_id);
        const newAvailablePartners = deliveryPartners.filter(partner => !selectedIds.includes(partner.delivery_partner_id));

        // Determine the newly selected items
        const unselectedPartners = deliveryPartners.filter(partner => selectedIds.includes(partner.delivery_partner_id));

        // Combine the newly unselected items with existing available partners
        const combinedPartners = [...newAvailablePartners, ...unselectedPartners];
        setDeliveryPartners(combinedPartners);
        //setDeliveryPartnerId(combinedPartners.map(partner => partner.delivery_partner_id))
        setDeliveryPartnerId(selectedIds)
    };


    const handleChargeChange = (chargeName, value) => {
        if (chargeName === 'charge1') {
            setCharge1(value)
        }
        else if (chargeName === 'charge2') {
            setCharge2(value)
        }
        else if (chargeName === 'charge3') {
            setCharge3(value)
        }
        else if (chargeName === 'charge4') {
            setCharge4(value)
        }
        else if (chargeName === 'charge5') {
            setCharge5(value)
        }
    };

    const handleTenantStatusClick = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/tenant/tenantstatuschange/${id}`);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            tenant_country_id,
            tenant_property_id,
            tenant_category_id,
            tenant_subcategory_id,
            tenant_group_name,
            tenant_email_id,
            tenant_address,
            tenant_store_type,
            tenant_current_status,
            tenant_carpet_area,
            tenant_chargable_area,
            tenant_floor,
            tenant_location,
            tenant_franchisee,
            tenant_lease_startdate,
            tenant_lease_enddate,
            tenant_base_rent,
            tenant_revenue_share,
            tenant_revenue_share_method_id,
            tenant_charge1: charge1 || 0,
            tenant_charge2: charge2 || 0,
            tenant_charge3: charge3 || 0,
            tenant_charge4: charge4 || 0,
            tenant_charge5: charge5 || 0,
            tenant_contact_person,
            tenant_contact_number,
            tenant_it_contact_person,
            tenant_it_contact_number,
            tenant_pos_name,
            tenant_modified_date,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/tenant/updatetenant/${id}`, data, configheader)
            .then(() => {

                if (deliveryPartnerId) {
                    const deliveryData = {
                        concessionaire_id: tenant_concessionaire_id,
                        tenant_id: id,
                        delivery_partner_id: deliveryPartnerId.length > 0 ? deliveryPartnerId : [],
                        login_id,
                        tenant_store_name
                    }

                    axios
                        .post(`${config.hostname}/tenantdeliverypartner/updatedeliverypartner`, deliveryData, configheader)
                        .then(() => {
                            console.log('Added delivery partners')
                        })
                        .catch((error) => {
                            toast.error('Error on Updating Delivery Partners')
                            console.log(error);

                        });
                }
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xl='4'>
                    <Fragment>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <H4 attrH4={{ className: 'card-title mb-0' }}>{tenant_store_name}</H4>
                                    </Col>

                                    <Col>
                                        <FormGroup className="mb-3">
                                            <Btn attrBtn={{ color: "primary", onClick: () => handleTenantStatusClick() }} >{labelTenantStatus}</Btn>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row className='mb-2'>
                                        <div className='profile-title'>
                                            <div className='media'>
                                                <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/img001.jpg')}` }} />

                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Row >
                                            <FormGroup> <Label className="form-label">Country</Label>
                                                <select name="country" id="tenantcountry" className="form-control digits" required
                                                    value={tenant_country_id} onChange={handleCountryChange}>
                                                    <option value="">Select Country</option>
                                                    {countries.map(country => (
                                                        <option key={country._id} value={country._id}>{country.country_name}</option>
                                                    ))}
                                                </select>
                                            </FormGroup>
                                        </Row>
                                        <Row >
                                            <FormGroup className="mb-3"> <Label className="form-label">Property</Label>
                                                <select name="property" id="tenantproperty" className="form-control digits" required
                                                    value={tenant_property_id} onChange={handlePropertyChange}>
                                                    <option value="">Select Property</option>
                                                    {properties.map(property => (
                                                        <option key={property._id} value={property._id}>{property.property_name}</option>
                                                    ))}
                                                </select>
                                            </FormGroup>
                                        </Row>
                                        <Row >
                                            <FormGroup className="mb-3"> <Label className="form-label">Category</Label>
                                                <select name="category" id="tenantcategory" className="form-control digits" required
                                                    value={tenant_category_id} onChange={handleCategoryChange}>
                                                    <option value="">Select Category</option>
                                                    {categories.map(category => (
                                                        <option key={category._id} value={category._id}>{category.category_name}</option>
                                                    ))}
                                                </select>
                                            </FormGroup>
                                        </Row>
                                        <Row >
                                            <FormGroup className="mb-3"> <Label className="form-label">Subcategory</Label>
                                                <select name="category" id="tenantcategory" className="form-control digits" required
                                                    value={tenant_subcategory_id} onChange={handleSubcategoryChange} >
                                                    <option value="">Select Subcategory</option>
                                                    {subcategories.map(subcategory => (
                                                        <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                                    ))}
                                                </select>
                                            </FormGroup>
                                        </Row>
                                        <Row >
                                            <FormGroup className="mb-3"> <Label className="form-label">Group Name</Label>
                                                <Input className="form-control" type="text" value={tenant_group_name}
                                                    placeholder='Group Name' onChange={(e) => setGroupName(e.target.value)} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup className="mb-3"> <Label className="form-label">Franchisee</Label>
                                                <select name="franchisee" className="form-control digits" required
                                                    value={tenant_franchisee}
                                                    onChange={(e) => handleFranchiseeChange(e.target.value)}>
                                                    <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                </select>
                                            </FormGroup>
                                        </Row>

                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Fragment>
                </Col>
                <Col xl='8'>
                    <Fragment>
                        <Form className="card" >
                            <CardHeader>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Tenant Details</H4>
                                <div className="card-options">
                                    <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                    </a>
                                    <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                    </a>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Email</Label>
                                            <Input className="form-control" type="text" value={tenant_email_id}
                                                placeholder='Email' onChange={(e) => setEmailId(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Address</Label>
                                            <Input className="form-control" type="text" value={tenant_address}
                                                placeholder='Email' onChange={(e) => setAddress(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Store Type</Label>
                                            <select name="category" id="tenantcategory" className="form-control digits" required
                                                value={tenant_store_type} onChange={handleStoreTypeChange}>
                                                <option value="">Select Store type</option>
                                                {arrayStoreType.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Current Status</Label>
                                            <select name="category" id="tenantcategory" className="form-control digits" required
                                                value={tenant_current_status} onChange={handleCurrentStatusChange}>
                                                <option value="">Select Current status</option>
                                                {arrayCurrentStatus.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Carpet Area</Label>
                                            <Input className="form-control" type="number" step="0.001" value={tenant_carpet_area}
                                                placeholder='Carpet Area' onChange={(e) => setCarpetArea(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Chargable Area</Label>
                                            <Input className="form-control" type="number" step="0.001" value={tenant_chargable_area}
                                                placeholder='Chargable Area' onChange={(e) => setChargableArea(e.target.value)} />
                                        </FormGroup>
                                    </Col>

                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Floor</Label>
                                            {/* <Input className="form-control" type="text" value={tenant_floor}
                                                placeholder='Floor' onChange={(e) => setFloor(e.target.value)} /> */}

                                            <select name="tenant_floor" id="tenant_floor" className="form-control digits" required
                                                value={tenant_floor} onChange={handleFloorChange} >
                                                <option value="">Select Floor</option>
                                                {allFloors.map(floor => (
                                                    <option key={floor.floor_id.floor_name} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Location</Label>
                                            <Input className="form-control" type="text" value={tenant_location}
                                                placeholder='Area' onChange={(e) => setLocation(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Lease Start Date</Label>
                                            <Input className="form-control" type="date" value={tenant_lease_startdate}
                                                onChange={(e) => setLeaseStartDate(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Lease End Date</Label>
                                            <Input className="form-control" type="date" value={tenant_lease_enddate}
                                                onChange={(e) => setLeaseEndDate(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Base Rent</Label>
                                            <Input className="form-control" type="number" step="0.001" value={tenant_base_rent}
                                                placeholder='Base Rent' onChange={(e) => setBaseRent(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Revenue Share%</Label>
                                            <Input className="form-control" type="number" step="0.001" value={tenant_revenue_share}
                                                placeholder='Revenue Share' onChange={(e) => setRevenueShare(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup className="mb-3"> <Label className="form-label">Revenue Share Method</Label>
                                            <select name="revenuemethod" id="revenuemethod" className="form-control digits" required
                                                value={tenant_revenue_share_method_id} onChange={handleRevenueMethodChange}>
                                                <option value="">Select Revenue Share Method</option>
                                                {revenueShareMethods.map(method => (
                                                    <option key={method.revenue_method_id._id} value={method.revenue_method_id._id}>{method.revenue_method_id.description}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Contact Person</Label>
                                            <Input className="form-control" type="text" value={tenant_contact_person}
                                                placeholder='Contact Person' onChange={(e) => setContactPerson(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Contact Number</Label>
                                            <Input className="form-control" type="text" value={tenant_contact_number}
                                                placeholder='Contact Number' onChange={(e) => setContactNumber(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">IT Contact Person</Label>
                                            <Input className="form-control" type="text" value={tenant_it_contact_person}
                                                placeholder='IT Contact person' onChange={(e) => setITContactPerson(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">IT Contact Number</Label>
                                            <Input className="form-control" type="text" value={tenant_it_contact_number}
                                                placeholder='IT Contact Number' onChange={(e) => setITContactNumber(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">POS Name</Label>
                                            <Input className="form-control" type="text" value={tenant_pos_name}
                                                placeholder='POS Name' onChange={(e) => setPOSName(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Delivery Partners</Label>
                                            <Typeahead
                                                id="tenant_deliverypartners_id"
                                                name="tenant_deliverypartners_id"
                                                clearButton
                                                labelKey="delivery_partner_name"
                                                multiple
                                                options={deliveryPartners}
                                                selected={selectedDeliveryPartners}
                                                placeholder="Choose delivery partners..."
                                                onChange={handlePartnerSelection}
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.delivery_partner_name}</span>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {/* <Col md='6'>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Other Charges</Label>
                                            <Typeahead
                                                id="tenant_charges"
                                                name="tenant_charges"
                                                clearButton
                                                labelKey="display_name"
                                                multiple
                                                options={otherCharges}
                                                selected={selectedCharges}
                                                placeholder="Choose other charges..."
                                                onChange={handleChargeSelection}
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.display_name}</span>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col> */}
                                    {charges.map((charge) => (
                                        <Col md="6" key={charge.other_charge_id}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor={`charge_${charge.display_name}`}>{charge.display_name}</Label>
                                                <input
                                                    className={`form-control ${errors[`charge_${charge.display_name}`] && "is-invalid"}`}
                                                    id={`charge_${charge.display_name}`}
                                                    type="number"
                                                    name={`charge_${charge.display_name}`}
                                                    defaultValue={
                                                        charge.charge_name === 'charge1' ? tenant.tenant_charge1 :
                                                            charge.charge_name === 'charge2' ? tenant.tenant_charge2 :
                                                                charge.charge_name === 'charge3' ? tenant.tenant_charge3 :
                                                                    charge.charge_name === 'charge4' ? tenant.tenant_charge4 :
                                                                        charge.charge_name === 'charge5' ? tenant.tenant_charge5 :
                                                                            ''
                                                    }
                                                    {...register(`charge_${charge.display_name}`, {
                                                        required: true,
                                                        onChange: (e) => {
                                                            handleChargeChange(charge.charge_name, e.target.value);
                                                        },
                                                    })}
                                                />
                                                <span className="text-danger">
                                                    {errors[`charge_${charge.display_name}`] && `${charge.display_name} is required`}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                            </CardBody>
                        </Form>
                        <CardFooter className="text-end">
                            <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
                        </CardFooter>
                    </Fragment>
                </Col>
            </Row>
        </Form>
    )
}

export default TenantEdit