import React, { Fragment, useEffect, useState } from 'react';
import PieChartClass from './PieChart';
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { H5 } from '../../../../AbstractElements';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import config from '../../../../config';

const TopFiveTenants = ({ year, month, propertyMallCode, className }) => {
    const [topFiveData, setTopFiveData] = useState({
        sales: [],
        growth: []
    });
    const [chartData, setChartData] = useState({});
    //const [title, setTitle] = useState('Top 5 Tenants By Sales');
    const [title, setTitle] = useState('By Sales');

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = year || propertyMallCode || month ? 'dashboardfiltered' : 'dashboard';
            const apiData = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode,
                month
            };

            try {
                if (apiUrl !== '') {
                    const [salesResponse, growthResponse] = await Promise.all([
                        axios.post(`${config.hostname}/${apiUrl}/topfivetenants`, apiData, configheader),
                        axios.post(`${config.hostname}/${apiUrl}/topfivetenantbygrowth`, apiData, configheader)
                    ]);

                    setTopFiveData({
                        sales: salesResponse.data.topFiveTenants,
                        growth: growthResponse.data.topFiveTenants
                    });

                    // Initialize chart data based on fetched data
                    updateChartData('BySales', salesResponse.data.topFiveTenants);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [year, month, propertyMallCode, concessionaire_id, token]); // Ensures fetch only on change

    const updateChartData = (type, data) => {
        const chartData = [["Tenant", "", { role: "style" }, { role: "annotation", type: "string", calc: "stringify" }]];
        data.forEach(tenant => {
            const value = type === 'BySales' ? tenant.net_sales / 1000000 : tenant.growthPercentage;
            const annotation = type === 'BySales' ? (tenant.net_sales / 1000000).toFixed(2) : tenant.growthPercentage.toFixed(2);
            chartData.push([
                tenant.tenant_store_name,
                value,
                '#00b1c2',
                annotation
            ]);
        });

        setChartData({
            GoogleBarChart2: {
                width: "100%",
                height: "400px",
                chartType: "BarChart",
                data: chartData,
                option: {
                    title: type === 'BySales' ? "Top 5 Tenants By Sales" : "Top 5 Tenants By Growth",
                    height: 400,
                    bar: { groupWidth: "95%" },
                    backgroundColor: "transparent",
                    legend: { position: "none" }
                }
            }
        });
        setTitle(type === 'BySales' ? 'By Sales' : 'By Growth');
    };

    const handleOptionSelect = (option) => {
        updateChartData(option === 'BySales' ? 'BySales' : 'ByGrowth', option === 'BySales' ? topFiveData.sales : topFiveData.growth);
    };

    return (
        <Fragment>
            <Col sm='12' xl='6' className={className}>
                <Card>
                    <CardHeader>
                        <div>
                            <H5> <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{'Top 5 Tenants '}</div>
                                <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>{title}</div>
                                {title === 'By Sales' ? (
                                    <div style={{ fontSize: '0.5em', color: '#777', marginLeft: '10px' }}>{'(values in millions)'}</div>
                                ) :
                                    <div style={{ fontSize: '0.5em', color: '#777', marginLeft: '10px' }}>{'(values in percentage)'}</div>
                                }
                            </div></H5>
                            <DropdownButton id="dropdown-basic-button" title="Options" variant="dark">
                                <Dropdown.Item onClick={() => handleOptionSelect('BySales')}>By Sales</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleOptionSelect('ByGrowth')}>By Growth</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {chartData.GoogleBarChart2 && chartData.GoogleBarChart2.data.length > 1 ? (
                            <PieChartClass data={chartData.GoogleBarChart2} />
                        ) : (
                            <div>No data...</div>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
}

export default TopFiveTenants;

