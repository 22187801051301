import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Container, Card, CardBody } from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import axios from "axios";
import config from "../../../../config";
import { toast } from "react-toastify";
import SweetAlert from 'sweetalert2';

const StatusChange = () => {

    const manageTenant = localStorage.getItem("manageTenants");
    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if ((manageTenant === "0" && manageGeneralSettings === "0")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { id } = useParams();
    const tenant_concessionaire_id = localStorage.getItem("concessionaireId");
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem("sessiontoken");
    const navigate = useNavigate();
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [tenant_name, setTenantName] = useState('');
    const [tenant_disable, setTenantDisable] = useState(false);
    const [tenant_disableInitial, setTenantDisableInitial] = useState('');
    const [tenant_status, setTenantStatus] = useState('');
    const [tenant_statusInitial, setTenantStatusInitial] = useState('');
    const [tenant_uat_date, setTenantUATDate] = useState('');
    const [tenant_uat_assignee, setTenantUATAssignee] = useState('');
    const [tenant_live_date, setTenantLiveDate] = useState('');
    const [tenant_live_assignee, setTenantLiveAssignee] = useState('');
    const [tenant_mode_of_transfer, setModeofTransfer] = useState('');
    const [tenant_data_capture_method, setDataCaptureMethod] = useState('');
    const [tenant_disable_date, setTenantDisableDate] = useState('');
    const [tenant_disable_assignee, setTenantDisableAssignee] = useState('');
    const [tenant_disable_reason, setTenantDisableReason] = useState('');
    const [tenant_reactivate_date, setTenantReactivateDate] = useState('');
    const [tenant_reactivate_assignee, setTenantReactivateAssignee] = useState('');
    const [tenant_reactivate_reason, setTenantReactivateReason] = useState('');
    const [arrayStausOption, setArrayStatusOption] = useState([]);
    const [alerMesage, setAlertMessage] = useState('');
    const [task, setTask] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const arrayCaptureMethod = ['API', 'DB', 'CSV', 'Push'];
    const arrayModeOfTransfer = ['API', 'Mail', 'FTP/SFTP'];

    const today = new Date();
    const beforeDate = new Date(today);
    beforeDate.setDate(today.getDate() - 15);

    useEffect(() => {
        axios.get(`${config.hostname}/tenant/${id}`, configheader)
            .then((response) => {
                setTenantName(response.data.tenant_store_name)
                setTenantDisableInitial(response.data.tenant_disable)
                setTenantStatusInitial(response.data.tenant_status)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])


    useEffect(() => {
        const setStatusOption = async () => {
            if (tenant_disableInitial) {
                setArrayStatusOption(['Reactivate']);
            } else if (!tenant_statusInitial || tenant_statusInitial === 'null') {
                setArrayStatusOption(['UAT']);
            } else if (tenant_statusInitial === 'UAT') {
                setArrayStatusOption(['Live']);
            } else if (tenant_statusInitial === 'Live') {
                setArrayStatusOption(['Disable']);
            }
        };

        setStatusOption();
    }, [tenant_statusInitial, tenant_disableInitial]);


    const handleStatusChange = (value) => {
        if (value === 'UAT') {
            setTenantStatus('UAT')
            setTenantUATAssignee(login_name)
            setAlertMessage('Are you sure you want to make ' + tenant_name + ' UAT?')
            setTask(tenant_name + ' Changed to UAT')
        }
        else if (value === 'Live') {
            setTenantStatus('Live')
            setTenantLiveAssignee(login_name)
            setAlertMessage('Are you sure you want to make ' + tenant_name + ' Live?')
            setTask(tenant_name + ' Changed to Live')
        }
        else if (value === 'Disable') {
            setTenantDisable(true)
            setTenantDisableAssignee(login_name)
            setAlertMessage('Are you sure you want to make ' + tenant_name + ' Disable?')
            setTask(tenant_name + ' Disabled')
        }
        else if (value === 'Reactivate') {
            setTenantDisable(false);
            setTenantReactivateAssignee(login_name)
            setAlertMessage('Are you sure you want to make ' + tenant_name + ' Reactivate?')
            setTask(tenant_name + ' Reactivated')
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date)
        if (tenant_status === 'Live') {
            setTenantLiveDate(date)
        }
        else if (tenant_status === 'UAT') {
            setTenantUATDate(date)
        }
        else if (tenant_disable === true) {
            setTenantDisableDate(date)
        }
        else if (tenant_disable === false) {
            setTenantReactivateDate(date)
        }
    };

    const handleReasonChange = (reason) => {
        if (tenant_disableInitial) {
            setTenantReactivateReason(reason)
        }
        else if (tenant_statusInitial === 'Live') {
            setTenantDisableReason(reason)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        SweetAlert.fire({
            title: alerMesage,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            reverseButtons: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = {
                    tenant_disable,
                    login_name,
                    login_id,
                    task, //for log
                    ...(tenant_status && { tenant_status }),
                    ...(tenant_uat_date && { tenant_uat_date }),
                    ...(tenant_uat_assignee && { tenant_uat_assignee }),
                    ...(tenant_live_date && { tenant_live_date }),
                    ...(tenant_live_assignee && { tenant_live_assignee }),
                    ...(tenant_disable_date && { tenant_disable_date }),
                    ...(tenant_disable_assignee && { tenant_disable_assignee }),
                    ...(tenant_disable_reason && { tenant_disable_reason }),
                    ...(tenant_reactivate_date && { tenant_reactivate_date }),
                    ...(tenant_reactivate_assignee && { tenant_reactivate_assignee }),
                    ...(tenant_reactivate_reason && { tenant_reactivate_reason }),
                    ...(tenant_data_capture_method && { tenant_data_capture_method }),
                    ...(tenant_mode_of_transfer && { tenant_mode_of_transfer }),

                };
                console.log(data);

                const logData = {
                    tenant_concessionaire_id,
                    tenant_id: id,
                    task: task,
                    date: selectedDate,
                    modified_by: login_name

                }
                if (tenant_disable_reason) {
                    logData.reason = tenant_disable_reason;
                } else if (tenant_reactivate_reason) {
                    logData.reason = tenant_reactivate_reason;
                }

                axios
                    .post(`${config.hostname}/tenant/updatetenant/${id}`, data, configheader)
                    .then(() => {
                        toast.success("Successfully updated");
                        navigate(`${process.env.PUBLIC_URL}/admin/tenant/viewtenant/:layout`);
                    })
                    .catch((error) => {
                        toast.error("Error");
                        console.log(error);
                    });

                //Insert into Tenant Status Table
                axios
                    .post(`${config.hostname}/tenantstatus`, logData, configheader)
                    .then(() => {

                    })
                    .catch((error) => {
                        toast.error("Error");
                        console.log(error);
                    });
            }
        });

    }

    return (
        <Fragment>
            <Breadcrumbs parent='Tenant' title='Update Tenant Status' mainTitle={'Update Tenant Status -' + tenant_name} />
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleSubmit} className="form-bookmark needs-validation">
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Tenant Status</Label>
                                                    <select name="tenantstatus" id="tenantstatus" placeholder="Select Tenant Status" className="form-control digits" required
                                                        onChange={(e) => handleStatusChange(e.target.value)}>
                                                        <option value="">Select Tenant Status</option>
                                                        {arrayStausOption.map((item, index) => (
                                                            <option key={index} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Date</Label>
                                                    <input className="form-control" type="date" name="date" required
                                                        onChange={(e) => handleDateChange(e.target.value)}
                                                        //min={beforeDate.toISOString().split('T')[0]}
                                                        max={today.toISOString().split('T')[0]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {tenant_statusInitial === 'UAT' && (
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Data Capture Method</Label>
                                                        <select name="datamethod" id="datamethod" className="form-control digits" required
                                                            onChange={(e) => setDataCaptureMethod(e.target.value)}>
                                                            <option value="">Select Method</option>
                                                            {arrayCaptureMethod.map((item, index) => (
                                                                <option key={index} value={item}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Mode of Transfer</Label>
                                                        <select name="modeoftransfer" id="modeoftransfer" className="form-control digits" required
                                                            onChange={(e) => setModeofTransfer(e.target.value)}>
                                                            <option value="">Select Mode of Transfer</option>
                                                            {arrayModeOfTransfer.map((item, index) => (
                                                                <option key={index} value={item}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}


                                        {tenant_disableInitial === true || tenant_statusInitial === 'Live' && (
                                            <Row>
                                                <Col md='6'>
                                                    <Label>Reason</Label>
                                                    <textarea className="form-control" type="text" name="reason" placeholder="Reason"
                                                        onChange={(e) => handleReasonChange(e.target.value)} required
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Fragment>
                                    <Row>
                                        <Col >
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Update </Btn>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default StatusChange