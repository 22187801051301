import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import Chart from 'react-apexcharts';
import configDB from '../../../../Config/ThemeConfig';
import config from "../../../../config";
import axios from "axios";

const SalesComparisonMom = ({ year, propertyMallCode }) => {
    const currentYear = new Date().getFullYear();
    const selectedYear = year === '' ? currentYear : year;
    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
    const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const [currency, setCurrency] = useState('');
    const [currentYearData, setCurrentYearData] = useState([]);
    const [previousYearData, setPrevioueYearData] = useState([]);
    const [apiUrl, setApiUrl] = useState('');
    const [data, setData] = useState([]);

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/country/getcountrybyconcessionaire`, { country_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCurrency(response.data[0].country_currency);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (year !== '' || propertyMallCode !== '') {
            setApiUrl('dashboardfiltered/salescomparisonmom')
            const data1 = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode
            }
            setData(data1)
        }
        else {
            setApiUrl('dashboard/salescomparisonmom')
            const data2 = {
                concessionaire_id,
                property_id
            }
            setData(data2)
        }
    }, [year, propertyMallCode]);

    useEffect(() => {
        if (apiUrl !== '') {
            axios
                .post(`${config.hostname}/${apiUrl}`, data, configheader)
                .then((response) => {
                    // Sort the currentYearSales and previousYearSales arrays by month
                    const currentYearSorted = response.data.currentYearSales
                        .sort((a, b) => {
                            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                            return months.indexOf(a.month) - months.indexOf(b.month);
                        })
                        .map(item => (item.totalSales / 1000000).toFixed(2));
                    setCurrentYearData(currentYearSorted);

                    const previousYearSorted = response.data.previousYearSales.sort((a, b) => {
                        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                        return months.indexOf(a.month) - months.indexOf(b.month);
                    }).map(item => (item.totalSales / 1000000).toFixed(2));
                    setPrevioueYearData(previousYearSorted)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, data]);

    const apexColumnChartsone = {

        series: [
            {
                name: selectedYear - 1,
                data: previousYearData
            }, {
                name: selectedYear,
                data: currentYearData
            }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },

            colors: [primary, '#4caaf0'],
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                // title: {
                //     text: 'Sales in millions'
                // },
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2); // Round Y-axis values to two decimal points
                    }
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        // return 'AED ' + val + ' millions';
                        // return `${currency} ${val} millions`;
                        return currency + ' ' + val + ' millions';
                    }
                }
            }
        },
    };

    return (
        <Fragment>
            <Col sm='12' >
                <Card>
                    <HeaderCard title={<div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{'Sales Comparison '}</div>
                        <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>Month on month</div>
                    </div>} />
                    <CardBody>
                        <div id='column-chart'>
                            <Chart options={apexColumnChartsone.options} series={apexColumnChartsone.series} type="bar" height={350} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default SalesComparisonMom