import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { H5, P } from '../../../AbstractElements';
import DeliveryPartnerAdd from './DeliveryPartner/DeliveryPartnerAdd';
import RevenueShareMethod from './RevenueShareMethod';
import OtherCharges from './OtherCharges';

const Configurations = () => {

    if (localStorage.getItem("manageConfigurations") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [BasicLineTab, setBasicLineTab] = useState('1');
    return (
        <Col sm='12' xl='12' className='xl-100'>
            <Card>
                <CardHeader>
                    <H5>Configurations</H5>
                </CardHeader>
                <CardBody>
                    <Nav className='border-tab' tabs>
                        <NavItem>
                            <NavLink href='#javascript' className={BasicLineTab === '1' ? 'active' : ''} onClick={() => setBasicLineTab('1')}>
                                <i className='icofont '></i>
                                {'Delivery Partners'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#javascript' className={BasicLineTab === '2' ? 'active' : ''} onClick={() => setBasicLineTab('2')}>
                                <i className='icofont '></i>
                                {'Revenue Share Methods'}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href='#javascript' className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('3')}>
                                <i className='icofont'></i>
                                {'Charges'}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={BasicLineTab}>
                        <TabPane className='fade show' tabId='1'>
                            <P attrPara={{ className: 'mb-0 m-t-30' }}>
                                <DeliveryPartnerAdd />
                            </P>
                        </TabPane>
                        <TabPane className='fade show' tabId='2'>
                            <P attrPara={{ className: 'mb-0 m-t-30' }}>
                                <RevenueShareMethod />
                            </P>
                        </TabPane>
                        <TabPane className='fade show' tabId='3'>
                            <P attrPara={{ className: 'mb-0 m-t-30' }}>
                                <OtherCharges />
                            </P>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Col>
    );
};

export default Configurations;
