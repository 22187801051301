import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import TenantDtl from './TenantDtl';

const TenantDetail = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle='View Tenant' parent='Tenant' title='Tenant Detail' />
            <Container fluid={true}>
                <div className='edit-profile'>
                    <Row>
                        <TenantDtl />
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}

export default TenantDetail