import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';
import { useNavigate } from 'react-router-dom';

const PropertyDtl = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");

    if ((manageGeneralSettings !== "1" && accessGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [properties, setProperties] = useState({});
    const [floors, setFloors] = useState([]);
    const [concessionaire_name, setConcessionaireName] = useState('');
    const [country_name, setCountryName] = useState('');
    const [property_created_Date, setPropertyCreatedDate] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios.get(`${config.hostname}/property/${id}`, configheader)
            .then((response) => {
                setProperties(response.data);
                const concessionaireName = response.data.property_concessionaire_id.concessionaire_name;
                setConcessionaireName(concessionaireName);
                const countryName = response.data.property_country_id.country_name;
                setCountryName(countryName);
                const createddate = new Date(response.data.property_created_date).toISOString().split('T')[0];
                setPropertyCreatedDate(createddate);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios.post(`${config.hostname}/property/getpropertyfloors`, { property_id: id }, configheader)
            .then((response) => {
                setFloors(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const floorNames = floors.length > 0
        ? floors.map(floor => floor.floor_id.floor_name).join(', ')
        : '';


    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/property/editproperty/${id}`);
    }

    return (
        <Row>
            <Col xl='4'>
                <Fragment>
                    <Card>
                        <CardHeader>
                            <H4 attrH4={{ className: 'card-title mb-0' }}>{properties.property_name}</H4>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row className='mb-2'>
                                    <div className='profile-title'>
                                        <div className='media'>
                                            <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/img001.jpg')}` }} />

                                        </div>
                                    </div>
                                </Row>
                                <FormGroup className='mb-3'>
                                    <H6 attrH6={{ className: 'form-label' }}>Address</H6>
                                    <Input type='textarea' className='form-control' rows='5' disabled defaultValue={properties.property_address} />
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Fragment>
            </Col>
            <Col xl='8'>
                <Fragment>
                    <Form className="card" >
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Property Details</H4>
                                {manageGeneralSettings === "1" && (
                                    <Btn attrBtn={{ color: "primary", type: "submit", marginLeft: '10px', onClick: () => handleEditClick() }}  >Edit</Btn>
                                )}
                            </div>

                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Concessionaire Name</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_name} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup> <Label className="form-label">Area</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={properties.property_area} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Mall code</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={properties.property_mall_code} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Currency</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={properties.property_mall_currency} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Country</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={country_name} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Stores</Label>
                                        <Input className="form-control" type="text" disabled defaultValue={properties.property_stores} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Disabled</Label>
                                        <Input className="form-control" type="text" disabled value={properties.property_disable ? 'Yes' : 'No'} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Created By</Label>
                                        <Input className="form-control" type="text" disabled defaultValue={properties.property_created_by} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Created Date</Label>
                                        <Input className="form-control" type="text" disabled defaultValue={property_created_Date} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Floors</Label>
                                        <Input className="form-control" type="textarea" disabled="true" value={floorNames} rows="3" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Fragment>
            </Col>
        </Row>
    )
}

export default PropertyDtl