import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Button, Input } from "reactstrap";
import axios from "axios";
import config from "../../../../config";
import { Typeahead } from 'react-bootstrap-typeahead';

const UnitInfo = ({ setSteps, setFormdata, formdata }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        if (data) {
            setFormdata((prev) => ({ ...prev, ...data }));
            setSteps((pre) => pre + 1);
        }
    };

    const concessionaire_id = localStorage.getItem("concessionaireId");

    const [tenant_category_id, setCategoryId] = useState('');
    const [tenant_subcategory_id, setSubcategoryId] = useState('');
    const [tenant_current_status, setCurrentStatus] = useState('');
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [floors, setFloors] = useState([]);
    const [tenant_store_type, setStoreType] = useState('');
    const [deliveryPartners, setDeliveryPartners] = useState([]);

    const arrayStoreType = ['Anchor', 'Vanilla', 'Kiosk', 'Other'];
    const arrayCurrentStatus = ['Trading', 'Fitout', 'Closed Temporarily', 'Closed Permanently'];

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };



    const handleCategoryChange = (e) => {
        const newCategoryId = e.target.value;
        setCategoryId(newCategoryId);
    };

    const handleSubcategoryChange = (e) => {
        setSubcategoryId(e.target.value);
    };

    const handleStoreTypeChange = (event) => {
        setStoreType(event.target.value);
    };

    const handleCurrentStatusChange = (event) => {
        setCurrentStatus(event.target.value);
    };

    const handlePartnerSelection = (selected) => {
        formdata.deliveryPartnersId = selected.map(item => item.delivery_partner_id)
    };


    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {

                const filteredSubcategories = response.data.filter(subcategory => subcategory.category_id._id === tenant_category_id);
                setSubcategories(filteredSubcategories);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [tenant_category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyproperty`, { property_id: formdata.property_id }, configheader)
            .then((response) => {
                setFloors(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {

        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, { concessionaire_id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    delivery_partner_id: item.delivery_partner_id._id,
                    delivery_partner_name: item.delivery_partner_id.delivery_partner_name
                }));
                setDeliveryPartners(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_code">Tenant Code</Label>
                                    <input className={`form-control ${errors.tenant_code && "is-invalid"}`}
                                        id="tenant_code" type="text" name="tenant_code" defaultValue={formdata.tenant_code || ""}
                                        {...register("tenant_code", { required: true })} placeholder="Tenant code" />
                                    <span className="text-danger">{errors.tenant_code && "Tenant code is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_unit_code">Unit Code</Label>
                                    <input className={`form-control ${errors.tenant_unit_code && "is-invalid"}`}
                                        id="tenant_unit_code" type="text" name="tenant_unit_code" defaultValue={formdata.tenant_unit_code || ""}
                                        {...register("tenant_unit_code", { required: true })} placeholder="Unit code" />
                                    <span className="text-danger">{errors.tenant_unit_code && "Unit code is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_store_type">Store Type</Label>
                                    <select name="tenant_store_type" id="tenant_store_type" className="form-control digits"
                                        {...register("tenant_store_type", { required: true })}
                                        defaultValue={formdata.tenant_store_type} onChange={handleStoreTypeChange}>
                                        <option value="">Select Store type</option>
                                        {arrayStoreType.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_store_type && "Store type is required"}</span>
                                    {tenant_store_type === 'Other' && (
                                        <div>
                                            <input className={`form-control ${errors.store_type && "is-invalid"} mt-3`}
                                                id="other_store_type" type="text" name="other_store_type" defaultValue={formdata.other_store_type || ""}
                                                {...register("other_store_type", { required: true })} placeholder="Enter Store Type" />
                                            <span className="text-danger">{errors.other_store_type && "Specify store type"}</span>
                                        </div>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_current_status">Current Status</Label>
                                    <select name="tenant_current_status" id="tenant_current_status" className="form-control digits"
                                        {...register("tenant_current_status", { required: true })}
                                        value={formdata.tenant_current_status} onChange={handleCurrentStatusChange}>
                                        <option value="">Select Current Status</option>
                                        {arrayCurrentStatus.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_current_status && "Current Status is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_carpet_area">Carpet Area</Label>
                                    <input className={`form-control ${errors.tenant_carpet_area && "is-invalid"}`}
                                        id="tenant_carpet_area" type="number"
                                        step="0.001"  // This allows decimal input
                                        name="tenant_carpet_area" defaultValue={formdata.tenant_carpet_area || ""}
                                        {...register("tenant_carpet_area", {
                                            required: true, validate: {
                                                notZero: value => value !== "0" || "Carpet Area cannot be 0"
                                            }
                                        })} placeholder="Carpet Area" />
                                    <span className="text-danger">{errors.tenant_carpet_area && "Carpet Area is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_chargable_area">Chargeable Area</Label>
                                    <input className={`form-control ${errors.tenant_chargable_area && "is-invalid"}`}
                                        id="tenant_chargable_area" type="number"
                                        step="0.001"  // This allows decimal input
                                        name="tenant_chargable_area" defaultValue={formdata.tenant_chargable_area || ""}
                                        {...register("tenant_chargable_area", {
                                            required: true, validate: {
                                                notZero: value => value !== "0" || "Chargable Area cannot be 0"
                                            }
                                        })} placeholder="Chargable Area" />
                                    <span className="text-danger">{errors.tenant_chargable_area && "Chargable Area is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_floor">Floor</Label>
                                    {/* <input className={`form-control ${errors.tenant_floor && "is-invalid"}`}
                                        id="tenant_floor" type="text" name="tenant_floor" defaultValue={formdata.tenant_floor || ""}
                                        {...register("tenant_floor", { required: true })} placeholder="Floor" /> */}

                                    <select name="tenant_floor" id="tenant_floor" className={`form-control ${errors.tenant_floor && "is-invalid"}`}
                                        {...register("tenant_floor", { required: true })}
                                        defaultValue={formdata.tenant_floor} >
                                        <option value="">Select Floor</option>
                                        {floors.map(floor => (
                                            <option key={floor.floor_id.floor_name} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_floor && "Floor is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_location">Location</Label>
                                    <input className={`form-control ${errors.tenant_location && "is-invalid"}`}
                                        id="tenant_location" type="text" name="tenant_location" defaultValue={formdata.tenant_location || ""}
                                        {...register("tenant_location", { required: true })} placeholder="Location" />
                                    <span className="text-danger">{errors.tenant_location && "Floor is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_category_id">Category</Label>
                                    <select name="tenant_category_id" id="tenant_category_id" className={`form-control ${errors.tenant_category_id && "is-invalid"}`}
                                        {...register("tenant_category_id", { required: true })}
                                        defaultValue={formdata.tenant_category_id} onChange={handleCategoryChange}>
                                        <option value="">Select Category</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_category_id && "Category is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_subcategory_id">Sub Category</Label>
                                    <select name="tenant_subcategory_id" id="tenant_subcategory_id" className={`form-control ${errors.tenant_subcategory_id && "is-invalid"}`}
                                        {...register("tenant_subcategory_id", { required: true })}
                                        defaultValue={formdata.tenant_subcategory_id} onChange={handleSubcategoryChange}>
                                        <option value="">Select Subcategory</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_subcategory_id && "Sub Category is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl='6'>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_deliverypartners_id">Delivery Partners</Label>
                                    <Typeahead
                                        id="tenant_deliverypartners_id"
                                        name="tenant_deliverypartners_id"
                                        clearButton
                                        labelKey="delivery_partner_name"
                                        multiple
                                        options={deliveryPartners}
                                        placeholder="Choose delivery partners..."
                                        onChange={handlePartnerSelection}
                                        renderMenuItemChildren={(option) => (
                                            <span>{option.delivery_partner_name}</span>
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button className="secondary me-2" color="primary" onClick={() => setSteps((pre) => pre - 1)}>
                                Previous
                            </Button>
                            <Button className="primary" color="primary" type="submit">
                                Next
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default UnitInfo