import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import DaywiseConsolidatedView from './DaywiseConsolidatedView'

const DaywiseConsolidated = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Report" title="Sales Summary Report" mainTitle="Sales Summary Report" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DaywiseConsolidatedView />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default DaywiseConsolidated