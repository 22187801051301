import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Btn, Breadcrumbs, Spinner } from '../../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Form, FormGroup, Label, Input, InputGroup, InputGroupText, CardHeader, Media } from 'reactstrap';

const PortalManage = () => {


    if (localStorage.getItem("tenantPortalManage") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }


    const [tenants, setTenants] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [properties, setProperties] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();


    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                // const propertyNames = response.data.map(property => property.property_name).join(',');
                // setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);


    useEffect(() => {
        let filteredTenants = allTenants;

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        setTenants(filteredTenants);
    }, [property_id, allTenants]);

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setSearchTerm('');
    };


    const handleManualChange = (id, isChecked, tenantName) => {

        const data = {

            login_id,
            tenant_manual_flag: isChecked
        };

        axios
            .post(`${config.hostname}/tenant/updatetenant/${id}`, data, configheader)
            .then(() => {
                // Update the tenant list state to reflect the new tenant_manual_flag
                setTenants(prevTenants =>
                    prevTenants.map(tenant =>
                        tenant._id === id ? { ...tenant, tenant_manual_flag: isChecked } : tenant
                    )
                );
                const enableStatus = isChecked ? ' enabled' : ' disabled'
                insertToLog(enableStatus + ' manual portal access to ' + tenantName)
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });

    }

    const tenantColumns = [

        {
            name: 'Store Name',
            selector: (row) => row.tenant_store_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '100px',
        },
        {
            name: 'Tenant Code',
            selector: (row) => row.tenant_code,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '80px',

        },
        {
            name: 'Property',
            selector: (row) => row.tenant_property_id.property_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '100px',

        },
        {
            name: 'Enable',
            cell: (row) => (
                <div>
                    <span>
                        <Media key={row._id}>
                            <Media body className='text-end icon-state switch-outline'>
                                <Label className='switch'>
                                    <Input type='checkbox'
                                        // disabled={row.tenant_manual_flag}
                                        checked={row.tenant_manual_flag}
                                        onChange={(e) => handleManualChange(row._id, e.target.checked, row.tenant_store_name)} />
                                    <span className='switch-state'></span>
                                </Label>
                            </Media>
                        </Media>
                    </span>
                </div>
            ),
            sortable: false,
            center: false,
            minWidth: '80px',
        }

    ]


    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        let filteredTenants = allTenants;

        // Apply property filter
        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(
                (tenant) => tenant.tenant_property_id._id === property_id
            );
        }

        // Apply search term filter
        if (searchTerm.trim() !== '') {
            filteredTenants = filteredTenants.filter(
                (tenant) =>
                    (tenant.tenant_store_name &&
                        tenant.tenant_store_name.toLowerCase().includes(searchTerm)) ||
                    (tenant.tenant_code &&
                        tenant.tenant_code.toLowerCase().includes(searchTerm))
            );
        }

        setTenants(filteredTenants);
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Admin Settings",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {

                console.log(error);

            });
    }

    return (
        <Row>

            <Col sm="12">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xl='6'>
                                <FormGroup className="mb-3">
                                    {/* <Label htmlFor="property_id">Property</Label> */}
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All Properties</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col xl="6">
                                <InputGroup className='input-group flex-nowrap'>
                                    <Input
                                        className='form-control'
                                        type='search'
                                        value={searchTerm}
                                        placeholder='Search Store Name/Tenant Code'
                                        onChange={handleSearchChange}
                                    />
                                    <InputGroupText>
                                        <i className='fa fa-search'></i>
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {loading === true ? (
                            <div className="loader-box">
                                <Spinner attrSpinner={{ className: 'loader-3' }} />
                            </div>
                        ) : (
                            <div className='table-responsive product-table'>
                                <DataTable
                                    pagination
                                    columns={tenantColumns}
                                    data={tenants}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}

                                >
                                </DataTable>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PortalManage