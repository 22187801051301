import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumbs, Spinner, Btn } from '../../../AbstractElements';
import { Container } from 'reactstrap';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import config from '../../../config';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const RunReport = () => {

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dynamic_report_id = queryParams.get('dynamic_report_id');

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const currentYear = new Date().getFullYear();
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [group_name, setGroupName] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [dynamic_report_name, setDynamicReportName] = useState('');
    const [report_name, setReportName] = useState('');
    const [filterValues, setFilterValues] = useState({});
    const [selectedReportFilters, setSelectedReportFilters] = useState([]);
    const [year, setYear] = useState('');
    const [startMonth, setStartMonth] = useState('');
    const [endMonth, setEndMonth] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [reportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    useEffect(() => {
        axios.post(`${config.hostname}/dynamicreports/getdynamicreport`, { report_id: id, dynamic_report_id }, configheader)
            .then((response) => {
                setDynamicReportName(response.data.report.dynamic_report_name)
                setReportName(response.data.report.report_name)
                setSelectedReportFilters(response.data.filters);
                const initialFilterValues = {};
                response.data.filters.forEach(filter => {
                    initialFilterValues[filter.filter_name] = filter.filter_value;
                });

                setFilterValues(initialFilterValues);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);



    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);



    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, { concessionaire_id }, configheader)
            .then((response) => {
                const deliveryPartnerNames = response.data.map(item => item.delivery_partner_id.delivery_partner_name)
                    .filter(name => name !== "Dine In");
                setDeliveryPartners(deliveryPartnerNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };



    const renderInput = (filterName, filterId) => {
        const value = filterValues[filterName] || '';
        switch (filterName) {
            case "Year":
                return (
                    <select name="year" id="year" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}
                        required>
                        <option value="">Select {filterName}</option>
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>{currentYear - 1}</option>
                        <option value={currentYear - 2}>{currentYear - 2}</option>
                    </select>
                );
            case "Start Date":
                return <Input type="date" name="startdate" value={value} onChange={(e) => {
                    setFilterValues({ ...filterValues, [filterName]: e.target.value })
                    setReportData([]);
                    setCurrentPage(0);
                }}
                    required />;
            case "End Date":
                return <Input type="date" name="endDate" value={value} onChange={(e) => {
                    setFilterValues({ ...filterValues, [filterName]: e.target.value });
                    setReportData([]);
                    setCurrentPage(0);
                }} required />;
            case "Start Month":
                return (
                    <select name="startMonth" id="startMonth" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}
                        required>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "End Month":
                return (
                    <select name="endMonth" id="endMonth" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }} required>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "Property":
                return (
                    <select name="property" id="property" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setPropertyId(e.target.value)
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {properties.map(property => (
                            <option key={property._id} value={property._id}>{property.property_name}</option>
                        ))}
                    </select>
                );
            case "Category":
                return (
                    <select name="category" id="category" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setCategoryId(e.target.value)
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>{category.category_name}</option>
                        ))}
                    </select>
                );
            case "Subcategory":
                return (
                    <select name="subcategory" id="subcategory" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setSubcategoryId(e.target.value)
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {subcategories.map(subcategory => (
                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                        ))}
                    </select>
                );
            case "Group":
                return (
                    <select name="group" id="group" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setGroupName(e.target.value)
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {groups.map((group, index) => (
                            <option key={index} value={group}>{group}</option>
                        ))}
                    </select>
                );
            case "Tenant":
                return (
                    <select name="tenant" id="tenant" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setTenantId(e.target.value)
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {tenants.map(tenant => (
                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                        ))}
                    </select>
                );
            case "Floor":
                return (
                    <select name="floor" id="floor" className="form-control"
                        value={value}
                        onChange={(e) => {
                            setFilterValues({ ...filterValues, [filterName]: e.target.value });
                            setReportData([]);
                            setCurrentPage(0);
                        }}>
                        <option value="">All</option>
                        {floors.map((floor, index) => (
                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                        ))}
                    </select>
                );
            default:
                return <Input type="text" name={filterId} value={value} onChange={(e) => setFilterValues({ ...filterValues, [filterName]: e.target.value })} />;
        }
    };



    const generateReport = async (e) => {
        e.preventDefault();
        // Validation for start month and end month
        if (parseInt(filterValues.endMonth || endMonth) < parseInt(filterValues.startMonth || startMonth)) {
            setValidationMessage('End month cannot be less than start month');
            return;
        } else {
            setValidationMessage('');
        }

        let reportUrl = '';

        switch (report_name) {
            case 'Daywise Report':
                reportUrl = 'getdaywisereport';
                break;
            case 'Sales Summary Report':
                reportUrl = 'getdaywiseconsolidatedreport';
                break;
            case 'Revenue Share Report':
                reportUrl = 'getrevenuesharereport';
                break;
            case 'Trading Density Report':
                reportUrl = 'gettradingdensityreport';
                break;
            case 'F&B Sales Summary Report':
                reportUrl = 'getfbsalessummary';
                break;
            case 'Sales Report-Delivery Partner':
                reportUrl = 'getdeliverypartnersales';
                break;
            default:
                return;
        }
        const property_name = properties.find(property => property._id === filterValues['Property'])?.property_name
        const category_name = categories.find(category => category._id === filterValues['Category'])?.category_name
        const subcategory_name = subcategories.find(subcategory => subcategory._id === filterValues['Subcategory'])?.subcategory_name
        const tenant_name = tenants.find(tenant => tenant._id === filterValues['Tenant'])?.tenant_store_name

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            year: filterValues['Year'] || year,
            start_month: filterValues['Start Month'] || startMonth,
            end_month: filterValues['End Month'] || endMonth,
            start_date: filterValues['Start Date'] || startDate,
            end_date: filterValues['End Date'] || endDate,
            property_name: property_name,
            category_name: category_name,
            subcategory_name: subcategory_name,
            group_name: filterValues['Group'] || group_name,
            tenant_name: tenant_name,
            floor: filterValues['Floor'] || selectedFloor,
        };
        setLoading(true);

        axios
            .post(`${config.hostname}/reports/${reportUrl}`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false);

                insertToLog("Generated " + dynamic_report_name);
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false);
                console.log(error);
            });
    };


    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Dynamic Report",
            task: task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }



    const initialReportColumnsMapping = {
        'Daywise Report': [
            { key: 'tenant_store_name', label: 'Tenant Name' },
            { key: 'tenant_group_name', label: 'Group Name' },
            { key: 'tenant_code', label: 'Tenant Code' },
            { key: 'tenant_unit_code', label: 'Unit Code' },
            { key: 'property_name', label: 'Property' },
            { key: 'subcategory_name', label: 'Subcategory' },
            { key: 'floor', label: 'Floor' },
            { key: 'transaction_date', label: 'Date' },
            { key: 'transaction_count', label: 'Transaction Count' },
            { key: 'net_sales', label: 'Net Sales' },
        ],
        'Sales Summary Report': [
            { key: 'tenant_store_name', label: 'Tenant Name' },
            { key: 'tenant_group_name', label: 'Group Name' },
            { key: 'tenant_code', label: 'Tenant Code' },
            { key: 'tenant_unit_code', label: 'Unit Code' },
            { key: 'property_name', label: 'Property' },
            { key: 'subcategory_name', label: 'Subcategory' },
            { key: 'floor', label: 'Floor' },
            { key: 'total_transaction_count', label: 'Transaction Count' },
            { key: 'total_net_sales', label: 'Net Sales' },
        ],
        'Revenue Share Report': [
            { key: 'tenant_store_name', label: 'Tenant Name' },
            { key: 'tenant_group_name', label: 'Group Name' },
            { key: 'tenant_code', label: 'Tenant Code' },
            { key: 'tenant_unit_code', label: 'Unit Code' },
            { key: 'property_name', label: 'Property' },
            { key: 'subcategory_name', label: 'Subcategory' },
            { key: 'floor', label: 'Floor' },
            { key: 'total_transaction_count', label: 'Transaction Count' },
            { key: 'total_net_sales', label: 'Net Sales' },
            { key: 'tenant_base_rent', label: 'Base Rent' },
            { key: 'tenant_revenue_share_percentage', label: 'TRevenue Share %' },
            { key: 'revenue_share_amount', label: 'Revenue Share Amount' },
            { key: 'tenant_chargable_area', label: 'Store Area' },
            { key: 'minimum_rent_per_sqft', label: 'Minimum Rent per SQF' },
            { key: 'rent_per_sqft', label: 'Rent per SQF' },
            { key: 'lease_start_date', label: 'Lease Start Date' },
            { key: 'lease_end_date', label: 'Lease End Date' },
        ],
        'Trading Density Report': [
            { key: 'tenant_store_name', label: 'Tenant Name' },
            { key: 'tenant_group_name', label: 'Group Name' },
            { key: 'tenant_code', label: 'Tenant Code' },
            { key: 'tenant_unit_code', label: 'Unit Code' },
            { key: 'property_name', label: 'Property' },
            { key: 'subcategory_name', label: 'Subcategory' },
            { key: 'floor', label: 'Floor' },
            { key: 'tenant_chargable_area', label: 'Area' },
            { key: 'total_transaction_count', label: 'Transaction Count' },
            { key: 'total_net_sales', label: 'Net Sales' },
            { key: 'trading_density', label: 'Trading Density' },
            { key: 'atv', label: 'ATV' }
        ],

        'F&B Sales Summary Report': [
            { key: 'tenant_store_name', label: 'Tenant Name' },
            { key: 'tenant_group_name', label: 'Group Name' },
            { key: 'tenant_code', label: 'Tenant Code' },
            { key: 'tenant_unit_code', label: 'Unit Code' },
            { key: 'property_name', label: 'Property' },
            { key: 'subcategory_name', label: 'Subcategory' },
            { key: 'dine_in_count', label: 'Dine In Count' },
            { key: 'dine_in_sales', label: 'Dine In Sales' },
        ],

        'Sales Report-Delivery Partner': [
            { key: 'delivery_partner_name', label: 'Delivery Partner Name' },
            { key: 'delivery_partner_count', label: 'Count' },
            { key: 'delivery_partner_sales', label: 'Net Sales' }
        ]

    };

    const [reportColumnsMapping, setReportColumnsMapping] = useState(initialReportColumnsMapping);

    useEffect(() => {
        if (report_name === 'F&B Sales Summary Report' && deliveryPartners.length > 0) {
            const dynamicColumns = deliveryPartners.map(partner => {
                const partnerKey = partner.toLowerCase().replace(/ /g, '_');
                return [
                    { key: `${partnerKey}_count`, label: `${partner} Count` },
                    { key: `${partnerKey}_sales`, label: `${partner} Sales` }
                ];
            }).flat();
            setReportColumnsMapping(prevState => ({
                ...prevState,
                'F&B Sales Summary Report': [
                    ...prevState['F&B Sales Summary Report'],
                    ...dynamicColumns
                ]
            }));
        }
    }, [report_name, deliveryPartners]);

    useEffect(() => {
        if (report_name === 'F&B Sales Summary Report' && reportData.length > 0 && deliveryPartners.length > 0) {
            const updatedData = reportData.map(item => {
                deliveryPartners.forEach(partner => {
                    const partnerKey = partner.toLowerCase().replace(/ /g, '_');
                    item[`${partnerKey}_count`] = item.delivery_partner_counts[`${partnerKey}_count`] || 0;
                    item[`${partnerKey}_sales`] = item.delivery_partner_sales[`${partnerKey}_sales`] || 0.00;
                });
                return item;
            });
            setReportData(updatedData);
        }
    }, [reportData, report_name, deliveryPartners]);




    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    const pageCount = Math.ceil(reportData.length / itemsPerPage);

    // const totalTransactionCount = reportData.reduce((sum, item) => sum + item.transaction_count, 0);
    // const totalNetSales = reportData.reduce((sum, item) => sum + item.net_sales, 0);

    const sortedReportData = React.useMemo(() => {
        let sortableData = [...reportData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [reportData, sortConfig]);


    // const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const currentPageData = React.useMemo(() => {
        return sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    }, [sortedReportData, currentPage, itemsPerPage]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };


    const handleExport = (format) => {

        if (format === 'excel') {
            exportToExcel(reportData, reportColumnsMapping, report_name, filterValues);
            insertToLog(' exported ' + { report_name } + ' to excel');
        } else if (format === 'pdf') {
            exportToPDF(reportData, reportColumnsMapping, report_name, filterValues);
            insertToLog(' exported ' + { report_name } + ' to pdf')
        }
    };



    const exportToPDF = (reportData, reportColumnsMapping, report_name, filterValues) => {
        const doc = new jsPDF('p', 'pt', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();

        // Add report name as a heading, center-aligned
        doc.setFontSize(13);
        doc.setFont('helvetica', 'bold');
        doc.text(report_name, pageWidth / 2, 20, { align: 'center' });

        // Reset font for metadata with a smaller font size
        doc.setFontSize(7);
        doc.setFont('helvetica', 'normal');

        // Generate metadata
        const dateDisplay = filterValues['Start Date'] === filterValues['End Date']
            ? `Date: ${filterValues['Start Date']}`
            : `From: ${filterValues['Start Date']} To: ${filterValues['End Date']}`;

        const metadata = [
            dateDisplay,
            `Property: ${filterValues['Property'] ? properties.find(property => property._id === filterValues['Property'])?.property_name : 'All'}`,
            `Category: ${filterValues['Category'] ? categories.find(category => category._id === filterValues['Category'])?.category_name : 'All'}`,
            `Subcategory: ${filterValues['Subcategory'] ? subcategories.find(subcategory => subcategory._id === filterValues['Subcategory'])?.subcategory_name : 'All'}`,
            `Group: ${filterValues['Group'] || 'All'}`,
            `Tenant: ${filterValues['Tenant'] ? tenants.find(tenant => tenant._id === filterValues['Tenant'])?.tenant_store_name : 'All'}`,
            `Floor: ${filterValues['Floor'] || 'All'}`,
            `Exported By: ${login_name}`,
            `Exported On: ${new Date().toLocaleString()}`,
        ];

        // Add metadata to the PDF
        let startY = 40;
        const lineHeight = 8;
        const startX = 10;

        metadata.forEach((text, index) => {
            doc.text(text, startX, startY + (index * lineHeight));
        });

        // Add a space before the table
        startY += metadata.length * lineHeight + 10;

        // Add report data
        const tableColumn = reportColumnsMapping[report_name].map(column => column.label);
        const tableRows = reportData.map(item => {
            return reportColumnsMapping[report_name].map(column => item[column.key]);
        });

        autoTable(doc, {
            head: [tableColumn],
            body: tableRows,
            startY: startY + 10,
            headStyles: { fontSize: 7 },
            bodyStyles: { fontSize: 7 },
            margin: { top: 10, bottom: 10, left: 10, right: 10 },
            tableWidth: 'auto',
            pageBreak: 'auto',
        });

        doc.save(`${dynamic_report_name}.pdf`);
    };



    const exportToExcel = (reportData, reportColumnsMapping, report_name, filterValues) => {
        // Generate metadata
        const dateDisplay = filterValues['Start Date'] === filterValues['End Date']
            ? `Date: ${filterValues['Start Date']}`
            : `From: ${filterValues['Start Date']} To: ${filterValues['End Date']}`;

        const metadata = [
            dateDisplay,
            `Property: ${filterValues['Property'] ? properties.find(property => property._id === filterValues['Property'])?.property_name : 'All'}`,
            `Category: ${filterValues['Category'] ? categories.find(category => category._id === filterValues['Category'])?.category_name : 'All'}`,
            `Subcategory: ${filterValues['Subcategory'] ? subcategories.find(subcategory => subcategory._id === filterValues['Subcategory'])?.subcategory_name : 'All'}`,
            `Group: ${filterValues['Group'] || 'All'}`,
            `Tenant: ${filterValues['Tenant'] ? tenants.find(tenant => tenant._id === filterValues['Tenant'])?.tenant_store_name : 'All'}`,
            `Floor: ${filterValues['Floor'] || 'All'}`,
            `Exported By: ${login_name}`,
            `Exported On: ${new Date().toLocaleString()}`,
        ];

        // Prepare the heading and metadata
        const headingRow = [[report_name]];

        // Preparing report data
        const reportHeaders = reportColumnsMapping[report_name].map(column => column.label);
        const reportDataRows = reportData.map(item => {
            return reportColumnsMapping[report_name].map(column => item[column.key]);
        });

        // Combine heading, metadata, and report data
        const combinedData = [
            ...headingRow,
            ...metadata.map(item => [item]),
            [''],
            reportHeaders,
            ...reportDataRows,
        ];

        // Create final worksheet and workbook
        const finalSheet = XLSX.utils.aoa_to_sheet(combinedData);

        // Center-align and style the report name heading
        finalSheet['A1'].s = {
            font: { sz: 13, bold: true },
            alignment: { horizontal: 'center', vertical: 'center' }
        };

        // Merge the cells for the heading to center-align across all columns
        const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: reportColumnsMapping[report_name].length - 1 } }];
        finalSheet['!merges'] = merge;

        // Auto adjust column widths
        const maxLengths = {};
        combinedData.forEach(row => {
            row.forEach((cell, index) => {
                if (!maxLengths[index]) {
                    maxLengths[index] = (cell && cell.toString().length) || 0;
                } else {
                    maxLengths[index] = Math.max(maxLengths[index], (cell && cell.toString().length) || 0);
                }
            });
        });

        const columnWidths = Object.keys(maxLengths).map(key => ({
            wch: maxLengths[key] + 2 // Add some padding for better readability
        }));

        finalSheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, finalSheet, 'Report');
        XLSX.writeFile(workbook, `${dynamic_report_name}.xlsx`);
    };




    return (
        <Fragment>
            <Breadcrumbs parent="Dynamic Reports" title="Dynamic Reports" mainTitle={dynamic_report_name} />
            <Container fluid={true}>
                {selectedReportFilters.length > 0 && (
                    <Fragment>
                        <Form className='theme-form' onSubmit={generateReport}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        {selectedReportFilters.map((filter, index) => (
                                            <Col md="6" key={filter.filter_id}>
                                                <FormGroup>
                                                    <Label for={filter.filter_name}>{filter.filter_name}</Label>
                                                    {renderInput(filter.filter_name, filter.filter_id)}
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Button type='submit' color="primary">Generate Report</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </Fragment>
                )}
            </Container>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                            {(report_name !== 'F&B Sales Summary Report') && (
                                <Col xs="auto" style={{ position: 'relative' }}>
                                    <Btn
                                        attrBtn={{
                                            color: 'primary',
                                            type: 'button',
                                            onClick: () => handleExport('pdf'),
                                            onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                            onMouseLeave: handleMouseLeave
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                    </Btn>
                                    {tooltip === 'Export to PDF' && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '3px',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            zIndex: 1,
                                        }}>
                                            {tooltip}
                                        </div>
                                    )}
                                </Col>
                            )}

                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                        <tr>
                                            {reportColumnsMapping[report_name] && reportColumnsMapping[report_name].map((column) => (
                                                <th key={column.key} scope="col" onClick={() => requestSort(column.key)}>
                                                    {column.label}
                                                </th>
                                            ))}
                                            {/* <th scope="col">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                    <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                                                        <i className="fa fa-ellipsis-v" style={{ cursor: 'pointer' }}></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem onClick={() => handleExport('excel')}>Export to Excel</DropdownItem>
                                                        <DropdownItem onClick={() => handleExport('pdf')}>Export to PDF</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportColumnsMapping[report_name] && currentPageData.map((item, index) => (
                                            <tr key={index}>
                                                {reportColumnsMapping[report_name].map((column) => (
                                                    <td key={column.key}>{item[column.key]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {reportData.length > 50 && (
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            )}
                        </Col>
                    </CardBody>
                </Card>

            )}
        </Fragment>
    )
}

export default RunReport