import React, { Fragment, useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import Chart from 'react-apexcharts';
import axios from "axios";
import configDB from '../../../../Config/ThemeConfig';
import config from "../../../../config";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { H5 } from '../../../../AbstractElements';

const SubcategorySales = ({ year, month, propertyMallCode, className }) => {

    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState([]);
    const [chartLabels, setChartLabels] = useState('');
    const [title, setTitle] = useState('All');
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (year !== '' || propertyMallCode !== '' || month !== '') {
            setApiUrl('dashboardfiltered/subcategorysales')
            const data1 = {
                concessionaire_id,
                property_id,
                category_name: categoryName,
                year,
                propertyMallCode,
                month
            }
            setApiData(data1)
        }
        else {
            setApiUrl('dashboard/subcategorysales')
            const data2 = {
                concessionaire_id,
                category_name: categoryName,
                property_id
            }
            setApiData(data2)
        }
    }, [year, month, propertyMallCode, categoryName]);

    useEffect(() => {
        if (apiUrl !== '') {
            axios
                .post(`${config.hostname}/${apiUrl}`, apiData, configheader)
                .then((response) => {
                    const responseData = response.data.subcategorywiseSales;
                    // Transform the response data to get the series array and labels
                    const seriesData = responseData.map(item => item.net_sales);
                    const labels = responseData.map(item => item.subcategory_name);
                    setChartData(seriesData);
                    setChartLabels(labels);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, apiData]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    const randomColors = chartLabels.length ? chartLabels.map(() => getRandomColor()) : [];
    const colors = [primary, ...randomColors];

    const apexPieCharts = {
        series: chartData,
        options: {
            chart: {
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: chartLabels,
            colors: colors,
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '8px',
                    fontWeight: 'normal'
                },
            },
            legend: {
                show: false
            },
            stroke: {
                width: 0 // Remove white space between sections
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };

    const handleOptionSelect = (category) => {
        setCategoryName(category)
        setTitle(category)
    };

    return (
        <Fragment>
            <Col sm='12' xl='4' className={className}>
                <Card>
                    <CardBody className="apex-chart donut-charts">
                        <div>
                            <H5> <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>{'Category Sales '}</div>
                                <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>{title}</div>
                                <div style={{ fontSize: '0.5em', color: '#777', marginLeft: '10px' }}>{'(values in millions)'}</div>
                            </div></H5>
                            <DropdownButton id="dropdown-basic-button" title="Options" variant="dark">
                                <Dropdown.Item
                                    key="all"
                                    onClick={() => setCategoryName('')}
                                >
                                    All
                                </Dropdown.Item>
                                {categories.map((category) => (
                                    <Dropdown.Item
                                        key={category._id}
                                        onClick={() => handleOptionSelect(category.category_name)}
                                    >
                                        {category.category_name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        <div id='donutchart' style={{ height: '235px' }} >
                            <Chart options={apexPieCharts.options} series={apexPieCharts.series} type="pie" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default SubcategorySales