import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Spinner } from '../../../../AbstractElements';
import { Card, CardBody, Col, Table, Form, FormGroup, Label, Row, CardHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const DaywiseReportView = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [group_name, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [ReportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const today = new Date().toISOString().split('T')[0];
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);



    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const generateReport = async (e) => {
        e.preventDefault();

        // Validation for start date and end date
        if (endDate < startDate) {
            setValidationMessage('End date cannot be less than start date');
            return;
        } else {
            setValidationMessage('');
        }

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';
        const tenant_name = tenant_id !== '' ? tenants.find(tenant => tenant._id === tenant_id).tenant_store_name : '';

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            start_date: startDate,
            end_date: endDate,
            group_name,
            category_name,
            property_name,
            subcategory_name,
            tenant_name,
            floor: selectedFloor
        };

        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getdaywisereport`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                insertToLog(" generated Daywise report")
                console.log(response.data);
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setTenantId('');
        setSelectedFloor('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setSubcategoryId('')
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setTenantId('')
        setReportData([]);
        setCurrentPage(0);
    };

    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    //const offset = currentPage * itemsPerPage;
    const pageCount = Math.ceil(ReportData.length / itemsPerPage);

    const totalTransactionCount = ReportData.reduce((sum, item) => sum + item.transaction_count, 0);
    const totalNetSales = ReportData.reduce((sum, item) => sum + item.net_sales, 0);


    const sortedReportData = React.useMemo(() => {
        let sortableData = [...ReportData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [ReportData, sortConfig]);

    const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = key => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'asc') {
                return <FontAwesomeIcon icon={faSortUp} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
            if (sortConfig.direction === 'desc') {
                return <FontAwesomeIcon icon={faSortDown} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
        }

    };


    const handleExport = (format) => {
        if (ReportData.length > 0) {
            if (format === 'excel') {
                exportToExcel();
                insertToLog(" exported Daywise report to excel");
            } else if (format === 'pdf') {
                exportToPDF();
                insertToLog(" exported Daywise report to pdf")
            }
        }
    };


    const exportToExcel = () => {
        // Prepare the data without the 'id' column
        const exportData = ReportData.map(item => ({
            'Tenant Name': item.tenant_store_name,
            'Group Name': item.tenant_group_name,
            'Tenant Code': item.tenant_code,
            'Unit Code': item.tenant_unit_code,
            'Property': item.property_name,
            'Subcategory': item.subcategory_name,
            'Floor': item.floor,
            'Date': item.transaction_date,
            'Transaction Count': item.transaction_count,
            'Net Sales': item.net_sales.toFixed(2)
        }));

        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([[]]);

        // Add the heading
        XLSX.utils.sheet_add_aoa(worksheet, [['Day wise Report']], { origin: 'A1' });

        // Merge the heading cells
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }];

        // Conditional date display
        const dateDisplay = startDate === endDate
            ? [['Date', startDate]]
            : [['From', startDate], ['To', endDate]];

        // Add additional information at the top of the worksheet
        const metadata = [
            ...dateDisplay,
            ['Property', property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'],
            ['Category', category_id ? categories.find(s => s._id === category_id)?.category_name : 'All'],
            ['Group', group_name ? group_name : 'All'],
            ['Subcategory', subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'],
            ['Tenant', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'],
            ['Floor', selectedFloor || 'All'],
            ['Exported By', login_name],
            ['Exported On', new Date().toLocaleString()]
        ];

        // Add metadata to the worksheet below the heading
        XLSX.utils.sheet_add_aoa(worksheet, metadata, { origin: 'A2' });

        // Calculate the starting row for the data
        const startRow = metadata.length + 3;

        // Add column headers once
        const columnHeaders = [['Tenant Name', 'Group Name', 'Tenant Code', 'Unit Code', 'Property', 'Subcategory', 'Floor', 'Date', 'Transaction Count', 'Net Sales']];
        XLSX.utils.sheet_add_aoa(worksheet, columnHeaders, { origin: `A${startRow - 1}` });

        // Add the data below the column headers
        XLSX.utils.sheet_add_json(worksheet, exportData, { origin: `A${startRow}`, skipHeader: true });

        // Auto adjust column widths
        const maxLengths = {};
        exportData.forEach(row => {
            Object.keys(row).forEach(key => {
                if (!maxLengths[key]) {
                    maxLengths[key] = key.length;
                }
                if (row[key] && row[key].length > maxLengths[key]) {
                    maxLengths[key] = row[key].length;
                }
            });
        });

        const columnWidths = Object.keys(maxLengths).map(key => ({
            wch: maxLengths[key] + 2 // Add some padding for better readability
        }));

        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, "DaywiseReport.xlsx");
    };




    const exportToPDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4');
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Add a heading with a reduced font size
        doc.setFontSize(13); // Reduced font size
        doc.setFont('helvetica', 'bold');
        doc.text('Day wise Report', pageWidth / 2, 20, { align: 'center' });

        // Reset font for metadata with a smaller font size
        doc.setFontSize(7); // Reduced font size by 2
        doc.setFont('helvetica', 'normal');

        // Conditional date display
        const dateDisplay = startDate === endDate
            ? [`Date: ${startDate}`]
            : [`From: ${startDate} To: ${endDate}`];

        // Add additional information at the top of the PDF
        const lineHeight = 8; // Reduced line height
        const startX = 10;
        let startY = 40;

        const metadata = [
            ...dateDisplay.map(item => [item]),
            `Property: ${property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'}`,
            `Category: ${category_id ? categories.find(s => s._id === category_id)?.category_name : 'All'}`,
            `Group: ${group_name ? group_name : 'All'}`,
            `Subcategory: ${subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'}`,
            `Tenant: ${tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'}`,
            `Floor: ${selectedFloor || 'All'}`,
            `Exported By: ${login_name}`,
            `Exported On: ${new Date().toLocaleString()}`,
        ];

        metadata.forEach((text, index) => {
            doc.text(text, startX, startY + (index * lineHeight));
        });

        // Add a space before the table
        startY += metadata.length * lineHeight + 10;

        // Function to split data into chunks
        const chunkArray = (array, chunkSize) => {
            const result = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize));
            }
            return result;
        };

        // Optimize chunk size to fit more rows per page and reduce the number of pages
        const rowsPerPage = Math.floor((pageHeight - startY - 30) / 10);
        const chunkSize = rowsPerPage * 3; // Three tables per page
        const dataChunks = chunkArray(ReportData, chunkSize);

        // Footer details
        const totalTransactionCount = ReportData.reduce((sum, item) => sum + item.transaction_count, 0);
        const totalNetSales = ReportData.reduce((sum, item) => sum + item.net_sales, 0);


        const addFooters = (doc, pageCount) => {
            for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);
                const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
                doc.setFontSize(7); // Smaller font size for page number
                doc.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 20, { align: 'center' });
            }
        };

        const addTotalRow = () => {
            doc.autoTable({
                startY: doc.lastAutoTable.finalY + 10,
                head: [['Total', '', '', '', '', '', '', totalTransactionCount, totalNetSales.toLocaleString()]],
                headStyles: { fillColor: [0, 71, 178], textColor: [255, 255, 255], fontSize: 8 },
                bodyStyles: { fillColor: [0, 71, 178], textColor: [255, 255, 255], fontSize: 8 },
                theme: 'grid',
                tableLineWidth: 0.5,
                tableLineColor: [255, 255, 255],
                margin: { top: 10 },
            });
        };

        const processChunk = (chunkIndex) => {
            if (chunkIndex >= dataChunks.length) {
                addTotalRow();
                addFooters(doc, doc.internal.getNumberOfPages());
                doc.save('DaywiseReport.pdf', { compress: true });
                return;
            }

            if (chunkIndex > 0) {
                doc.addPage();
            }

            doc.autoTable({
                startY: chunkIndex === 0 ? startY : 20,
                head: [['Tenant Name', 'Group Name', 'Tenant Code', 'Unit Code', 'Property', 'Subcategory', 'Floor', 'Date', 'Transaction Count', 'Net Sales']],
                body: dataChunks[chunkIndex].map(item => [
                    item.tenant_store_name,
                    item.tenant_group_name,
                    item.tenant_code,
                    item.tenant_unit_code,
                    item.property_name,
                    item.subcategory_name,
                    item.floor,
                    item.transaction_date,
                    item.transaction_count,
                    item.net_sales.toFixed(2)
                ]),
                headStyles: { fontSize: 7 },
                bodyStyles: { fontSize: 7 },
                margin: { top: 10, bottom: 10, left: 10, right: 10 }, // Reduced margins to minimize blank space
                tableWidth: 'auto', // Adjust the table width to fit the content
                pageBreak: 'auto', // Automatically handle page breaks
            });

            // Process next chunk after a short delay
            setTimeout(() => processChunk(chunkIndex + 1), 0);
        };

        // Start processing chunks
        processChunk(0);
    };

    const reset = () => {
        setStartDate('')
        setEndDate('')
        setPropertyId('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setSelectedFloor('');
        setReportData([])

    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="startdate">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={startDate}
                                        max={today} onChange={handleStartDateChange} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="enddate">End Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={endDate}
                                        max={today} onChange={handleEndDateChange} required />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Floor</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={selectedFloor} onChange={handleFloorChange}>
                                        <option value="">All</option>
                                        {floors.map((floor, index) => (
                                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="category_id">Category</Label>
                                    <select name="category_id" id="category_id" className="form-control"
                                        value={category_id} onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>

                        </Row>


                    </CardBody>
                </Card>
            </Form>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('pdf'),
                                        onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                </Btn>
                                {tooltip === 'Export to PDF' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive" >
                                <Table>
                                    <thead >
                                        <tr>
                                            <th scope="col" onClick={() => requestSort('tenant_store_name')}>Tenant Name{getSortIcon('tenant_store_name')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_group_name')}>Group Name {getSortIcon('tenant_group_name')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_code')}>Tenant Code {getSortIcon('tenant_code')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_unit_code')}>Unit Code {getSortIcon('tenant_unit_code')}</th>
                                            <th scope="col" onClick={() => requestSort('property_name')}>Property {getSortIcon('property_name')}</th>
                                            <th scope="col" onClick={() => requestSort('subcategory_name')}>Subcategory {getSortIcon('subcategory_name')}</th>
                                            <th scope="col" onClick={() => requestSort('floor')}>Floor{getSortIcon('floor')}</th>
                                            <th scope="col" onClick={() => requestSort('transaction_date')}>Date{getSortIcon('transaction_date')}</th>
                                            <th scope="col" onClick={() => requestSort('transaction_count')}>Transaction Count{getSortIcon('transaction_count')}</th>
                                            <th scope="col" onClick={() => requestSort('net_sales')}>Net Sales {getSortIcon('net_sales')}</th>
                                            <th scope="col">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {currentPageData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.tenant_store_name}</td>
                                                <td>{item.tenant_group_name}</td>
                                                <td>{item.tenant_code}</td>
                                                <td>{item.tenant_unit_code}</td>
                                                <td>{item.property_name}</td>
                                                <td>{item.subcategory_name}</td>
                                                <td>{item.floor}</td>
                                                <td>{item.transaction_date}</td>
                                                <td>{item.transaction_count}</td>
                                                <td>{parseFloat(item.net_sales).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot >
                                        <tr>
                                            <td colSpan="8">Total</td>
                                            <td>{totalTransactionCount}</td>
                                            <td>{totalNetSales.toLocaleString()}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                            {ReportData.length > 50 && (
                                <ReactPaginate
                                    previousLabel={'<<'}
                                    nextLabel={'>>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                />
                            )}
                        </Col>
                    </CardBody>
                </Card>
            )}
        </Fragment>
    );
};

export default DaywiseReportView;
