import React, { Fragment } from 'react'
import { Breadcrumbs, UL } from '../../../AbstractElements';
import AnalyticsView from './AnalyticsView'
import { Container } from 'reactstrap';
const Analytics = () => {
    return (
        <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
            <Fragment>
                <Breadcrumbs parent="Analytics" title="Analytics" mainTitle="Analytics" />
                <Container fluid={true}>
                    <AnalyticsView />
                </Container>
            </Fragment>
        </UL>
    )
}

export default Analytics