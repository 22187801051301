import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CountryTable from './CountryTable';

const ViewCountry = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Country" title="Country List" mainTitle="Country List" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <CountryTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ViewCountry