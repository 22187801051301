import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';


const NewCategory = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();

    const [category_name, setName] = useState('');
    const category_concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            category_name,
            category_concessionaire_id,
            login_id
        };

        axios
            .post(`${config.hostname}/category`, data, configheader)
            .then(() => {
                toast.success("Successfully Added");
                setName('');
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Category' title='Add New Category' mainTitle='Add New Category' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Category Name</Label>
                                                    <input className="form-control" type="text" name="category" placeholder="Category Name"
                                                        value={category_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.category && 'Category name is required'}</span>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >{Add} </Btn>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/category/viewcategory/:layout`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewCategory