import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import Chart from 'react-apexcharts';
import axios from "axios";
import config from "../../../../config";
import configDB from '../../../../Config/ThemeConfig';


const RestaurantSales = ({ year, month, propertyMallCode, className }) => {

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState([]);
    const [chartLabels, setChartLabels] = useState('');
    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (year !== '' || propertyMallCode !== '' || month !== '') {
            console.log('restaurant year:' + year);
            console.log('restaurant property:' + propertyMallCode);
            setApiUrl('fbdashboardfiltered/restaurentsales')
            const data1 = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode,
                month
            }
            setApiData(data1)
        }
        else {
            setApiUrl('fbdashboard/restaurentsales')
            const data2 = {
                concessionaire_id,
                property_id
            }
            setApiData(data2)
        }
    }, [year, month, propertyMallCode]);

    useEffect(() => {
        if (apiUrl !== '') {
            axios
                .post(`${config.hostname}/${apiUrl}`, apiData, configheader)
                .then((response) => {
                    const responseData = response.data;
                    // Transform the response data to get the series array and labels
                    const seriesData = Object.values(responseData).map(value => parseFloat((parseFloat(value) / 1_000_000).toFixed(2)));
                    const labels = Object.keys(responseData);
                    setChartData(seriesData);
                    setChartLabels(labels);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, apiData]);

    const apexDonutCharts = {
        series: chartData,
        options: {
            chart: {
                type: 'donut',
                toolbar: {
                    show: false
                }
            },
            labels: chartLabels,
            colors: [primary, '#4caaf0', '#51bb25', '#544fff', '#fb740d'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300,
                        offsetX: -20,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    };




    return (
        <Fragment>
            <Col sm='12' xl='6' className={className}>
                <Card>
                    <HeaderCard title='F&B Sales' />
                    <CardBody className="apex-chart donut-charts">
                        <div id='donutchart' style={{ height: '345px' }}>
                            <Chart options={apexDonutCharts.options} series={apexDonutCharts.series} type="donut" width={717} height={348} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default RestaurantSales
