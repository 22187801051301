import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';
import { Typeahead } from 'react-bootstrap-typeahead';

const NewProperty = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [countries, setCountries] = useState([]);

    const [property_name, setName] = useState('');
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const [property_area, setArea] = useState('');
    const [property_mall_code, setMallCode] = useState('');
    const [property_address, setAddress] = useState('');
    const [property_mall_currency, setCurrency] = useState('');
    const [property_country_id, setCountryId] = useState('');
    const [property_stores, setStores] = useState('');
    const [property_disable, setDisable] = useState(false);
    const [floors, setFloors] = useState([]);
    const [floorId, setFloorId] = useState([]);
    const [property_created_date, setCreatedDate] = useState(new Date().toISOString().substr(0, 10));
    const [property_created_by, setCreatedBy] = useState(localStorage.getItem("loginName"));
    const login_id = localStorage.getItem("loginId");

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const handleCountryChange = (e) => {
        setCountryId(e.target.value);
    };
    const handleDisableChange = (value) => {
        const isDisable = value === 'true';
        setDisable(isDisable);
    };


    const handleFloorSelection = (selected) => {
        setFloorId(selected.map(item => item._id));
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/country/getcountrybyconcessionaire`, { country_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloors`, {}, configheader)
            .then((response) => {
                setFloors(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            property_name,
            property_concessionaire_id: concessionaire_id,
            property_area,
            property_mall_code,
            property_address,
            property_mall_currency,
            property_country_id,
            property_stores,
            property_disable,
            property_created_date,
            property_created_by,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/property`, data, configheader)
            .then((response) => {
                const property_id = response.data._id

                if (floorId && floorId.length > 0) {
                    const floorData = {
                        concessionaire_id,
                        property_id,
                        floor_id: floorId,
                        login_id,
                        property_name
                    }
                    axios
                        .post(`${config.hostname}/property/addfloors`, floorData, configheader)
                        .then(() => {
                            console.log('Added floors')
                        })
                        .catch((error) => {
                            toast.error('Error on Adding Floors')
                            console.log(error);
                        });
                }
                toast.success("Successfully Added");
                setName('');
                setArea('');
                setMallCode('');
                setAddress('');
                setCurrency('');
                setStores('')
                setCountryId('')
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Property' title='Add New Proprty' mainTitle='Add New Property' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Property Name</Label>
                                                    <input className="form-control" type="text" name="property" placeholder="Property Name"
                                                        value={property_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Country</Label>
                                                    <select name="country" id="propertycountry" className="form-control digits" required
                                                        value={property_country_id} onChange={handleCountryChange}>
                                                        <option value="">Select Country</option>
                                                        {countries.map(country => (
                                                            <option key={country._id} value={country._id}>{country.country_name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Area</Label>
                                                    <input className="form-control" type="number" name="area" placeholder="Area"
                                                        value={property_area} onChange={(e) => setArea(e.target.value)} required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Mall Code</Label>
                                                    <input className="form-control" type="text" name="mallcode" placeholder="Mall code"
                                                        value={property_mall_code} onChange={(e) => setMallCode(e.target.value)} required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Address</Label>
                                                    <input className="form-control" type="text" name="address" placeholder="Address"
                                                        value={property_address} onChange={(e) => setAddress(e.target.value)} required
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Currency</Label>
                                                    <input className="form-control" type="text" name="currency" placeholder="Currency"
                                                        value={property_mall_currency} onChange={(e) => setCurrency(e.target.value)} required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Stores</Label>
                                                    <input className="form-control" type="number" name="stores" placeholder="Stores" required
                                                        value={property_stores} onChange={(e) => setStores(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Disable</Label>
                                                    <select name="disable" id="propertydisable" className="form-control digits" required
                                                        onChange={(e) => handleDisableChange(e.target.value)}>
                                                        <option value="false">No</option>
                                                        <option value="true">Yes</option>
                                                    </select>
                                                    <span style={{ color: 'red' }}>{errors.description && 'Unit Type is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl='6'>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="tenant_charges_id">Floors</Label>
                                                    <Typeahead
                                                        id="property_floors"
                                                        name="property_floors"
                                                        clearButton
                                                        labelKey="floor_name"
                                                        multiple
                                                        options={floors}
                                                        placeholder="Choose floors..."
                                                        onChange={handleFloorSelection}
                                                        renderMenuItemChildren={(option) => (
                                                            <span>{option.floor_name}</span>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >{Add} </Btn>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/property/viewproperty/:layout`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>

                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewProperty