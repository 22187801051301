import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';
import { useNavigate } from 'react-router-dom';

const CountryDtl = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");

    if ((manageGeneralSettings !== "1" && accessGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    // const { register, formState: { errors } } = useForm();
    const [country, setCountry] = useState({});
    const [concessionaire_name, setConcessionaireName] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios.get(`${config.hostname}/country/${id}`, configheader)
            .then((response) => {
                setCountry(response.data);
                const concessionaireName = response.data.country_concessionaire_id.concessionaire_name;
                setConcessionaireName(concessionaireName);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/country/editcountry/${id}`);
    }

    return (
        <Row>
            <Col xl='4'>
                <Fragment>
                    <Card>
                        <CardHeader>
                            <H4 attrH4={{ className: 'card-title mb-0' }}>{country.country_name}</H4>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row className='mb-2'>
                                    <div className='profile-title'>
                                        <div className='media'>
                                            <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/user/7.jpg')}` }} />

                                        </div>
                                    </div>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Fragment>
            </Col>
            <Col xl='8'>
                <Fragment>
                    <Form className="card" >
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Country Details</H4>
                                {manageGeneralSettings === "1" && (
                                    <Btn attrBtn={{ color: "primary", type: "submit", marginLeft: '10px', onClick: () => handleEditClick() }}  >Edit</Btn>
                                )}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Country code</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={country.country_code} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup> <Label className="form-label">Currency</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={country.country_currency} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Concessionaire Name</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_name} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Fragment>
            </Col>
        </Row>
    )
}

export default CountryDtl