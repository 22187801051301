import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import Widgets1 from '../../../Common/CommonWidgets/Widgets1';
import axios from 'axios';
import config from '../../../../config';


const WidgetsWrapper = () => {

  const token = localStorage.getItem("sessiontoken");
  const configheader = {
    headers: {
      Authorization: `${token}`
    }
  };

  const [totalConcessionaire, setTotalConcessionaire] = useState('');
  const [activeConcessionaire, setActiveConcessionaire] = useState('');
  const [totalTenants, setTotalTenants] = useState('');
  const [liveTenants, setLiveTenants] = useState('');

  useEffect(() => {

    axios
      .get(`${config.hostname}/sadmindashboard/getdetails`, configheader)
      .then((response) => {
        setTotalConcessionaire(response.data.totalConcessionaireCount);
        setActiveConcessionaire(response.data.activeConcessionaireCount);
        setTotalTenants(response.data.totalTenantsCount);
        setLiveTenants(response.data.liveTenantsCount);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  const WidgetTotalConcessionaire = {
    title: 'Total Concessionaire',
    total: totalConcessionaire,
    color: 'primary',
    icon: 'new-order',
  };

  const WidgetActiveConcessionaire = {
    title: 'Active Concessionaire',
    total: activeConcessionaire,
    color: 'warning',
    icon: 'new-order',
  };
  const WidgetTotalTenants = {
    title: 'Total Tenants',
    total: totalTenants,
    color: 'success',
    icon: 'customers',
  };

  const WidgetLiveTenants = {
    title: 'Live Tenants',
    total: liveTenants,
    color: 'secondary',
    icon: 'orders',
  };

  return (
    <>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetTotalConcessionaire} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetActiveConcessionaire} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className='box-col-6'>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetTotalTenants} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetLiveTenants} />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default WidgetsWrapper;
