import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table } from 'reactstrap';
import axios from 'axios';
import { Btn, H6, } from '../../../AbstractElements';
import config from '../../../config';
import { toast } from 'react-toastify';

const ScheduleReportView = () => {

    if (localStorage.getItem("manageScheduleReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const [reports, setReports] = useState([]);
    const [scheduleReports, setScheduleReports] = useState([]);
    const [viewType, setViewType] = useState('');
    const [scheduleReportName, setScheduleReportName] = useState('');
    const [reportId, setReportId] = useState('');
    const [recipients, setRecipients] = useState('');
    const [frequency, setFrequency] = useState('');
    const [alertTime, setAlertTime] = useState('09:00');
    const [scheduleReportId, setScheduleReportId] = useState('');
    const [disable, setDisable] = useState('');

    useEffect(() => {
        axios
            .get(`${config.hostname}/dynamicreports/getreports`, configheader)
            .then((response) => {
                setReports(response.data.filter(report => report.report_name !== 'Zero Sale Detection'));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const fetchScheduleReports = async () => {
        try {
            const response = await axios.post(`${config.hostname}/schedulereports/getallschedulereport`, { concessionaire_id }, configheader);
            setScheduleReports(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchScheduleReports();
    }, []);

    const handleAddClick = (row) => {
        setViewType('Add')
    }

    const handleReportClick = async (id) => {
        try {
            setScheduleReportId(id)
            const response = await axios.get(`${config.hostname}/schedulereports/getSchedulereportbyid/${id}`, configheader);
            setScheduleReportName(response.data.schedule_report_name);
            setReportId(response.data.report_id);
            setRecipients(response.data.recipients)
            setFrequency(response.data.frequency);
            setAlertTime(response.data.alert_time)
            setDisable(response.data.disable)
            setViewType('Edit');
        } catch (error) {
            console.error(error);
            toast.error('Error fetching report details');
        }
    }


    const handleAddSubmit = (e) => {
        e.preventDefault();
        handleAddNewSchedule();
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        handleUpdateProfile();
    };

    const handleAddNewSchedule = async () => {

        const data = {
            concessionaire_id,
            login_id,
            report_id: reportId,
            schedule_report_name: scheduleReportName,
            recipients: recipients,
            frequency: frequency,
            alert_time: alertTime,
            created_by: login_id
        };

        axios
            .post(`${config.hostname}/schedulereports/createschedulereport`, data, configheader)
            .then((response) => {
                toast.success('Report Added Successfully');
                fetchScheduleReports();
                setViewType('');
                setReportId('')
                setScheduleReportName('');
            })
            .catch((error) => {
                toast.error('Error Adding Report');
                console.log(error);
            });
    };

    const handleUpdateProfile = async () => {
        const data = {
            concessionaire_id,
            login_id,
            report_id: reportId,
            schedule_report_name: scheduleReportName,
            recipients: recipients,
            frequency: frequency,
            alert_time: alertTime,
            created_by: login_id,
            disable
        };

        axios
            .post(`${config.hostname}/schedulereports/updateschedulereport/${scheduleReportId}`, data, configheader)
            .then(() => {
                toast.success('Report Added Successfully');
                fetchScheduleReports();
                setViewType('');
                setReportId('')
                setScheduleReportId('')
                setScheduleReportName('');
            })
            .catch((error) => {
                toast.error('Error Adding Report');
                console.log(error);
            });
    };
    return (
        <Fragment>
            {viewType === '' && (
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Schedules</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New</Btn>
                        </div>
                    </CardHeader>
                    {scheduleReports.length > 0 &&
                        <CardBody>
                            <div className='table-responsive product-table'>
                                {
                                    <Table>
                                        <thead >
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col" >Notification Name</th>
                                                <th scope="col" >Frequency</th>
                                                <th scope="col" >Time</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {scheduleReports.map((item, index) => (
                                                <tr key={index} onClick={() => handleReportClick(item._id)}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.schedule_report_name}</td>
                                                    <td>{item.frequency}</td>
                                                    <td>{item.alert_time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    }
                </Card>
            )}
            {viewType === 'Add' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Add New Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleAddSubmit} >
                        <CardBody>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label>Reports</Label>
                                        <select name="report" id="report" className="form-control digits" required
                                            onChange={(e) => setReportId(e.target.value)}>
                                            <option value="">Select Report</option>
                                            {reports.map(report => (
                                                <option key={report._id} value={report._id}>{report.report_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Notification Name</Label>
                                        <input className="form-control" type="text" name="notificationName" placeholder="Notification Name"
                                            onChange={(e) => setScheduleReportName(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Recipients</Label>
                                        <input className="form-control" type="text" name="mailingList" placeholder="Recipients"
                                            onChange={(e) => setRecipients(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Frequency </Label>
                                        <select className="form-control form-control-primary btn-square mb-3" name="frequency" type="select"
                                            onChange={(e) => setFrequency(e.target.value)}>
                                            <option value="">Select Frequency</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup >
                                        <Label>Alert Time</Label>
                                        <Input className="form-control digits" type="time" defaultValue="09:00:00"
                                            onChange={(e) => setAlertTime(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Save Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
            {viewType === 'Edit' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Edit Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleEditSubmit}>
                        <CardBody>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label>Reports</Label>
                                        <select name="report" id="report" className="form-control digits" required
                                            onChange={(e) => setReportId(e.target.value)}
                                            value={reportId}>
                                            <option value="">Select Report</option>
                                            {reports.map(report => (
                                                <option key={report._id} value={report._id}>{report.report_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Report Name</Label>
                                        <Input
                                            type="text"
                                            name="report_name"
                                            className="form-control"
                                            value={scheduleReportName}
                                            onChange={(e) => setScheduleReportName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Recipients</Label>
                                        <input className="form-control" type="text" name="mailingList" placeholder="Recipients" value={recipients}
                                            onChange={(e) => setRecipients(e.target.value)} required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Frequency </Label>
                                        <select className="form-control form-control-primary btn-square mb-3" name="frequency" type="select"
                                            onChange={(e) => setFrequency(e.target.value)}
                                            value={frequency}>
                                            <option value="">Select Frequency</option>
                                            <option value="Daily">Daily</option>
                                            <option value="Weekly">Weekly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col xl='6'>
                                    <FormGroup >
                                        <Label>Alert Time</Label>
                                        <Input className="form-control digits" type="time" value={alertTime}
                                            onChange={(e) => setAlertTime(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label className="d-block" for="chk-ani2"> Disable </Label>
                                        <Input
                                            className="checkbox_animated"
                                            id="chkDisabale"
                                            type="checkbox"
                                            checked={disable}
                                            onChange={(e) => setDisable(e.target.checked)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Update Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
        </Fragment>
    )
}

export default ScheduleReportView