import React from "react";
import { Suspense, useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import axios from 'axios';


// setup fake backend


const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const userType = localStorage.getItem("usertype");
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const [sessionExpired, setSessionExpired] = useState(false);
  const sessionExpiredRef = useRef(false);

  useEffect(() => {
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 403) {
          if (!sessionExpiredRef.current) {
            sessionExpiredRef.current = true;
            setSessionExpired(true);
            localStorage.removeItem('sessiontoken');
            localStorage.removeItem("profileURL");
            localStorage.removeItem("token");
            localStorage.removeItem("auth0_profile");
            localStorage.removeItem("loginName");
            localStorage.removeItem("loginId");
            localStorage.setItem("authenticated", false);
            localStorage.setItem("login", false);
            localStorage.removeItem("usertype");
            localStorage.removeItem("groupId");
            alert('Session has ended. Please log in again.');
            window.location.href = `${process.env.PUBLIC_URL}/login`;
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptor on unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [sessionExpired]);



  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login || authenticated ? (
              <>
                {userType === "admin" && (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layout}`} />} />
                  </>
                )}
                {userType === "sadmin" && (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/controlpanel/dashboard/default/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/controlpanel/dashboard/default/${layout}`} />} />
                  </>
                )}
                {userType === "tenant" && (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/tenant/home/${layout}`} />} />
                  </>
                )}
                {userType === "Backend User" && (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/admin/home/${layout}`} />} />
                  </>
                )}
                {userType !== "admin" && userType !== "sadmin" && userType !== "tenant" && userType !== "Backend User" && (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layout}`} />} />
                  </>
                )}
              </>
            ) : (
              ""
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
