import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import DataTable from 'react-data-table-component';
import config from '../../../../config';
import axios from 'axios';

const YearOnYearTable = ({ year, propertyMallCode, className }) => {
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const currentYear = new Date().getFullYear();
    const selectedYear = year === '' ? currentYear : year;
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState([]);

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    const [tabledata, setTableData] = useState([]);

    useEffect(() => {
        if (year !== '' || propertyMallCode !== '') {
            setApiUrl('dashboardfiltered/salescomparisonyoytable')
            const data1 = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode
            }
            setApiData(data1)
        }
        else {
            setApiUrl('dashboard/salescomparisonyoytable')
            const data2 = {
                concessionaire_id,
                property_id
            }
            setApiData(data2)
        }
    }, [year, propertyMallCode]);

    useEffect(() => {
        if (apiUrl !== '') {
            axios
                .post(`${config.hostname}/${apiUrl}`, apiData, configheader)
                .then((response) => {
                    setTableData(response.data)
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, apiData]);

    const tableColumns = [
        {
            name: 'Year',
            selector: row => row.year,
            sortable: false,
            center: false,
            width: '80px'
        },
        {
            name: 'Sales',
            selector: row => row.sales,
            sortable: false,
            center: true,
            width: '100px'
        },
        {
            name: 'YOY',
            selector: row => row.yoy,
            sortable: false,
            center: true,
            width: '100px'
        },
        {
            name: 'YTD',
            selector: row => row.ytd,
            sortable: false,
            center: true,
            width: '100px'
        },
        {
            name: '% Change',
            selector: row => row.changeytd,
            sortable: false,
            center: true,
            width: '120px'
        },
        {
            name: 'MTD',
            selector: row => row.mtd,
            sortable: false,
            center: true,
            width: '100px'
        },
        {
            name: '% Change',
            selector: row => row.changemtd,
            sortable: false,
            center: true,
            width: '120px'
        }
    ];
    const data = [
        {
            id: 1,
            year: selectedYear - 1,
            sales: tabledata.previousYearSale,
            yoy: '_',
            ytd: tabledata.ytdPreviousYear,
            changeytd: '_',
            mtd: tabledata.mtdPreviousYear,
            changemtd: '_',

        },
        {
            id: 1,
            year: selectedYear,
            sales: tabledata.currentYearSale,
            yoy: <span className={`f-w-700 font-${tabledata.yoyCurrentYear < 0 ? 'danger' : 'success'}`}>
                <i className={`icofont icofont-arrow-${tabledata.yoyCurrentYear < 0 ? 'down' : 'up'}`}></i> {tabledata.yoyCurrentYear}%
            </span>,
            ytd: tabledata.ytdCurrentYear,
            changeytd: <span className={`f-w-700 font-${tabledata.growthYTD < 0 ? 'danger' : 'success'}`}>
                <i className={`icofont icofont-arrow-${tabledata.growthYTD < 0 ? 'down' : 'up'}`}></i> {tabledata.growthYTD}%
            </span>,
            mtd: tabledata.mtdCurrentYear,
            changemtd: <span className={`f-w-700 font-${tabledata.growthMTD < 0 ? 'danger' : 'success'}`}>
                <i className={`icofont icofont-arrow-${tabledata.growthMTD < 0 ? 'down' : 'up'}`}></i> {tabledata.growthMTD}%
            </span>

        },
        {
            id: 1,
            year: '',
            sales: '',
            yoy: '',
            ytd: '',
            changeytd: '',
            mtd: '',
            changemtd: '',

        },
    ]

    return (
        <Fragment>
            <Col sm='12' xl='8' className={`${className}`} >
                <Card>
                    <HeaderCard title={<div><div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{'Sales Analysis '}</div>
                        <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>Year on year</div>
                    </div>
                        <div style={{ fontSize: '0.6em', color: '#777' }}>Values in millions</div></div>} />
                    <CardBody>
                        <DataTable
                            data={data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default YearOnYearTable