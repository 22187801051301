import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import SadminLogView from './SadminLogView';

const SadminLog = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Settings" title="Log" mainTitle="Log" />
            <Container fluid={true}>
                <SadminLogView />
            </Container>
        </Fragment>
    )
}

export default SadminLog