import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table } from 'reactstrap';
import axios from 'axios';
import { Btn, H6, } from '../../../AbstractElements';
import config from '../../../config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ManageDynamicReports = () => {

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const login_id = localStorage.getItem("loginId");
    const currentYear = new Date().getFullYear();
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [group_name, setGroupName] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [reports, setReports] = useState([]);
    const [reportId, setReportId] = useState('');
    const [reportFilters, setReportFilters] = useState([]);
    const [dynamicReports, setDynamicReports] = useState([]);
    const [viewType, setViewType] = useState('');
    const [report_name, setReportName] = useState('');
    const [filterValues, setFilterValues] = useState({});
    const [selectedReport, setSelectedReport] = useState(null);
    const [selectedReportFilters, setSelectedReportFilters] = useState([]);
    const [dynamicReportName, setDynamicReportName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${config.hostname}/dynamicreports/getreports`, configheader)
            .then((response) => {
                setReports(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const fetchDynamicReports = async () => {
        try {
            const response = await axios.post(`${config.hostname}/dynamicreports/getalldynamicreport`, { concessionaire_id }, configheader);
            setDynamicReports(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDynamicReports();
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);



    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);



    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const handleAddClick = (e) => {
        setViewType('Add')
    }

    const handleYearChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleStartMonthChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleEndMonthChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleStartDateChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleEndDateChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleSubcategoryChange = (e) => {
        setSubcategoryId(e.target.value)

        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleTenantChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value)
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };

    const handleFloorChange = (e) => {
        setFilterValues({ ...filterValues, [e.target.name]: e.target.value });
    };



    const handleReportClick = async (report_id, dynamic_report_id) => {
        try {
            const data = {
                report_id,
                dynamic_report_id
            }
            const response = await axios.post(`${config.hostname}/dynamicreports/getdynamicreport`, data, configheader);
            setSelectedReport(response.data.report);
            setDynamicReportName(response.data.report.dynamic_report_name);
            setSelectedReportFilters(response.data.filters);

            const initialFilterValues = {};
            response.data.filters.forEach(filter => {
                initialFilterValues[filter.filter_id] = filter.filter_value;
            });

            setFilterValues(initialFilterValues);
            setViewType('Edit');
        } catch (error) {
            console.error(error);
            toast.error('Error fetching report details');
        }
    };

    const handleReportChange = (e) => {
        const report_id = e.target.value;
        setReportId(e.target.value)
        axios
            .post(`${config.hostname}/dynamicreports/getfiltersbyreport`, { report_id }, configheader)
            .then((response) => {
                setReportFilters(response.data);
            })
            .catch((error) => {
                console.log(error);
                setReportFilters([]);
            });
    };

    const renderAddInput = (filterName, filterId) => {
        switch (filterName) {
            case "Year":
                return (
                    <select name={filterId} id="year" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleYearChange}>
                        <option value="">Select {filterName}</option>
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>{currentYear - 1}</option>
                        <option value={currentYear - 2}>{currentYear - 2}</option>
                    </select>
                );
            case "Start Date":
                return <Input type="date" name={filterId} value={filterValues[filterId] || ''} onChange={handleStartDateChange} />;
            case "End Date":
                return <Input type="date" name={filterId} value={filterValues[filterId] || ''} onChange={handleEndDateChange} />;
            case "Start Month":
                return (
                    <select name={filterId} id="startMonth" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleStartMonthChange}>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "End Month":
                return (
                    <select name={filterId} id="endMonth" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleEndMonthChange}>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "Property":
                return (
                    <select name={filterId} id="property_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handlePropertyChange}>
                        <option value="">All</option>
                        {properties.map(property => (
                            <option key={property._id} value={property._id}>{property.property_name}</option>
                        ))}
                    </select>
                );
            case "Category":
                return (
                    <select name={filterId} id="category_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleCategoryChange}>
                        <option value="">All</option>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>{category.category_name}</option>
                        ))}
                    </select>
                );
            case "Subcategory":
                return (
                    <select name={filterId} id="subcategory_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleSubcategoryChange}>
                        <option value="">All</option>
                        {subcategories.map(subcategory => (
                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                        ))}
                    </select>
                );
            case "Group":
                return (
                    <select name={filterId} id="group" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleGroupChange}>
                        <option value="">All</option>
                        {groups.map((group, index) => (
                            <option key={index} value={group}>{group}</option>
                        ))}
                    </select>
                );
            case "Tenant":
                return (
                    <select name={filterId} id="tenant_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleTenantChange}>
                        <option value="">All</option>
                        {tenants.map(tenant => (
                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                        ))}
                    </select>
                );
            case "Floor":
                return (
                    <select name={filterId} id="floor" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleFloorChange}>
                        <option value="">All</option>
                        {floors.map((floor, index) => (
                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                        ))}
                    </select>
                );
            default:
                return <Input type="text" name={filterId} value={filterValues[filterId] || ''} onChange={(e) => setFilterValues({ ...filterValues, [filterId]: e.target.value })} />;
        }
    };

    const renderEditInput = (filterName, filterId) => {
        switch (filterName) {
            case "Year":
                return (
                    <select name={filterId} id="year" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleYearChange}>
                        <option value="">Select {filterName}</option>
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>{currentYear - 1}</option>
                        <option value={currentYear - 2}>{currentYear - 2}</option>
                    </select>
                );
            case "Start Date":
                return <Input type="date" name={filterId} value={filterValues[filterId] || ''} onChange={handleStartDateChange} />;
            case "End Date":
                return <Input type="date" name={filterId} value={filterValues[filterId] || ''} onChange={handleEndDateChange} />;
            case "Start Month":
                return (
                    <select name={filterId} id="startMonth" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleStartMonthChange}>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "End Month":
                return (
                    <select name={filterId} id="endMonth" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleEndMonthChange}>
                        <option value="">Select {filterName}</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                );
            case "Property":
                return (
                    <select name={filterId} id="property_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handlePropertyChange}>
                        <option value="">All</option>
                        {properties.map(property => (
                            <option key={property._id} value={property._id}>{property.property_name}</option>
                        ))}
                    </select>
                );
            case "Category":
                return (
                    <select name={filterId} id="category_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleCategoryChange}>
                        <option value="">All</option>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>{category.category_name}</option>
                        ))}
                    </select>
                );
            case "Subcategory":
                return (
                    <select name={filterId} id="subcategory_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleSubcategoryChange}>
                        <option value="">All</option>
                        {subcategories.map(subcategory => (
                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                        ))}
                    </select>
                );
            case "Group":
                return (
                    <select name={filterId} id="group" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleGroupChange}>
                        <option value="">All</option>
                        {groups.map((group, index) => (
                            <option key={index} value={group}>{group}</option>
                        ))}
                    </select>
                );
            case "Tenant":
                return (
                    <select name={filterId} id="tenant_id" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleTenantChange}>
                        <option value="">All</option>
                        {tenants.map(tenant => (
                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                        ))}
                    </select>
                );
            case "Floor":
                return (
                    <select name={filterId} id="floor_id.floor_name" className="form-control"
                        value={filterValues[filterId] || ''}
                        onChange={handleFloorChange}>
                        <option value="">All</option>
                        {floors.map((floor, index) => (
                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                        ))}
                    </select>
                );
            default:
                return <Input type="text" name={filterId} value={filterValues[filterId] || ''} onChange={(e) => setFilterValues({ ...filterValues, [filterId]: e.target.value })} />;
        }
    };



    const handleAddSubmit = (e) => {
        e.preventDefault();
        handleAddNewReport();
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        handleUpdateReport();
    };

    const handleAddNewReport = async () => {
        const data = {
            concessionaire_id,
            login_id,
            report_id: reportId,
            dynamic_report_name: dynamicReportName,
            reportFilters: reportFilters.map(filter => ({
                _id: filter._id,
                filter_value: filterValues[filter._id] || ''
            })).filter(filter => filter.filter_value !== '') // Only include selected filters
        };

        axios
            .post(`${config.hostname}/dynamicreports/savenewreport`, data, configheader)
            .then((response) => {
                toast.success('Report Added Successfully');
                fetchDynamicReports()


                setViewType('');
                setSelectedReport(null);
                setDynamicReportName('');
                setFilterValues({});
            })
            .catch((error) => {
                toast.error('Error Adding Report');
                console.log(error);
            });
    };

    const handleUpdateReport = async (e) => {

        const validFilters = selectedReportFilters
            .filter(filter => filterValues[filter.filter_id])
            .map(filter => ({
                filter_id: filter.filter_id,
                filter_value: filterValues[filter.filter_id],
                filter_name: filter.filter_name // Include filter name if needed
            }));

        const data = {
            report_id: selectedReport.report_id,
            dynamic_report_id: selectedReport.dynamic_report_id,
            dynamic_report_name: dynamicReportName,
            reportFilters: validFilters,
            concessionaire_id,
            login_id
        };

        axios
            .post(`${config.hostname}/dynamicreports/updatedynamicreport`, data, configheader)
            .then(() => {
                toast.success('Report Updated Successfully');
                fetchDynamicReports()
                setViewType(''); // Optionally reset the view
            })
            .catch((error) => {
                toast.error('Error Updating Report');
                console.log(error);
            });
    };

    const handleRunReport = (report_id, dynamic_report_id) => {
        navigate(`${process.env.PUBLIC_URL}/admin/runreport/${report_id}?dynamic_report_id=${dynamic_report_id}`);
    };

    return (
        <Fragment>
            {viewType === '' && (
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Reports</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { handleAddClick() } }}  >Add New</Btn>
                        </div>
                    </CardHeader>
                    {dynamicReports.length > 0 &&
                        <CardBody>
                            <div className='table-responsive product-table'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">No.</th>
                                            <th scope="col">Report Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dynamicReports.map((item, index) => (
                                            <tr key={index} onClick={() => handleReportClick(item.report_id, item.dynamic_report_id)}>
                                                <td>{index + 1}</td>
                                                <td>{item.dynamic_report_name}</td>
                                                <td>  <Button color="primary" onClick={() => handleRunReport(item.report_id, item.dynamic_report_id)}>Run</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    }
                </Card>
            )}

            {viewType === 'Add' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Add New Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleAddSubmit} >
                        <CardBody>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Reports</Label>
                                        <select name="report" id="report" className="form-control digits" required
                                            onChange={handleReportChange}>
                                            <option value="">Select Report</option>
                                            {reports.map(report => (
                                                <option key={report._id} value={report._id}>{report.report_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>

                            </Row>
                            {reportFilters.length > 0 && (
                                <Fragment>
                                    <Row>
                                        {reportFilters.map((filter, index) => (
                                            <Col md="6" key={filter._id}>
                                                <FormGroup>
                                                    <Label for={filter.filter_name}>{filter.filter_name}</Label>
                                                    {renderAddInput(filter.filter_name, filter._id)}
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>Save New report As</Label>
                                                <input className="form-control" type="text" name="newreport" placeholder="Report Name"
                                                    onChange={(e) => setDynamicReportName(e.target.value)} required
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <div className='text-end'>
                                                <Button type='submit' color="primary">Save Report</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}

                        </CardBody>
                    </Form>
                </Card>
            }
            {viewType === 'Edit' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Edit Report</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleEditSubmit}>
                        <CardBody>
                            <Row>
                                <Col xl='6'>
                                    <FormGroup>
                                        <Label>Report Name</Label>
                                        <Input
                                            type="text"
                                            name="report_name"
                                            className="form-control"
                                            value={dynamicReportName}
                                            //value={report_name}
                                            onChange={(e) => setDynamicReportName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {selectedReportFilters.length > 0 && (
                                <Fragment>
                                    <Row>
                                        {selectedReportFilters.map((filter, index) => (
                                            <Col md="6" key={filter.filter_id}>
                                                <FormGroup>
                                                    <Label for={filter.filter_name}>{filter.filter_name}</Label>
                                                    {renderEditInput(filter.filter_name, filter.filter_id)}
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Button type='submit' color="primary">Update Report</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </CardBody>
                    </Form>
                </Card>
            }

        </Fragment>
    );
};

export default ManageDynamicReports;
