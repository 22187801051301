import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, P } from '../../../AbstractElements';
import APIKeyView from './APIKeyView';

const ViewAPIKey = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="APIKey" title="API Key View" mainTitle="API Key View" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <APIKeyView />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ViewAPIKey