import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';

const ConcessionaireDetail = () => {

    if (localStorage.getItem("usertype") !== "sadmin") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [concessionaires, setConcessionaire] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [concessionaire_license_start_date, setLicenceStartDate] = useState('');
    const [concessionaire_license_end_date, setLisenseEndDate] = useState('');
    const [concessionaire_created_Date, setConcessionaireCreatedDate] = useState('');
    const [createdBy, setCreatedBy] = useState('');


    useEffect(() => {
        axios.get(`${config.hostname}/concessionaire/${id}`, configheader)
            .then((response) => {
                setConcessionaire(response.data);
                const licensestartdate = new Date(response.data.concessionaire_license_start_date).toISOString().split('T')[0];
                setLicenceStartDate(licensestartdate);
                const licenseenddate = new Date(response.data.concessionaire_license_end_date).toISOString().split('T')[0];
                setLisenseEndDate(licenseenddate);
                const createddate = new Date(response.data.concessionaire_created_Date).toISOString().split('T')[0];
                setConcessionaireCreatedDate(createddate);
                setCreatedBy(response.data.concessionaire_created_by.userFirstName);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const handleEditClick = () => {
        navigate(`${process.env.PUBLIC_URL}/controlpanel/concessionaire/editconcessionaire/${id}`);
    }

    return (
        <Row>
            <Col xl='4'>
                <Fragment>
                    <Card>
                        <CardHeader>
                            <H4 attrH4={{ className: 'card-title mb-0' }}>{concessionaires.concessionaire_name}</H4>
                            <div className='card-options'>
                                <a className='card-options-collapse' href='#javascript'>
                                    <i className='fe fe-chevron-up'></i>
                                </a>
                                <a className='card-options-remove' href='#javascript'>
                                    <i className='fe fe-x'></i>
                                </a>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row className='mb-2'>
                                    <div className='profile-title'>
                                        <div className='media'>
                                            <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/user/7.jpg')}` }} />
                                            <div className='media-body'>

                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <FormGroup className='mb-3'>
                                    <H6 attrH6={{ className: 'form-label' }}>Address</H6>
                                    <Input type='textarea' className='form-control' rows='5' disabled defaultValue={concessionaires.concessionaire_address} />
                                </FormGroup>

                            </Form>
                        </CardBody>
                    </Card>
                </Fragment>
            </Col>
            <Col xl='8'>
                <Fragment>
                    <Form className="card" >
                        <CardHeader>
                            <H4 attrH4={{ className: "card-title mb-0" }}>Concessionaire Details</H4>
                            {/* <Btn attrBtn={{ color: "primary", type: "submit", marginLeft: '10px', onClick: () => handleEditClick() }}  >Edit</Btn> */}
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Email</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaires.concessionaire_email} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup> <Label className="form-label">Phone Number</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaires.concessionaire_phone} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Username</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaires.concessionaire_username} />
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">License Start Date</Label>
                                        <input className="form-control" type="text" disabled="true" defaultValue={concessionaire_license_start_date} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup> <Label className="form-label"> License End Date</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_license_end_date} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Crated Date</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_created_Date} />
                                    </FormGroup>
                                </Col>
                                <Col sm="6" md="6">
                                    <FormGroup> <Label className="form-label">Created By</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={createdBy} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Active</Label>
                                        <Input className="form-control" type="text" disabled="true" value={concessionaires.concessionaire_active ? 'Yes' : 'No'} />
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Btn attrBtn={{ color: "primary", type: "submit", marginLeft: '10px', onClick: () => handleEditClick() }}  >Edit</Btn>
                                    </FormGroup>
                                </Col>

                            </Row>
                        </CardBody>
                        {/* <CardFooter className="text-end">
                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{UpdateProfile}</Btn>
                        </CardFooter> */}
                    </Form>
                </Fragment>
            </Col>
        </Row>
    )
}

export default ConcessionaireDetail