import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import ManageSadminUsers from './ManageSadminUsers';

const SadminUsers = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Settings" title="Users" mainTitle="Users" />
            <Container fluid={true}>
                <ManageSadminUsers />
            </Container>
        </Fragment>
    )
}

export default SadminUsers