import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table } from 'reactstrap';
import axios from 'axios';
import { Btn, H6, } from '../../../../AbstractElements';
import config from '../../../../config';
import { toast } from 'react-toastify';


const ManageProfile = () => {

    if (localStorage.getItem("manageUserProfile") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const [userProfiles, setUserProfiles] = useState([]);
    const [profileId, setProfileId] = useState([]);
    const [viewType, setViewType] = useState('');
    const [profileName, setProfileName] = useState('');
    const [accessDashboard, setAccessDashboard] = useState(0);
    const [accessAnalytics, setAccessAnalytics] = useState(0);
    const [accessReports, setAccessReports] = useState(0);
    const [accessDataGrid, setAccessDataGrid] = useState(0);
    const [accessGeneralSettings, setAccessGeneralSettings] = useState(0);
    const [manageGeneralSettings, setManageGeneralSettings] = useState(0);
    const [manageUsers, setManageUsers] = useState(0);
    const [manageUserProfiles, setMnanageUserProfiles] = useState(0);
    const [manageConfig, setMnanageConfig] = useState(0);
    const [accessTenants, setAccessTenants] = useState(0);
    const [manageTenants, setManageTenants] = useState(0);
    const [accessLogs, setAccessLogs] = useState(0);
    const [manageScheduleReports, setMananageScheduleReports] = useState(0);
    const [manualPortalAccess, setManualPortalAccess] = useState(0);

    useEffect(() => {
        axios
            .post(`${config.hostname}/userpermissions/getpermissionbyconcessionaire`, { concessionaire_id }, configheader)
            .then((response) => {
                setUserProfiles(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    const handleProfileClick = (id) => {
        setViewType('Edit')
        setProfileId(id);
        axios
            .post(`${config.hostname}/userpermissions/getpermissionbyid`, { id }, configheader)
            .then((response) => {
                setProfileName(response.data.user_type_id.user_role);
                setAccessDashboard(response.data.access_dashboard);
                setAccessAnalytics(response.data.access_analytics);
                setAccessReports(response.data.access_report);
                setAccessDataGrid(response.data.access_data_grid);
                setAccessGeneralSettings(response.data.acess_general_settings);
                setManageGeneralSettings(response.data.manage_general_settings);
                setManageUsers(response.data.manage_users);
                setMnanageUserProfiles(response.data.manage_user_profile);
                setMnanageConfig(response.data.manage_configurations);
                setAccessTenants(response.data.access_tenants);
                setManageTenants(response.data.manage_tenants);
                setAccessLogs(response.data.access_logs)
                setMananageScheduleReports(response.data.manage_schedule_reports)
                setManualPortalAccess(response.data.manual_portal_access);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAddClick = (row) => {
        setViewType('Add')
    }

    const handleCheckBoxToggle = (type, isChecked) => {
        switch (type) {
            case 'dashboard':
                setAccessDashboard(isChecked ? 1 : 0);
                break;
            case 'analytics':
                setAccessAnalytics(isChecked ? 1 : 0);
                break;
            case 'reports':
                setAccessReports(isChecked ? 1 : 0);
                break;
            case 'datagrid':
                setAccessDataGrid(isChecked ? 1 : 0);
                break;
            case 'accessGeneralSettings':
                setAccessGeneralSettings(isChecked ? 1 : 0);
                break;
            case 'manageGeneralSettings': //
                setManageGeneralSettings(isChecked ? 1 : 0);
                break;
            case 'manageUsers':
                setManageUsers(isChecked ? 1 : 0);
                break;
            case 'manageUserProfiles':
                setMnanageUserProfiles(isChecked ? 1 : 0);
                break;
            case 'manageConfigurations':
                setMnanageConfig(isChecked ? 1 : 0);
                break;
            case 'accessTenants':
                setAccessTenants(isChecked ? 1 : 0);
                break;
            case 'manageTenants':
                setManageTenants(isChecked ? 1 : 0);
                break;
            case 'accessLogs':
                setAccessLogs(isChecked ? 1 : 0);
                break;
            case 'manageScheduleReports':
                setMananageScheduleReports(isChecked ? 1 : 0);
                break;
            case 'manualPortalAccess':
                setManualPortalAccess(isChecked ? 1 : 0);
                break;
            default:
                break;
        }
    };

    const handleAddSubmit = (e) => {
        e.preventDefault();
        handleAddNewProfile();
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        handleUpdateProfile();
    };

    const handleAddNewProfile = async () => {

        axios
            .post(`${config.hostname}/userpermissions/checkprofilename`, { concessionaire_id, user_role: profileName }, configheader)
            .then((response) => {
                const isProfileNameExist = response.data.exists
                if (isProfileNameExist === 0) {
                    const data = {
                        user_role: profileName,
                        concessionaire_id: concessionaire_id,
                        access_dashboard: accessDashboard,
                        access_analytics: accessAnalytics,
                        access_report: accessReports,
                        access_data_grid: accessDataGrid,
                        acess_general_settings: accessGeneralSettings,
                        manage_general_settings: manageGeneralSettings,
                        manage_configurations: manageConfig,
                        access_tenants: accessTenants,
                        manage_tenants: manageTenants,
                        manage_users: manageUsers,
                        manage_user_profile: manageUserProfiles,
                        access_logs: accessLogs,
                        manage_schedule_reports: manageScheduleReports,
                        manual_portal_access: manualPortalAccess,
                        login_id
                    };
                    axios
                        .post(`${config.hostname}/userpermissions/createuserpermission`, data, configheader)
                        .then((response) => {
                            toast.success("Successfully Added");
                            setViewType('')
                            setProfileName('');
                            setAccessDashboard(0);
                            setAccessAnalytics(0);
                            setAccessReports(0);
                            setAccessDataGrid(0);
                            setAccessGeneralSettings(0);
                            //setAdminSettings(0);
                            setManageUsers(0);
                            setMnanageUserProfiles(0);
                            setMnanageConfig(0);
                            setAccessTenants(0);
                            setManageTenants(0);
                            setAccessLogs(0);
                            setManualPortalAccess(0);

                            setUserProfiles((prevUserProfiles) => [
                                ...prevUserProfiles,
                                {
                                    ...response.data,
                                    user_type_id: { user_role: profileName },
                                }
                            ]);
                        })
                        .catch((error) => {
                            toast.error('Error')
                            console.log(error);

                        });
                }
                else {
                    toast.warning('Profile Name Already Exists.')
                }
            })
            .catch((error) => {
                console.log(error);
            });


    };

    const handleUpdateProfile = async () => {
        const data = {
            user_role: profileName,
            concessionaire_id: concessionaire_id,
            access_dashboard: accessDashboard,
            access_analytics: accessAnalytics,
            access_report: accessReports,
            access_data_grid: accessDataGrid,
            acess_general_settings: accessGeneralSettings,
            manage_general_settings: manageGeneralSettings,
            manage_configurations: manageConfig,
            access_tenants: accessTenants,
            manage_tenants: manageTenants,
            manage_users: manageUsers,
            manage_user_profile: manageUserProfiles,
            access_logs: accessLogs,
            manage_schedule_reports: manageScheduleReports,
            manual_portal_access: manualPortalAccess,
            login_id
        };
        axios
            .post(`${config.hostname}/userpermissions/updateuserpermission/${profileId}`, data, configheader)
            .then((response) => {
                toast.success("Successfully Updated");
                setViewType('')
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            {viewType === '' && (
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Profiles</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New</Btn>
                        </div>
                    </CardHeader>
                    {userProfiles.length > 0 &&
                        <CardBody>
                            <div className='table-responsive product-table'>
                                {
                                    <Table>
                                        <thead >
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col" >Profile Name</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {userProfiles.map((item, index) => (
                                                <tr key={index} onClick={() => handleProfileClick(item._id)}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user_type_id ? item.user_type_id.user_role : ''}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    }
                </Card>
            )}
            {viewType === 'Add' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Add New Profile</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleAddSubmit} >
                        <CardBody>
                            <Fragment>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Profile Name</Label>
                                            <input className="form-control" type="text" name="userprofile" placeholder="Profile Name"
                                                onChange={(e) => setProfileName(e.target.value)} required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Row>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>Appearance</Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkDashboard"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("dashboard", e.target.checked)}
                                                    />{"Dashboard"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAnalytics"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("analytics", e.target.checked)}
                                                    />{"Analytics"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkReports"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("reports", e.target.checked)}
                                                    />{"Reports"}
                                                </Label>

                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkDataGrid"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("datagrid", e.target.checked)}
                                                    />{"Data Grid"}
                                                </Label>

                                                {/* <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAdminSettings"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("adminSettings", e.target.checked)}
                                                    />{"Admin Settings"}
                                                </Label> */}
                                            </FormGroup>
                                        </Col>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>General Settings</Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkGeneralSettings"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("accessGeneralSettings", e.target.checked)}
                                                    />{"Access General Settings"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageGeneralSettings"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageGeneralSettings", e.target.checked)}
                                                    />{"Manage General Settings"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessTenant"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("accessTenants", e.target.checked)}
                                                    />{"Access Tenants"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageTenant"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageTenants", e.target.checked)}
                                                    />{"Manage Tenants"}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>Administrative task</Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageUser"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageUsers", e.target.checked)}
                                                    />{"Manage Users"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageUserProf"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageUserProfiles", e.target.checked)}
                                                    />{"Manage User Profiles"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManangeConfig"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageConfigurations", e.target.checked)}
                                                    />{"Manange Configurations"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessLog"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("accessLogs", e.target.checked)}
                                                    />{"Access Logs"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkScheduleReport"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manageScheduleReports", e.target.checked)}
                                                    />{"Manage Schedule Report"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManualPortal"
                                                        type="checkbox"
                                                        onChange={(e) => handleCheckBoxToggle("manualPortalAccess", e.target.checked)}
                                                    />{"Tenant Portal"}
                                                </Label>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </Row>

                            </Fragment>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Add User Profile</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
            {viewType === 'Edit' &&
                <Card>
                    <Form className='theme-form' onSubmit={handleEditSubmit} >
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <H6 attrH4={{ className: "card-title mb-0" }}>Edit New Profile</H6>
                                <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { setViewType('') } }}  >Back</Btn>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Fragment>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Profile Name</Label>
                                            <input className="form-control" type="text" name="userprofile" placeholder="Profile Name"
                                                value={profileName} onChange={(e) => setProfileName(e.target.value)} required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Row>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>Appearance</Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkDashboard"
                                                        type="checkbox"
                                                        checked={accessDashboard === 1}
                                                        onChange={(e) => handleCheckBoxToggle("dashboard", e.target.checked)}
                                                    />{"Dashboard"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAnalytics"
                                                        type="checkbox"
                                                        checked={accessAnalytics === 1}
                                                        onChange={(e) => handleCheckBoxToggle("analytics", e.target.checked)}
                                                    />{"Analytics"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkReports"
                                                        type="checkbox"
                                                        checked={accessReports === 1}
                                                        onChange={(e) => handleCheckBoxToggle("reports", e.target.checked)}
                                                    />{"Reports"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkDataGrid"
                                                        type="checkbox"
                                                        checked={accessDataGrid === 1}
                                                        onChange={(e) => handleCheckBoxToggle("datagrid", e.target.checked)}
                                                    />{"Data Grid"}
                                                </Label>

                                                {/* <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAdminSettings"
                                                        type="checkbox"
                                                        checked={accessAdminSettings === 1}
                                                        onChange={(e) => handleCheckBoxToggle("adminSettings", e.target.checked)}
                                                    />{"Admin Settings"}
                                                </Label> */}
                                            </FormGroup>
                                        </Col>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>General Settings</Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessGeneralSettings"
                                                        type="checkbox"
                                                        checked={accessGeneralSettings === 1}
                                                        onChange={(e) => handleCheckBoxToggle("accessGeneralSettings", e.target.checked)}
                                                    />{"Access General Settings"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageGeneralSettings"
                                                        type="checkbox"
                                                        checked={manageGeneralSettings === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageGeneralSettings", e.target.checked)}
                                                    />{"Manage General Settings"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessUser"
                                                        type="checkbox"
                                                        checked={accessTenants === 1}
                                                        onChange={(e) => handleCheckBoxToggle("accessTenants", e.target.checked)}
                                                    />{"Access Tenants"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessUserProf"
                                                        type="checkbox"
                                                        checked={manageTenants === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageTenants", e.target.checked)}
                                                    />{"ManageTenants"}
                                                </Label>


                                            </FormGroup>
                                        </Col>
                                        <Col xl='4'>
                                            <FormGroup>
                                                <Label style={{ fontWeight: 'bold' }}>Administrative task</Label>
                                                <Label className="d-block" for="chk-ani">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageUser"
                                                        type="checkbox"
                                                        checked={manageUsers === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageUsers", e.target.checked)}
                                                    />{"Manage Users"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani1">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManageUserProf"
                                                        type="checkbox"
                                                        checked={manageUserProfiles === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageUserProfiles", e.target.checked)}
                                                    />{"Manage User Profiles"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManangeConfig"
                                                        type="checkbox"
                                                        checked={manageConfig === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageConfigurations", e.target.checked)}
                                                    />{"Manange Configurations"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkAccessLog"
                                                        type="checkbox"
                                                        checked={accessLogs === 1}
                                                        onChange={(e) => handleCheckBoxToggle("accessLogs", e.target.checked)}
                                                    />{"Access Logs"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkScheduleReport"
                                                        type="checkbox"
                                                        checked={manageScheduleReports === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manageScheduleReports", e.target.checked)}
                                                    />{"Manage Schedule Report"}
                                                </Label>
                                                <Label className="d-block" for="chk-ani2">
                                                    <Input
                                                        className="checkbox_animated"
                                                        id="chkManualPortal"
                                                        type="checkbox"
                                                        checked={manualPortalAccess === 1}
                                                        onChange={(e) => handleCheckBoxToggle("manualPortalAccess", e.target.checked)}
                                                    />{"Tenant Portal"}
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Row>
                            </Fragment>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button color="primary" type='submit'>Update User Profile</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
        </Fragment>
    )
}

export default ManageProfile