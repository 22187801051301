import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, P } from '../../../AbstractElements';
import BackendHomeView from './BackendHomeView';

const BackendUserHome = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Home" title="Home" mainTitle="Home" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <BackendHomeView />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default BackendUserHome