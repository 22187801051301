import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, Home, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import config from "../../../config";
import axios from "axios";
import { toast } from "react-toastify";

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState();
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));
  const accessDashboard = localStorage.getItem("accessDashboard");
  const module = localStorage.getItem("usertype");


  useEffect(() => {
    //setProfile(localStorage.getItem("profileURL") || man);
    setProfile(man);
    setName(localStorage.getItem("loginName") ? localStorage.getItem("loginName") : name);
  }, []);

  const insertToLog = () => {
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
      headers: {
        Authorization: `${token}`
      }
    };
    const created_by = localStorage.getItem("loginId");

    const task = localStorage.getItem("loginName") + " logged out"
    const data = {
      created_by,
      module,
      task
    };
    console.log(data);

    axios
      .post(`${config.hostname}/log`, data, configheader)
      .catch((error) => {
        toast.error('Error')
        console.log(error);
      });
  };

  const changePassword = () => {
    //history(`${process.env.PUBLIC_URL}/account/changepassword`);
    history(`${process.env.PUBLIC_URL}/account/changepassword/${layoutURL}`)
  }

  const Logout = () => {
    insertToLog();
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("loginName");
    localStorage.setItem("authenticated", false);
    localStorage.setItem("login", false);
    localStorage.removeItem("usertype");
    localStorage.removeItem("groupId");
    localStorage.removeItem("sessiontoken");
    localStorage.setItem("showGuideTour", "0");
    removePermissions();
    history(`${process.env.PUBLIC_URL}/login`);
  };
  const removePermissions = () => {
    localStorage.removeItem("accessDashboard")
    localStorage.removeItem("accessAnalytics")
    localStorage.removeItem("accessReports")
    localStorage.removeItem("accessDataGrid")
    localStorage.removeItem("accessGeneralSettings")
    localStorage.removeItem("manageGeneralSettings")
    localStorage.removeItem("accessTenants")
    localStorage.removeItem("manageTenants")
    localStorage.removeItem("manageConfigurations")
    localStorage.removeItem("manageUsers")
    localStorage.removeItem("manageUserProfile")
    localStorage.removeItem("accessLogs")
    localStorage.removeItem("manageScheduleReports")
    localStorage.removeItem("tenantPortalManage")
  }

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: "b-r-10 m-0",
            src: `${authenticated ? auth0_profile.picture : profile}`,
            alt: "",
          }}
        />
        <div className="media-body">
          <span>{authenticated ? auth0_profile.name : name}</span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {/* {Admin} <i className="middle fa fa-angle-down"></i> */}
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        {module === 'Backend User' && (
          <LI
            attrLI={{
              onClick: () => {
                localStorage.removeItem("concessionaireId")
                removePermissions();
                // UserMenuRedirect(`${process.env.PUBLIC_URL}/admin/home/${layoutURL}`)
                window.location.href = `${process.env.PUBLIC_URL}/admin/home/${layoutURL}`
              },
            }}>
            <Home />
            <span>Back To Home</span>
          </LI>
        )}
        {(module !== 'sadmin' && module !== 'tenant' && accessDashboard === "1") && (

          <LI
            attrLI={{
              onClick: () => {
                localStorage.setItem("showGuideTour", "1")
                UserMenuRedirect(`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layoutURL}`)
              },
            }}>
            <FileText />
            <span>Guided Tour</span>
          </LI>
        )}
        <LI attrLI={{ onClick: changePassword }}>
          <span> Reset Password</span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
