import React, { Fragment, useState } from "react";
import BrandInfo from "./BrandInfo";
import UnitInfo from "./UnitInfo";
import TenancyInfo from "./TenancyInfo";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { FormWizardWithIcon } from "../../../../Constant";
import { Breadcrumbs } from "../../../../AbstractElements";
import HeaderCard from "../../../Common/Component/HeaderCard";

const AddNewTenant = () => {

    const manageTenant = localStorage.getItem("manageTenants");
    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if ((manageTenant !== "1" && manageGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [steps, setSteps] = useState(1);
    const [formdata, setFormdata] = useState({});

    return (
        <Fragment>
            <Breadcrumbs parent="Tenant" mainTitle="Add Tenant" subParent="Add Tenant" title="Add Tenant" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={steps === 1 ? 'Brand Information' : steps === 2 ? 'Unit Information' : 'Tenancy Information'} />
                            <CardBody>
                                {steps === 1 && <BrandInfo setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                                {steps === 2 && <UnitInfo setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                                {steps === 3 && <TenancyInfo setSteps={setSteps} setFormdata={setFormdata} formdata={formdata} />}

                                <div className="text-center">
                                    <span className={`step ${steps > 1 ? "finish" : ""} ${steps === 1 ? "active" : ""}`} />
                                    <span className={`step ${steps > 2 ? "finish" : ""} ${steps === 2 ? "active" : ""}`} />
                                    <span className={`step ${steps > 3 ? "finish" : ""} ${steps === 3 ? "active" : ""}`} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default AddNewTenant;
