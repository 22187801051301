import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Card, CardHeader, CardBody, Button, Label } from 'reactstrap';
import { P } from '../../../AbstractElements';
import config from '../../../config';
import { toast } from "react-toastify";
import axios from 'axios';
import { useNavigate } from 'react-router';

const ManageAPIKey = () => {

    const token = localStorage.getItem("sessiontoken");
    const login_id = localStorage.getItem("loginId");
    const id = localStorage.getItem("tenantId");
    const navigate = useNavigate();
    const [api_key, setAPIKey] = useState('');
    const [email, setEmail] = useState('');
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        axios.get(`${config.hostname}/tenant/${id}`, configheader)
            .then((response) => {
                const apikey = response.data.tenant_api_key;
                setAPIKey(apikey);
                setEmail(response.data.tenant_email_id)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const generateRandomAPIKey = (length) => {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomKey = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            randomKey += charset[randomIndex];
        }
        return randomKey;
    }
    const insertAPIKey = async (e) => {
        try {

            e.preventDefault();
            const tenant_api_key = generateRandomAPIKey(21);
            // Check if the API key exists in the database
            const response = await axios.get(`${config.hostname}/tenant/apikey/${tenant_api_key}`, configheader);
            const exists = response.data.exists;
            if (exists) {
                // If the key exists, generate a new one and check again recursively
                const newApiKey = generateRandomAPIKey(21);
                return await insertAPIKey(newApiKey);
            }
            else {
                const data = {
                    api_key: tenant_api_key,
                    email_id: email,
                    login_id
                };
                console.log(data);
                axios
                    .post(`${config.hostname}/tenant/insertapikey`, data, configheader)
                    .then(() => {
                        toast.success("Successfully updated");
                        navigate(`${process.env.PUBLIC_URL}/tenant/apikey/generateapikey/:layout`);
                    })
                    .catch((error) => {
                        toast.error('Error')
                        console.log(error);

                    });
            }
        }
        catch (error) {
            toast.error("Error");
            console.log(error);
        };
    }
    return (
        <Fragment>
            {/* <Breadcrumbs mainTitle="APIs" parent="Pages" title="APIs" /> */}
            <Container fluid={true}>
                <Row>
                    <Card>
                        <CardHeader>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{'Sales API'}</h5>
                                {api_key === 'null' || api_key === '' ? (
                                    <Button onClick={insertAPIKey}>Generate API key</Button>
                                ) : (
                                    <div>
                                        <Row>
                                            <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>{'API KEY'}</Label>
                                        </Row>
                                        <Row>
                                            <Label style={{ fontWeight: 'bold', fontSize: '20px' }}>{api_key}</Label>
                                        </Row>
                                    </div>
                                )}
                            </div>
                        </CardHeader>
                        {/* <CardBody>
                            <P>{'We use API Sales Push Technology to operate with real-time sales data, simply integrated to obtained from tenants’ any sort of POS platforms.'}</P>
                            <p style={{ fontWeight: 'bold' }}>{'EndPoint'}</p>
                            <p>{'http://15.34.32.12/api/salesdata'}</p>
                            <p style={{ fontWeight: 'bold' }}>{'Authentication'}</p>
                            <p>{'apikey'}</p>
                            <p style={{ fontWeight: 'bold' }}>{'Payload Sample'}</p>
                            <p>{'{ "outlet_code": "OLT5476", "mall_code": "ML3251", "transaction_count": 20, "net_sales":3200, "transaction_date": "2022-05-02T00:00:00" }'}</p>
                            <p style={{ fontWeight: 'bold' }}>{'F&B Payload Sample'}</p>
                            <p>{'{ "outlet_code": "OLT5476", "mall_code": "ML3251", "transaction_count": 25, "net_sales":5000, "transaction_date": "2022-05-22T00:00:00", "zomato_sales":1000, "zomato_count":5, "talabat_sales":1000, "talabat_count":5, "careem_sales":1000, "careem_count":5, "noon_sales":1000, "noon_count":5, "dinein_sales":1000, "dinein_count":5 }'}</p>
                        </CardBody> */}
                    </Card>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ManageAPIKey