import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Btn } from '../../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';

const style2 = { width: 60, fontSize: 14, padding: 4 };

const CategoryTable = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");

    if ((manageGeneralSettings !== "1" && accessGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    const category_concessionaire_id = localStorage.getItem("concessionaireId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    const categoryColumns = [

        {
            name: 'Category Name',
            selector: (row) => row.category_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '300px',
        },
    ]

    if (manageGeneralSettings === '1') {
        categoryColumns.push({
            name: 'Action',
            cell: (row) => (
                <div>
                    <span><Btn attrBtn={{ style: style2, color: 'primary', className: 'fa fa-pencil', type: 'button', size: 'sm', onClick: () => handleEditClick(row) }}>Edit </Btn></span>
                </div>
            ),
            sortable: false,
            center: false,
            minWidth: '300px',
            maxWidth: '360px',
        });
    }

    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/category/editcategory/${row._id}`);
    }

    const handleAddClick = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/category/addcategory/default`);
    }

    return (
        <Fragment>
            {manageGeneralSettings === "1" && (
                <div className="text-end mb-3">
                    <Btn attrBtn={{ color: "primary", className: "fa fa-plus", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New Category</Btn>
                </div>
            )}
            <div className='table-responsive product-table'>
                <DataTable pagination columns={categoryColumns} data={categories} highlightOnHover={true} striped={true} responsive={true}>
                </DataTable>
            </div>
        </Fragment>
    );
}

export default CategoryTable