import React, { Fragment } from 'react';
import ConcessionaireEdit from './ConcessionaireEdit';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';

const EditConcessionaire = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle='Edit Concessionaire' parent='ConcessionaireS' title='Edit Concessionaire' />
            <Container fluid={true}>
                <div className='edit-profile'>
                    <Row>
                        <ConcessionaireEdit />
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}

export default EditConcessionaire