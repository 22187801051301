import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, Label, FormGroup, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { toast } from "react-toastify";
import config from '../../../../config';
import SweetAlert from 'sweetalert2';
import { Typeahead } from 'react-bootstrap-typeahead';

const TenancyInfo = ({ setSteps, setFormdata, formdata }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();


    const tenant_concessionaire_id = localStorage.getItem("concessionaireId");
    const tenant_country_id = formdata.tenant_country_id;
    const tenant_property_id = formdata.tenant_property_id
    const tenant_store_name = formdata.tenant_store_name;
    const tenant_brand_name = formdata.tenant_brand_name;
    const tenant_group_name = formdata.tenant_group_name;
    const tenant_email_id = formdata.tenant_email_id;
    const tenant_address = formdata.tenant_address;
    const tenant_unit_code = formdata.tenant_unit_code;
    const tenant_mall_code = formdata.property_mall_code;
    const tenant_floor = formdata.tenant_floor;
    const tenant_location = formdata.tenant_location;
    const tenant_category_id = formdata.tenant_category_id;
    const tenant_subcategory_id = formdata.tenant_subcategory_id;
    const tenant_lease_startdate = formdata.tenant_lease_startdate;
    const tenant_lease_enddate = formdata.tenant_lease_enddate;
    const tenant_created_date = new Date().toISOString().substr(0, 10);
    const tenant_created_by = localStorage.getItem("loginId");
    const tenant_login_id = formdata.tenant_email_id;
    const tenant_carpet_area = formdata.tenant_carpet_area;
    const tenant_chargable_area = formdata.tenant_chargable_area;
    const tenant_base_rent = formdata.tenant_base_rent;
    const tenant_revenue_share = formdata.tenant_revenue_share;
    const tenant_disable = false;
    const tenant_code = formdata.tenant_code;
    const tenant_franchisee = formdata.tenant_franchisee;
    const tenant_store_type = formdata.tenant_store_type;
    const other_store_type = formdata.other_store_type;
    const tenant_current_status = formdata.tenant_current_status;
    const tenant_contact_person = formdata.tenant_contact_person;
    const tenant_contact_number = formdata.tenant_contact_number;
    const tenant_it_contact_person = formdata.tenant_it_contact_person;
    const tenant_it_contact_number = formdata.tenant_it_contact_number;
    const tenant_pos_name = formdata.tenant_pos_name;
    const delivery_partner_id = formdata.deliveryPartnersId;
    const [tenant_concessionaire_code, setConcessionaireCode] = useState([]);
    const [revenueMethods, setRevenueMehods] = useState([]);
    const [charges, setCharges] = useState([]);
    const [charge1, setCharge1] = useState('');
    const [charge2, setCharge2] = useState('');
    const [charge3, setCharge3] = useState('');
    const [charge4, setCharge4] = useState('');
    const [charge5, setCharge5] = useState('');
    const [chargesId, setChargesId] = useState([]);
    const [chargeValues, setChargeValues] = useState({});
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    // const tenant_logo = formdata.tenant_logo;

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios.get(`${config.hostname}/concessionaire/${tenant_concessionaire_id}`, configheader)
            .then((response) => {
                setConcessionaireCode(response.data.concessionaire_code)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairerevenuemethods/getmethods`, { concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                setRevenueMehods(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairecharges/getcharges`, { concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    other_charge_id: item.other_charge_id._id,
                    display_name: item.display_name,
                    charge_name: item.other_charge_id.charge_name
                }));
                setCharges(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    const handleChargeChange = (chargeName, value) => {
        if (chargeName === 'charge1') {
            setCharge1(value)
        }
        else if (chargeName === 'charge2') {
            setCharge2(value)
        }
        else if (chargeName === 'charge3') {
            setCharge3(value)
        }
        else if (chargeName === 'charge4') {
            setCharge4(value)
        }
        else if (chargeName === 'charge5') {
            setCharge5(value)
        }
    };



    const onSubmit = async (data) => {
        if (data) {
            // SweetAlert.fire({
            //     title: 'Proceed with tenant creation?',
            //     cancelButtonText: 'No',
            //     confirmButtonText: 'Yes',
            //     reverseButtons: true,
            //     showCancelButton: true,
            // }).then((result) => {
            const result = await SweetAlert.fire({
                title: 'Proceed with tenant creation?',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                reverseButtons: true,
                showCancelButton: true,
            });
            // if (result.isConfirmed) {
            if (result.isConfirmed) {
                // axios
                //     .post(`${config.hostname}/tenant/checktcode`, { tenant_code, tenant_concessionaire_id }, configheader)
                //     .then((response) => {
                //         if (response.data.message !== "0") {
                //             toast.warning(response.data.message)
                //             return;
                //         }

                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });




                // axios.post(`${config.hostname}/tenant/gettenantsbyemailid`, { email_id: tenant_email_id, concessionaire_id: tenant_concessionaire_id }, configheader)
                //     .then((response) => {
                //         setApiKey(response.data[0].tenant_api_key)
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //     });

                const checkCodeResponse = await axios.post(
                    `${config.hostname}/tenant/checktcode`,
                    { tenant_code, tenant_concessionaire_id },
                    configheader
                );

                // If the response message is not "0", show a warning and exit the function
                if (checkCodeResponse.data.message !== 0) {
                    toast.warning(checkCodeResponse.data.message);
                    return; // This will now stop further execution
                }

                // Proceed to check the tenants by email ID
                const emailResponse = await axios.post(
                    `${config.hostname}/tenant/gettenantsbyemailid`,
                    { email_id: tenant_email_id, concessionaire_id: tenant_concessionaire_id },
                    configheader
                );

                const apiKey = emailResponse.data[0]?.tenant_api_key || '';


                setFormdata((prev) => ({ ...prev, ...data }));
                const data1 = {
                    tenant_concessionaire_id,
                    tenant_country_id,
                    tenant_property_id,
                    tenant_store_name,
                    tenant_brand_name,
                    tenant_group_name,
                    tenant_email_id,
                    tenant_address,
                    tenant_password: 'egoal@123',
                    tenant_unit_code,
                    tenant_concessionaire_code,
                    tenant_mall_code,
                    tenant_floor,
                    tenant_location,
                    tenant_category_id,
                    tenant_subcategory_id,
                    tenant_lease_startdate,
                    tenant_lease_enddate,
                    tenant_created_date,
                    tenant_created_by: login_id,
                    tenant_login_id,
                    tenant_carpet_area,
                    tenant_chargable_area,
                    tenant_base_rent,
                    tenant_revenue_share,
                    tenant_disable,
                    tenant_code,
                    tenant_franchisee,
                    tenant_store_type: tenant_store_type === 'Other' ? other_store_type : tenant_store_type,
                    tenant_current_status,
                    tenant_contact_person,
                    tenant_contact_number,
                    tenant_it_contact_person,
                    tenant_it_contact_number,
                    tenant_pos_name,
                    tenant_charge1: charge1 || 0,
                    tenant_charge2: charge2 || 0,
                    tenant_charge3: charge3 || 0,
                    tenant_charge4: charge4 || 0,
                    tenant_charge5: charge5 || 0,
                    login_id,
                    tenant_api_key: apiKey || '',
                    tenant_status: '',
                    //tenant_logo

                }
                const tenantData = { ...data1, ...data };


                axios
                    .post(`${config.hostname}/tenant`, tenantData, configheader)
                    .then((response) => {
                        toast.success("Successfully Added");
                        const tenant_id = response.data._id;
                        console.log('Tenant_id' + tenant_id)
                        setSteps(1);
                        setFormdata({});

                        if (delivery_partner_id && delivery_partner_id.length > 0) {
                            const deliveryData = {
                                concessionaire_id: tenant_concessionaire_id,
                                tenant_id,
                                delivery_partner_id,
                                login_name,
                                login_id,
                                tenant_store_name
                            }

                            axios
                                .post(`${config.hostname}/tenantdeliverypartner/createdeliverypartner`, deliveryData, configheader)
                                .then(() => {
                                    console.log('Added delivery partners')
                                })
                                .catch((error) => {
                                    toast.error('Error on Adding Delivery Partners')
                                    console.log(error);

                                });
                        }

                    })
                    .catch((error) => {
                        toast.error('Error on Adding Tenant')
                        console.log(error);

                    });


            } else {
                console.log("Tenant creation cancelled");
            }
            // });
        }
    };

    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_lease_startdate">Lease Start Date</Label>
                                    <input className={`form-control ${errors.tenant_lease_startdate && "is-invalid"}`}
                                        id="tenant_lease_startdate" type="date" name="tenant_lease_startdate" defaultValue={formdata.tenant_lease_startdate || ""}
                                        {...register("tenant_lease_startdate", { required: true })} />
                                    <span className="text-danger">{errors.tenant_lease_startdate && "Lease start date is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_lease_enddate">Lease End Date</Label>
                                    <input className={`form-control ${errors.tenant_lease_enddate && "is-invalid"}`}
                                        id="tenant_lease_enddate" type="date" name="tenant_lease_enddate" defaultValue={formdata.tenant_lease_enddate || ""}
                                        {...register("tenant_lease_enddate", { required: true })} />
                                    <span className="text-danger">{errors.tenant_lease_enddate && "Lease end date is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_base_rent">Base Rent</Label>
                                    <input className={`form-control ${errors.tenant_base_rent && "is-invalid"}`}
                                        id="tenant_base_rent" type="number"
                                        step="0.001"  // This allows decimal input
                                        name="tenant_base_rent" defaultValue={formdata.tenant_base_rent || ""}
                                        {...register("tenant_base_rent", {
                                            required: true, validate: {
                                                notZero: value => value !== "0" || "Base Rent cannot be 0"
                                            }
                                        })} />
                                    <span className="text-danger">{errors.tenant_base_rent && "Base rent is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_revenue_share">Revenue Share %</Label>
                                    <input className={`form-control ${errors.tenant_revenue_share && "is-invalid"}`}
                                        id="tenant_revenue_share" type="number"
                                        step="0.001"  // This allows decimal input
                                        name="tenant_revenue_share" defaultValue={formdata.tenant_revenue_share || ""}
                                        {...register("tenant_revenue_share", { required: true })} />
                                    <span className="text-danger">{errors.tenant_revenue_share && "Revenue share is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_revenue_share_method_id">Revenue Share Method</Label>
                                    <select name="tenant_revenue_share_method_id" id="tenant_revenue_share_method_id" className={`form-control ${errors.tenant_revenue_share_method_id && "is-invalid"}`}
                                        {...register("tenant_revenue_share_method_id", { required: true })}
                                        defaultValue={formdata.tenant_revenue_share_method_id} >
                                        <option value="">Select Revenue Share Method</option>
                                        {revenueMethods.map(method => (
                                            <option key={method._id} value={method.revenue_method_id._id}>{method.revenue_method_id.description}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_revenue_share_method_id && "Revenue share method is required"}</span>
                                </FormGroup>
                            </Col>
                            {charges.length > 0 && (
                                <>
                                    {charges.map((charge) => (
                                        <Col md="6" key={charge.other_charge_id}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor={`charge_${charge.display_name}`}>{charge.display_name}</Label>
                                                <input
                                                    className={`form-control ${errors[`charge_${charge.display_name}`] && "is-invalid"}`}
                                                    id={`charge_${charge.display_name}`}
                                                    type="number"
                                                    step="0.001"  // This allows decimal input
                                                    name={`charge_${charge.display_name}`}
                                                    defaultValue={0} // Default value can be set if needed
                                                    {...register(`charge_${charge.display_name}`, {
                                                        required: true,
                                                        onChange: (e) => {
                                                            setValue(`charge_${charge.display_name}`, e.target.value); // Ensure form state is updated
                                                            handleChargeChange(charge.charge_name, e.target.value);
                                                        },
                                                    })}
                                                />
                                                <span className="text-danger">
                                                    {errors[`charge_${charge.display_name}`] && `${charge.display_name} is required`}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </>
                            )}
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_contact_person">Contact Person</Label>
                                    <input className={`form-control ${errors.tenant_contact_person && "is-invalid"}`}
                                        id="tenant_contact_person" type="text" name="tenant_contact_person" defaultValue={formdata.tenant_contact_person || ""}
                                        {...register("tenant_contact_person", { required: true })} placeholder="Contact person" />
                                    <span className="text-danger">{errors.tenant_contact_person && "Contact person is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_contact_number">Contact Number</Label>
                                    <input className={`form-control ${errors.tenant_contact_number && "is-invalid"}`}
                                        id="tenant_contact_number" type="text" name="tenant_contact_number" defaultValue={formdata.tenant_contact_number || ""}
                                        {...register("tenant_contact_number", { required: true })} placeholder="Contact Number" />
                                    <span className="text-danger">{errors.tenant_contact_number && "Contact person is required"}</span>
                                </FormGroup>
                            </Col>
                            {/* </Row>
                        <Row> */}
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_it_contact_person">IT Contact Person</Label>
                                    <input className={`form-control ${errors.tenant_it_contact_person && "is-invalid"}`}
                                        id="tenant_it_contact_person" type="text" name="tenant_it_contact_person" defaultValue={formdata.tenant_it_contact_person || ""}
                                        {...register("tenant_it_contact_person")} placeholder="IT Contact Person" />

                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_it_contact_number">IT Contact Number</Label>
                                    <input className={`form-control ${errors.tenant_it_contact_number && "is-invalid"}`}
                                        id="tenant_it_contact_number" type="text" name="tenant_it_contact_number" defaultValue={formdata.tenant_it_contact_number || ""}
                                        {...register("tenant_it_contact_number")} placeholder="IT Contact Number" />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_pos_name">POS Name</Label>
                                    <input className={`form-control ${errors.tenant_pos_name && "is-invalid"}`}
                                        id="tenant_pos_name" type="text" name="tenant_pos_name" defaultValue={formdata.tenant_pos_name || ""}
                                        {...register("tenant_pos_name", { required: true })} placeholder="POS Name" />
                                    <span className="text-danger">{errors.tenant_pos_name && "POS Name is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button className="secondary me-2" color="primary" onClick={() => setSteps((pre) => pre - 1)}>
                                Previous
                            </Button>
                            <Button className="primary" color="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default TenancyInfo