import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Btn } from '../../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const style2 = { width: 60, fontSize: 14, padding: 4 };

const ConcessionaireTable = ({ itemsPerPage }) => {

    if (localStorage.getItem("usertype") !== "sadmin") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [concessionaires, setConcessionaires] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const navigate = useNavigate();
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    useEffect(() => {
        axios
            .get(`${config.hostname}/concessionaire`, configheader)
            .then((response) => {
                setConcessionaires(response.data);
                setTotalPages(Math.ceil(response.data.length / itemsPerPage));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        const offset = currentPage * itemsPerPage;
        const currentPageData = concessionaires.slice(offset, offset + itemsPerPage);
        setCurrentPageData(currentPageData);
    }, [currentPage, concessionaires, itemsPerPage]);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const [currentPageData, setCurrentPageData] = useState([]);


    const concessionaireColumns = [

        {
            name: 'Concessionaire Name',
            selector: (row) => row.concessionaire_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '300px',
        },
        {
            name: 'Concessionaire Code',
            selector: (row) => row.concessionaire_code,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '200px',
        },
        {
            name: 'Email',
            selector: (row) => row.concessionaire_email,
            sortable: true,
            center: false,
            minWidth: '200px',
            maxWidth: '250px',
        },
        {
            name: 'Phone',
            selector: (row) => row.concessionaire_phone,
            sortable: true,
            center: false,
            minWidth: '120px',
            maxWidth: '150px',
        },

        {
            name: 'Action',
            cell: (row) => (
                <div>
                    <span>
                        <Btn attrBtn={{
                            style: style2, color: 'primary', className: 'fa fa-pencil', type: 'button', size: 'sm',
                            onClick: () => handleEditClick(row)
                        }}>Edit
                        </Btn>
                    </span>
                </div>
            ),
            sortable: false,
            center: false,
            minWidth: '300px',
            maxWidth: '360px',
        },]

    const handleExport = () => {

        const formatDate = (dateString) => {
            if (!dateString) return '';
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };
        const exportData = concessionaires.map(concessionaire => ({
            'Concessionaire Name': concessionaire.concessionaire_name,
            'Concessionaire Code': concessionaire.concessionaire_code,
            'Email': concessionaire.concessionaire_email,
            'Phone Number': concessionaire.concessionaire_phone,
            'Address': concessionaire.concessionaire_address,
            'License Start Date': concessionaire.concessionaire_license_start_date,
            'License End Date': concessionaire.concessionaire_license_end_date,

        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Concessionaires');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'Concessionaires.xlsx');

    }


    const handleAddClick = () => {
        navigate(`${process.env.PUBLIC_URL}/controlpanel/concessionaire/addnewconcessionaire/default`);
    }


    const handleViewClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/controlpanel/concessionaire/viewconcessionairedetail/${row._id}`);

    }
    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/controlpanel/concessionaire/editconcessionaire/${row._id}`);

    }

    return (
        <Fragment>
            <Row className='text-end'>
                <Col xl='11'>
                    <Btn attrBtn={{ color: "primary", className: "fa fa-plus", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New Concessionaire</Btn>
                </Col>
                <Col xl='1'>
                    <Btn
                        attrBtn={{
                            color: 'primary',
                            className: 'ml-2',
                            onClick: () => handleExport('excel'),
                            onMouseEnter: () => handleMouseEnter('Export to Excel'),
                            onMouseLeave: handleMouseLeave
                        }}
                    >
                        <FontAwesomeIcon icon={faFileExcel} size="lg" />
                    </Btn>


                    {tooltip === 'Export to Excel' && (
                        <div style={{
                            position: 'absolute',
                            top: '-40px',   // Adjust top position based on the button's position
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px 10px',
                            borderRadius: '3px',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            zIndex: 1
                        }}>
                            {tooltip}
                        </div>
                    )}
                </Col>
            </Row>
            <div className='table-responsive product-table'>
                <DataTable
                    pagination
                    columns={concessionaireColumns}
                    data={concessionaires}
                    highlightOnHover={true}
                    striped={true}
                    responsive={true}
                    onRowClicked={(row) => handleViewClick(row)}>
                </DataTable>
            </div>

        </Fragment>
    );
}

export default ConcessionaireTable