import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import DeliveryPartnerSales from './DeliveryPartnerSales';

const DeliveryPartnerReport = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Report" title="Sales Report - Delivery partner" mainTitle="Sales Report - Delivery partner" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DeliveryPartnerSales />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default DeliveryPartnerReport