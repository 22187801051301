import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button, Container, CardFooter, Input } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';


const OtherChargesAdd = () => {
    const concessionaire_id = localStorage.getItem('concessionaireId');
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem('sessiontoken');
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const [movedItems, setMovedItems] = useState([]);
    const [otherCharges, setOtherCharges] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);
    const [displayNames, setDisplayNames] = useState({});

    useEffect(() => {
        axios
            .get(`${config.hostname}/othercharges/getothercharges`, configheader)
            .then((response) => {
                setOtherCharges(response.data);
                setAvailableItems(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairecharges/getcharges`, { concessionaire_id }, configheader)
            .then((response) => {
                const charges = response.data.map(charge => ({
                    id: charge.other_charge_id._id,
                    text: charge.display_name
                }));
                setMovedItems(charges);

                const updatedAvailableItems = otherCharges.filter(ai =>
                    !charges.some(pi => pi.id === ai._id)
                );
                setAvailableItems(updatedAvailableItems);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [otherCharges]);

    const handleSelect = (id) => {
        setSelectedItems(prev => prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]);
    };

    const handleDisplayNameChange = (id, value) => {
        setDisplayNames(prev => ({ ...prev, [id]: value }));
    };

    const moveSelectedItems = () => {
        const itemsToMove = availableItems.filter(item => selectedItems.includes(item._id));
        setMovedItems(prev => [...prev, ...itemsToMove.map(item => ({ id: item._id, text: displayNames[item._id] }))]);
        setSelectedItems([]);
        setAvailableItems(prev => prev.filter(item => !selectedItems.includes(item._id)));
        handleSave(itemsToMove.map(item => item._id), itemsToMove.map(item => displayNames[item._id]));
    };

    // const moveBackSelectedItems = () => {
    //     const itemsToMoveBack = movedItems.filter(item => selectedItems.includes(item.id));
    //     setAvailableItems(prev => [...prev, ...itemsToMoveBack.map(item => ({ _id: item.id, display_name: item.text }))]);
    //     setSelectedItems([]);
    //     setMovedItems(prev => prev.filter(item => !selectedItems.includes(item.id)));
    //     handleRemove(itemsToMoveBack.map(item => item.id));
    // };

    const moveBackSelectedItems = () => {
        const itemsToMoveBack = movedItems.filter(item => selectedItems.includes(item.id));
        setAvailableItems(prev => [
            ...prev,
            ...itemsToMoveBack.map(item => {
                const originalItem = otherCharges.find(oc => oc._id === item.id);
                return { ...originalItem, display_name: item.text };
            })
        ]);
        setSelectedItems([]);
        setMovedItems(prev => prev.filter(item => !selectedItems.includes(item.id)));
        handleRemove(itemsToMoveBack.map(item => item.id));
    };


    const handleSave = (itemsToAdd, displayNamesToAdd) => {
        const data = {
            concessionaire_id,
            other_charge_id: itemsToAdd,
            display_name: displayNamesToAdd,
            login_id
        };

        axios
            .post(`${config.hostname}/concessionairecharges/createcharges`, data, configheader)
            .then(() => {
                toast.success('Successfully Added');
            })
            .catch((error) => {
                toast.error('Error');
                console.log(error);
            });
    };

    const handleRemove = (itemsToRemove) => {
        const data = {
            concessionaire_id,
            other_charge_id: itemsToRemove,
            login_id
        };

        axios({
            method: 'delete',
            url: `${config.hostname}/concessionairecharges/removecharges`,
            data: data,
            headers: configheader.headers
        })
            .then(() => {
                toast.success('Successfully Removed');
            })
            .catch((error) => {
                toast.error('Error');
                console.log(error);
            });
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                {availableItems.map(method => (
                                                    <div key={method._id} className="d-flex align-items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(method._id)}
                                                            onChange={() => handleSelect(method._id)}
                                                        />
                                                        <span className="ml-2">{method.charge_name}</span>
                                                        {selectedItems.includes(method._id) && (
                                                            <Input
                                                                type="text"
                                                                className="ml-2"
                                                                placeholder="Display Name"
                                                                value={displayNames[method._id] || ''}
                                                                onChange={(e) => handleDisplayNameChange(method._id, e.target.value)}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveSelectedItems} color="primary">Add Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                {movedItems.map((item) => (
                                                    <div key={item.id} className="d-flex align-items-center mb-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(item.id)}
                                                            onChange={() => handleSelect(item.id)}
                                                        />
                                                        <span className="ml-2">{item.text}</span>
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveBackSelectedItems} color="primary" className="ml-2">Remove Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default OtherChargesAdd;
