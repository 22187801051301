import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../AbstractElements';
import { Container } from 'reactstrap';
import ScheduleReportView from './ScheduleReportView';

const ScheduleReport = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Schedule Reports" title="Schedule Reports" mainTitle="Schedule Reports" />
            <Container fluid={true}>
                <ScheduleReportView />
            </Container>
        </Fragment>
    )
}

export default ScheduleReport
