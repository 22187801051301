import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Row, Col, Form, FormGroup, Card, CardBody, CardHeader, Label, Input, Button, Table } from 'reactstrap';
import axios from 'axios';
import { Btn, H6, } from '../../../../AbstractElements';
import config from '../../../../config';
import { toast } from 'react-toastify';

const ManageSadminUsers = () => {


    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const login_id = localStorage.getItem("loginId");
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('');
    const [userProfileId, setUserProfileId] = useState('');
    const [viewType, setViewType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [concessionaires, setConcessionaires] = useState([]);
    const [selectedConcessionaires, setSelectedConcessionaires] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    useEffect(() => {
        axios
            .post(`${config.hostname}/users/getusertypeid`, { user_type: 'Backend User' }, configheader)
            .then((response) => {
                setUserProfileId(response.data.userTypeId);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (userProfileId !== '') {
            axios
                .post(`${config.hostname}/users/getusersbytype`, { user_type_id: userProfileId }, configheader)
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userProfileId]);

    useEffect(() => {
        axios
            .get(`${config.hostname}/concessionaire`, configheader)
            .then((response) => {
                setConcessionaires(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleProfileChange = (e) => {
        // setUserProfileId(e.target.value)
    }

    const handleConcessionaireChange = (event) => {
        const value = event.target.value;
        if (selectedConcessionaires.includes(value)) {
            setSelectedConcessionaires(selectedConcessionaires.filter(item => item !== value));
        } else {
            setSelectedConcessionaires([...selectedConcessionaires, value]);
        }
    };


    const handleUserClick = (id) => {
        setViewType('Edit')
        setUserId(id);
        axios
            .get(`${config.hostname}/users/${id}`, configheader)
            .then((response) => {
                setFirstName(response.data.userFirstName)
                setLastName(response.data.userLastName)
                setEmail(response.data.username)
                setUserProfileId(response.data.user_type_id)
                setSelectedConcessionaires(response.data.concessionaire_access);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // const handleCheckBoxToggle = (isChecked) => {
    //     setIsDisabled(isChecked ? true : false);
    // };

    const handleAddSubmit = (e) => {
        e.preventDefault();
        handleAddNewProfile();
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        handleUpdateProfile();
    };

    const handleAddNewProfile = async () => {

        if (selectedConcessionaires.length === 0) {
            toast.warning('Select any concessionaire');
            return;
        }

        const data = {
            userFirstName: firstName,
            userLastName: lastName,
            username: email,
            password: 'egoal@1',
            user_type_id: userProfileId,
            concessionaire_access: selectedConcessionaires,
            login_id
        };
        axios
            .post(`${config.hostname}/users`, data, configheader)
            .then((response) => {
                toast.success("Successfully Added");
                clearAll();

                setUsers((prevUsers) => [
                    ...prevUsers,
                    {
                        ...response.data
                    }
                ]);
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    const handleUpdateProfile = async () => {

        if (selectedConcessionaires.length === 0) {
            toast.warning('Select any property');
            return;
        }

        const data = {
            userFirstName: firstName,
            userLastName: lastName,
            username: email,
            user_type_id: userProfileId,
            concessionaire_access: selectedConcessionaires,
            login_id
        };
        axios
            .post(`${config.hostname}/users/updateuser/${userId}`, data, configheader)
            .then((response) => {
                toast.success("Successfully Updated");
                clearAll()
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const clearAll = () => {
        setViewType('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setSelectedConcessionaires([])
    }

    return (
        <Fragment>
            {viewType === '' && (
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Users</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => setViewType('Add') }}  >Add New</Btn>
                        </div>
                    </CardHeader>
                    {users.length > 0 &&
                        <CardBody>
                            <div className='table-responsive product-table'>
                                {
                                    <Table>
                                        <thead >
                                            <tr>
                                                <th scope="col">No.</th>
                                                <th scope="col" >User Name</th>
                                                <th scope="col" >User Profile</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {users.map((item, index) => (
                                                <tr key={index} onClick={() => handleUserClick(item._id)}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.userFirstName}</td>
                                                    <td>{item.user_type_id.user_role}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        </CardBody>
                    }
                </Card>
            )}
            {viewType === 'Add' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Add New User</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { clearAll(); } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleAddSubmit} >
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>First Name</Label>
                                        <input className="form-control" type="text" name="fname" placeholder="First Name"
                                            onChange={(e) => setFirstName(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Last Name</Label>
                                        <input className="form-control" type="text" name="lname" placeholder="Last Name"
                                            onChange={(e) => setLastName(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <input className="form-control" type="text" name="useremail" placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>User Profile</Label>
                                        <select name="userprofile" id="userprofile" className="form-control digits" required
                                            onChange={handleProfileChange}>
                                            {/* <option value="">Select Profile</option> */}
                                            <option value={userProfileId}>Backend User</option>
                                            {/* {userProfiles.map(profile => (
                                                <option key={profile.user_type_id._id} value={profile.user_type_id._id}>{profile.user_type_id.user_role}</option>
                                            ))} */}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label>Concessionaires</label>
                                        <div
                                            onClick={toggleDropdown}
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>Select Concessionaires</div>
                                            <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                        </div>
                                        {dropdownOpen && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    width: '100%',
                                                    backgroundColor: '#fff',
                                                    // border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    marginTop: '4px',
                                                    zIndex: 1,
                                                    // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                }}
                                            >
                                                {concessionaires.map(concessionaire => (
                                                    <label key={concessionaire._id} style={{ display: 'block', padding: '2px', cursor: 'pointer' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={concessionaire._id}
                                                            checked={selectedConcessionaires.includes(concessionaire._id)}
                                                            onChange={handleConcessionaireChange}
                                                            style={{ marginRight: '8px' }}
                                                        />
                                                        {concessionaire.concessionaire_name}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Add User</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
            {viewType === 'Edit' &&
                <Card>
                    <CardHeader>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <H6 attrH4={{ className: "card-title mb-0" }}>Edit User</H6>
                            <Btn attrBtn={{ color: "primary", marginLeft: '10px', onClick: () => { clearAll(); } }}  >Back</Btn>
                        </div>
                    </CardHeader>
                    <Form className='theme-form' onSubmit={handleEditSubmit} >
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>First Name</Label>
                                        <input className="form-control" type="text" name="fname" placeholder="First Name"
                                            value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Last Name</Label>
                                        <input className="form-control" type="text" name="lname" placeholder="Last Name"
                                            value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <input className="form-control" type="text" name="useremail" placeholder="Email"
                                            value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>User Profile</Label>
                                        <select name="userprofile" id="userprofile" className="form-control digits" required
                                            value={userProfileId} onChange={handleProfileChange}>
                                            {/* <option value="">Select Profile</option> */}
                                            <option value={userProfileId}>Backend User</option>
                                            {/* {userProfiles.map(profile => (
                                                <option key={profile.user_type_id._id} value={profile.user_type_id._id}>{profile.user_type_id.user_role}</option>
                                            ))} */}
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl='6'>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <label>Properties</label>
                                        <div
                                            onClick={toggleDropdown}
                                            style={{
                                                border: '1px solid #ccc',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                {selectedConcessionaires.length > 0
                                                    ? concessionaires
                                                        .filter(concessionaire => selectedConcessionaires.includes(concessionaire._id))
                                                        .map(concessionaire => concessionaire.concessionaire_name)
                                                        .join(', ')
                                                    : 'Select concessionaire'}
                                            </div>
                                            <span style={{ marginLeft: 'auto' }}>&#9662;</span>
                                        </div>
                                        {dropdownOpen && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: 0,
                                                    width: '100%',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px',
                                                    marginTop: '4px',
                                                    zIndex: 1,
                                                }}
                                            >
                                                {concessionaires.map(concessionaire => (
                                                    <label key={concessionaire._id} style={{ display: 'block', padding: '2px', cursor: 'pointer' }}>
                                                        <input
                                                            type="checkbox"
                                                            value={concessionaire._id}
                                                            checked={selectedConcessionaires.includes(concessionaire._id)}
                                                            onChange={handleConcessionaireChange}
                                                            style={{ marginRight: '8px' }}
                                                        />
                                                        {concessionaire.concessionaire_name}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                {/* <Col>
                                    <Label className="d-block" for="chk-ani2">
                                        <Input
                                            className="checkbox_animated"
                                            id="chkDisable"
                                            type="checkbox"
                                            onChange={(e) => handleCheckBoxToggle(e.target.checked)}
                                        />{"Disable"}
                                    </Label>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col>
                                    <div className='text-end'>
                                        <Button type='submit' color="primary">Update User</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            }
        </Fragment>
    )
}

export default ManageSadminUsers