import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import PortalManage from './PortalManage';

const TenantPortal = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Admin Settings" title="Tenant Portal" mainTitle="Tenant Portal" />
            <Container fluid={true}>
                <PortalManage />
            </Container>
        </Fragment>
    )
}

export default TenantPortal