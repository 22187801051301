import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';
import { toast } from "react-toastify";

const CountryEdit = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [concessionaires, setConcessionaires] = useState([]);

    const [country_name, setName] = useState('');
    const [country_currency, setCurrency] = useState('');
    const [country_code, setCode] = useState('');
    const [country_concessionaire_id, setConcessionaireId] = useState('');
    const login_id = localStorage.getItem("loginId");

    const [country, setCountry] = useState({});


    const { id } = useParams();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        axios
            .get(`${config.hostname}/concessionaire`, configheader)
            .then((response) => {
                setConcessionaires(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${config.hostname}/country/${id}`, configheader)
            .then((response) => {
                setCountry(response.data);
                setName(response.data.country_name);
                setCode(response.data.country_code);
                setCurrency(response.data.country_currency);
                const concessionaireid = response.data.country_concessionaire_id._id;
                setConcessionaireId(concessionaireid);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            country_name,
            country_code,
            country_currency,
            country_concessionaire_id,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/country/updatecountry/${id}`, data, configheader)
            .then(() => {
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Form className='theme-form' onSubmit={handleSubmit}>
            <Row>
                <Col xl='4'>
                    <Fragment>
                        <Card>
                            <CardHeader>
                                <H4 attrH4={{ className: 'card-title mb-0' }}>{country_name}</H4>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row className='mb-2'>
                                        <div className='profile-title'>
                                            <div className='media'>
                                                <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/user/7.jpg')}` }} />

                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Fragment>
                </Col>
                <Col xl='8'>
                    <Fragment>
                        <Form className="card" >
                            <CardHeader>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Country Details</H4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Country Name</Label>
                                            <Input className="form-control" type="text" value={country_name} placeholder='Country Name' required
                                                onChange={(e) => setName(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Country code</Label>
                                            <Input className="form-control" type="text" value={country_code} placeholder='Country Code' required
                                                onChange={(e) => setCode(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                        <FormGroup> <Label className="form-label">Currency</Label>
                                            <Input className="form-control" type="text" value={country_currency} placeholder='Currency' required
                                                onChange={(e) => setCurrency(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Form>
                    </Fragment>
                </Col>
            </Row>
            <CardFooter className="text-end">
                <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
            </CardFooter>
        </Form>
    )
}

export default CountryEdit