import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import TradingDensityReportView from './TradingDensityReportView';

const TradingDensityReport = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Report" title="Trading Density Report" mainTitle="Trading Density Report" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <TradingDensityReportView />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default TradingDensityReport