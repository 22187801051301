import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../AbstractElements';
import ManageDynamicReports from './ManageDynamicReports';
import { Container } from 'reactstrap';

const DynamicReports = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Dynamic Reports" title="Dynamic Reports" mainTitle="Dynamic Reports" />
            <Container fluid={true}>
                <ManageDynamicReports />
            </Container>
        </Fragment>
    )
}

export default DynamicReports