import React, { Fragment, useState, useEffect, useContext } from "react";
import CustomContext from "../../_helper/Customizer";
import { MenuTenant } from "./MenuTenant";
import { MenuAdmin } from "./MenuAdmin";
import { MenuSadmin } from "./MenuSadmin";
import { MenuCustom } from "./MenuCustom";
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const Sidebar = (props) => {
  const customizer = useContext(CustomContext);
  const { toggleIcon } = useContext(CustomContext);
  const id = window.location.pathname.split("/").pop();
  const defaultLayout = Object.keys(customizer.layout);

  const layout = id ? id : defaultLayout;

  const userType = localStorage.getItem("usertype");

  const accessPermissions = {
    accessDashboard: localStorage.getItem("accessDashboard"),
    accessAnalytics: localStorage.getItem("accessAnalytics"),
    accessReports: localStorage.getItem("accessReports"),
    accessDataGrid: localStorage.getItem("accessDataGrid"),
    accessGeneralSettings: localStorage.getItem("accessGeneralSettings"),
    manageGeneralSettings: localStorage.getItem("manageGeneralSettings"),
    accessTenants: localStorage.getItem("accessTenants"),
    manageTenants: localStorage.getItem("manageTenants"),
    manageConfigurations: localStorage.getItem("manageConfigurations"),
    manageUsers: localStorage.getItem("manageUsers"),
    manageUserProfile: localStorage.getItem("manageUserProfile"),
    accessLogs: localStorage.getItem("accessLogs"),
    manageScheduleReports: localStorage.getItem("manageScheduleReports"),
    tenantPortalManage: localStorage.getItem("tenantPortalManage")
  };



  const filterMenuItems = (menuItems) => {
    return menuItems
      .filter((menu) => {
        // Top-level filtering based on permissions
        console.log(accessPermissions)
        if (menu.menutitle === "DashBoard" && accessPermissions.accessDashboard === "1") return true;
        if (menu.menutitle === "Analytics" && accessPermissions.accessAnalytics === "1") return true;
        if (menu.menutitle === "Reports" && accessPermissions.accessReports === "1") return true;
        if (menu.menutitle === "Data Grid" && accessPermissions.accessDataGrid === "1") return true;
        if (menu.menutitle === "") return true;

        // General Settings filtering
        if (menu.menutitle === "General Settings") {

          // Show all items if access or manage general settings is enabled
          if (accessPermissions.accessGeneralSettings === "1" || accessPermissions.manageGeneralSettings === "1") {
            return true; // Show all items
          } else {
            // Check for tenants permission
            menu.Items = menu.Items.filter((item) => {
              if (item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1")) {
                return true; // Show Tenants item if either tenant permission is granted
              }
              return false; // Otherwise, don't show
            });
            return menu.Items.length > 0; // Return true if "Tenants" is available
          }
        }

        // Configurations
        if (menu.menutitle === "Configurations" && accessPermissions.manageConfigurations === "1") return true;

        // Admin Settings
        if (menu.menutitle === "Admin Settings") {
          return (
            accessPermissions.manageUsers === "1" ||
            accessPermissions.manageUserProfile === "1" ||
            accessPermissions.accessLogs === "1" ||
            accessPermissions.manageScheduleReports === "1" ||
            accessPermissions.tenantPortalManage === "1"
          );
        }

        return false; // Default case if no permissions match
      })
      .map((menu) => {
        // Further filtering for Admin Settings after initial filter
        if (menu.menutitle === "Admin Settings") {
          menu.Items = menu.Items.filter((item) => {
            if (item.title === "User Profiles" && accessPermissions.manageUserProfile === "1") return true;
            if (item.title === "Users" && accessPermissions.manageUsers === "1") return true;
            if (item.title === "Logs" && accessPermissions.accessLogs === "1") return true;
            if (item.title === "Schedule Reports" && accessPermissions.manageScheduleReports === "1") return true;
            if (item.title === "Tenant Portal" && accessPermissions.tenantPortalManage === "1") return true;
            return false;
          });
        }

        if (menu.menutitle === "General Settings" && accessPermissions.accessGeneralSettings !== "1" && accessPermissions.manageGeneralSettings !== "1") {
          // Ensure only Tenants are shown if no general settings access
          menu.Items = menu.Items.filter((item) => {
            return item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1");
          });
        }

        return menu;
      });
  };



  const [mainmenu, setMainMenu] = useState([]);

  useEffect(() => {
    let selectedMenu;
    switch (userType) {
      case "admin":
        selectedMenu = MenuAdmin;
        break;
      case "Backend User":
        selectedMenu = filterMenuItems(MenuAdmin);;
        break;
      case "sadmin":
        selectedMenu = MenuSadmin;
        break;
      case "tenant":
        selectedMenu = MenuTenant;
        break;
      default:
        selectedMenu = filterMenuItems(MenuCustom);
    }
    console.log('Filtered Menu:', selectedMenu);
    setMainMenu(selectedMenu);
  }, [userType]);

  const [width, setWidth] = useState(0);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const handleScroll = () => {
    if (window.scrollY > 400) {
      document.querySelector(".sidebar-main").className = "sidebar-main hovered";
    } else {
      if (document.getElementById("sidebar-main")) document.querySelector(".sidebar-main").className = "sidebar-main";
    }
  };

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);
    handleResize();
    const currentUrl = window.location.pathname;

    if (Array.isArray(mainmenu)) {
      mainmenu.map((items) => {
        items.Items.filter((Items) => {
          if (Items.path === currentUrl) setNavActive(Items);
          if (!Items.children) return false;
          Items.children.filter((subItems) => {
            if (subItems.path === currentUrl) setNavActive(subItems);
            if (!subItems.children) return false;
            subItems.children.filter((subSubItems) => {
              if (subSubItems.path === currentUrl) {
                setNavActive(subSubItems);
                return true;
              } else {
                return false;
              }
            });
            return subItems;
          });
          return Items;
        });
        return items;
      });
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [layout, mainmenu]);

  const activeClass = () => {
    document.querySelector(".bg-overlay1").classList.add("active");
  };

  const setNavActive = (item) => {
    if (Array.isArray(mainmenu)) {
      mainmenu.map((menuItems) => {
        menuItems.Items.filter((Items) => {
          if (Items !== item) {
            Items.active = false;
            document.querySelector(".bg-overlay1").classList.remove("active");
          }
          if (Items.children && Items.children.includes(item)) {
            Items.active = true;
            document.querySelector(".sidebar-links").classList.add("active");
          }
          if (Items.children) {
            Items.children.filter((submenuItems) => {
              if (submenuItems.children && submenuItems.includes(item)) {
                Items.active = true;
                submenuItems.active = true;
                return true;
              } else {
                return false;
              }
            });
          }
          return Items;
        });
        return menuItems;
      });
    }
    item.active = !item.active;
    setMainMenu((prevMainMenu) => (Array.isArray(prevMainMenu) ? [...prevMainMenu] : []));
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-links").classList.remove("active");
  };

  return (
    <Fragment>
      <div
        className="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}></div>
      <div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`} sidebar-layout="stroke-svg">
        <SidebarIcon />
        <SidebarLogo />
        <SidebarMenu setMainMenu={setMainMenu} props={props} setNavActive={setNavActive} activeClass={activeClass} width={width} />
      </div>
    </Fragment>
  );
};

export default Sidebar;





