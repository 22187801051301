import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../_helper/Customizer";
import { MenuTenant } from "./MenuTenant";
import { MenuAdmin } from "./MenuAdmin";
import { MenuSadmin } from "./MenuSadmin";
import { MenuCustom } from "./MenuCustom";

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive, activeClass }) => {
  const { layout } = useContext(CustomizerContext);
  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  const id = window.location.pathname.split("/").pop();
  const layoutId = id;
  const CurrentPath = window.location.pathname;

  const userType = localStorage.getItem("usertype");
  const [menuType, setMenuType] = useState(MenuCustom);

  const accessPermissions = {
    accessDashboard: localStorage.getItem("accessDashboard"),
    accessAnalytics: localStorage.getItem("accessAnalytics"),
    accessReports: localStorage.getItem("accessReports"),
    accessDataGrid: localStorage.getItem("accessDataGrid"),
    accessGeneralSettings: localStorage.getItem("accessGeneralSettings"),
    manageGeneralSettings: localStorage.getItem("manageGeneralSettings"),
    accessTenants: localStorage.getItem("accessTenants"),
    manageTenants: localStorage.getItem("manageTenants"),
    manageConfigurations: localStorage.getItem("manageConfigurations"),
    manageUsers: localStorage.getItem("manageUsers"),
    manageUserProfile: localStorage.getItem("manageUserProfile"),
    accessLogs: localStorage.getItem("accessLogs"),
    manageScheduleReports: localStorage.getItem("manageScheduleReports"),
    tenantPortalManage: localStorage.getItem("tenantPortalManage")
  };


  const filterMenuItems = (menuItems) => {
    return menuItems
      .filter((menu) => {
        // Top-level filtering based on permissions
        console.log(accessPermissions)
        if (menu.menutitle === "DashBoard" && accessPermissions.accessDashboard === "1") return true;
        if (menu.menutitle === "Analytics" && accessPermissions.accessAnalytics === "1") return true;
        if (menu.menutitle === "Reports" && accessPermissions.accessReports === "1") return true;
        if (menu.menutitle === "Data Grid" && accessPermissions.accessDataGrid === "1") return true;
        if (menu.menutitle === "") return true;

        // General Settings filtering
        if (menu.menutitle === "General Settings") {

          // Show all items if access or manage general settings is enabled
          if (accessPermissions.accessGeneralSettings === "1" || accessPermissions.manageGeneralSettings === "1") {
            return true; // Show all items
          } else {
            // Check for tenants permission
            menu.Items = menu.Items.filter((item) => {
              if (item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1")) {
                return true; // Show Tenants item if either tenant permission is granted
              }
              return false; // Otherwise, don't show
            });
            return menu.Items.length > 0; // Return true if "Tenants" is available
          }
        }

        // Configurations
        if (menu.menutitle === "Configurations" && accessPermissions.manageConfigurations === "1") return true;

        // Admin Settings
        if (menu.menutitle === "Admin Settings") {
          return (
            accessPermissions.manageUsers === "1" ||
            accessPermissions.manageUserProfile === "1" ||
            accessPermissions.accessLogs === "1" ||
            accessPermissions.manageScheduleReports === "1" ||
            accessPermissions.tenantPortalManage === "1"
          );
        }

        return false; // Default case if no permissions match
      })
      .map((menu) => {
        // Further filtering for Admin Settings after initial filter
        if (menu.menutitle === "Admin Settings") {
          menu.Items = menu.Items.filter((item) => {
            if (item.title === "User Profiles" && accessPermissions.manageUserProfile === "1") return true;
            if (item.title === "Users" && accessPermissions.manageUsers === "1") return true;
            if (item.title === "Logs" && accessPermissions.accessLogs === "1") return true;
            if (item.title === "Schedule Reports" && accessPermissions.manageScheduleReports === "1") return true;
            if (item.title === "Tenant Portal" && accessPermissions.tenantPortalManage === "1") return true;
            return false;
          });
        }

        if (menu.menutitle === "General Settings" && accessPermissions.accessGeneralSettings !== "1" && accessPermissions.manageGeneralSettings !== "1") {
          // Ensure only Tenants are shown if no general settings access
          menu.Items = menu.Items.filter((item) => {
            return item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1");
          });
        }

        return menu;
      });
  };



  useEffect(() => {
    let selectedMenu;
    switch (userType) {
      case "admin":
        selectedMenu = MenuAdmin;
        break;
      case "Backend User":
        selectedMenu = filterMenuItems(MenuAdmin);;
        break;
      case "sadmin":
        selectedMenu = MenuSadmin;
        break;
      case "tenant":
        selectedMenu = MenuTenant;
        break;
      default:
        selectedMenu = filterMenuItems(MenuCustom);
    }
    setMenuType(selectedMenu);
  }, [userType]);

  const { t } = useTranslation();
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper";
      }
    }

    if (!item.active) {
      menuType.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: menuType });
  };

  return (
    <>
      {menuType.map((Item, i) => (
        <Fragment key={i}>
          <li className="sidebar-main-title">
            <div>
              <h6 className="lan-1">{t(Item.menutitle)}</h6>
            </div>
          </li>
          {Item.Items.map((menuItem, i) => (
            <li className="sidebar-list" key={i}>
              {menuItem.type === "sub" ? (
                <a
                  href="javascript"
                  className={`sidebar-link sidebar-title ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""} ${menuItem.active && "active"}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setNavActive(menuItem);
                    activeClass(menuItem.active);
                  }}>
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                  <div className="according-menu">{menuItem.active ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}</div>
                </a>
              ) : (
                ""
              )}

              {menuItem.type === "link" ? (
                <Link to={menuItem.path + "/" + layoutId} className={`sidebar-link sidebar-title link-nav  ${CurrentPath.includes(menuItem.title.toLowerCase()) ? "active" : ""}`} onClick={() => toggletNavActive(menuItem)}>
                  <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                  <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                </Link>
              ) : (
                ""
              )}

              {menuItem.children ? (
                <ul className="sidebar-submenu" style={layout1 !== "compact-sidebar compact-small" ? (menuItem?.active || CurrentPath.includes(menuItem?.title?.toLowerCase()) ? (sidebartoogle ? { opacity: 1, transition: "opacity 500ms ease-in" } : { display: "block" }) : { display: "none" }) : { display: "none" }}>
                  {menuItem.children.map((childrenItem, index) => {
                    return (
                      <li key={index}>
                        {childrenItem.type === "sub" ? (
                          <a
                            href="javascript"
                            className={`${CurrentPath.includes(childrenItem?.title?.toLowerCase()) ? "active" : ""}`}
                            onClick={(event) => {
                              event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}>
                            {t(childrenItem.title)}
                            <span className="sub-arrow">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                            <div className="according-menu">{childrenItem.active ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}</div>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={childrenItem.path + "/" + layoutId}
                            className={`${CurrentPath.includes(childrenItem?.title?.toLowerCase()) ? "active" : ""}`}
                            onClick={() => toggletNavActive(childrenItem)}>
                            {t(childrenItem.title)}
                          </Link>
                        ) : (
                          ""
                        )}

                        {childrenItem.children ? (
                          <ul className="nav-sub-childmenu submenu-content" style={CurrentPath.includes(childrenItem?.title?.toLowerCase()) || childrenItem.active ? { display: "block" } : { display: "none" }}>
                            {childrenItem.children.map((childrenSubItem, key) => (
                              <li key={key}>
                                {childrenSubItem.type === "link" ? (
                                  <Link
                                    to={childrenSubItem.path + "/" + layoutId}
                                    className={`${CurrentPath.includes(childrenSubItem?.title?.toLowerCase()) ? "active" : ""}`}
                                    onClick={() => toggletNavActive(childrenSubItem)}>
                                    {t(childrenSubItem.title)}
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;
