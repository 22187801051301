import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';


const EditCategoryComponent = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();

    const [category_name, setName] = useState('');
    const [category, setCategory] = useState({});
    const login_id = localStorage.getItem("loginId");

    const { id } = useParams();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        axios.get(`${config.hostname}/category/${id}`, configheader)
            .then((response) => {
                setCategory(response.data);
                setName(response.data.category_name);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            category_name,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/category/updatecategory/${id}`, data, configheader)
            .then(() => {
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Category' title='Edit Category' mainTitle='Edit Category' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Category Name</Label>
                                                    <input className="form-control" type="text" name="category" placeholder="Category Name"
                                                        value={category_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.category && 'Category name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default EditCategoryComponent