import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col } from "reactstrap";
import HeaderCard from "../../../Common/Component/HeaderCard";
import configDB from '../../../../Config/ThemeConfig';
import Chart from 'react-apexcharts';
import { PolarArea } from "react-chartjs-2";
import axios from "axios";
import config from "../../../../config";

const DeliveryPartnerSales = ({ month, year, propertyMallCode, className }) => {
    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
    const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const currentYear = new Date().getFullYear();
    const selectedYear = year === '' ? currentYear : year;
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [actualAmounts, setActualAmounts] = useState([]);


    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        if (year !== '' || propertyMallCode !== '' || month !== '') {
            setApiUrl('fbdashboardfiltered/deliverypartnersales');
            const data1 = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode,
                month
            };
            setApiData(data1);
        } else {
            setApiUrl('fbdashboard/deliverypartnersales');
            setApiData({ concessionaire_id, property_id });
        }
    }, [year, month, propertyMallCode]);

    useEffect(() => {
        if (apiUrl) {
            axios
                .post(`${config.hostname}/${apiUrl}`, apiData, configheader)
                .then((response) => {
                    const responseData = response.data;

                    // Sort the data in descending order
                    responseData.sort((a, b) => b.sale - a.sale);

                    // Filter out items with 0 sales
                    const filteredData = responseData.filter(item => parseFloat(item.sale) > 0);

                    const totalAmount = filteredData.reduce((sum, item) => sum + parseFloat(item.sale), 0);

                    let seriesData = totalAmount === 0
                        ? filteredData.map(item => 0)
                        : filteredData.map(item => parseFloat(((parseFloat(item.sale) / totalAmount) * 100).toFixed(2)));

                    const labels = filteredData.map(item => item.partnerName);
                    const amounts = filteredData.map(item => parseFloat(item.sale));

                    // Filter out 0.00 from seriesData
                    seriesData = seriesData.filter(value => value !== 0);

                    console.log(seriesData)
                    setChartData(seriesData);
                    setChartLabels(labels);
                    setActualAmounts(amounts);


                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, apiData]);


    const apexRadialBarChart = {
        series: chartData,
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px',
                        },
                        value: {
                            fontSize: '16px',
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                return 249;
                            }
                        }
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 260
                    }
                }
            }],
            labels: chartLabels,
            colors: [primary, secondary, '#51bb25', '#544fff'],
        },

    };

    const polarData = {
        labels: chartLabels,
        datasets: [
            {
                data: chartData,
                backgroundColor: [primary, secondary, "#f8d62b", "#7366ff", "#51bb25", "#a927f9"]
            },

        ],
    }

    const polarOption = {
        plugins: {
            legend: {
                display: false  // Hide the legend
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return `${value}%`;  // Append % symbol to the data value
                    }
                }
            }
        },
        scales: {
            r: {
                ticks: {
                    display: false,
                },
                pointLabels: {
                    display: false  // Hide the labels around the chart
                },
            }
        },
        maintainAspectRatio: false, // Ensure the chart does not maintain the default aspect ratio
        layout: {
            padding: {
                top: 10,
                bottom: 10,
                left: 10,
                right: 10
            }
        },
        responsive: true,
    }



    return (
        <Fragment>
            <Col sm='12' xl="6" className={className}>
                <Card>
                    <HeaderCard title='Delivery Partner Sales' />
                    <CardBody>
                        <div id='circlechart'>
                            {/* <Chart options={apexRadialBarChart.options} series={apexRadialBarChart.series} type="radialBar" height={320} /> */}
                            <PolarArea data={polarData} options={polarOption} width={717} height={348} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
}

export default DeliveryPartnerSales;


