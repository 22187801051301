import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';


const SubcategoryEdit = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();

    const [subcategory_name, setName] = useState('');
    const [subcategory, setSubcategory] = useState({});
    const [categories, setCategories] = useState([]);
    const [category_id, setCategoryId] = useState('');
    const login_id = localStorage.getItem("loginId");
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const { id } = useParams();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        axios.get(`${config.hostname}/subcategory/${id}`, configheader)
            .then((response) => {
                setSubcategory(response.data);
                setName(response.data.subcategory_name);
                const categoryId = response.data.category_id._id;
                setCategoryId(categoryId);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios
            .get(`${config.hostname}/category`, configheader)
            .then((response) => {
                // Filter countries by country_concessionaire_id
                const filteredCategories = response.data.filter(category => category.category_concessionaire_id === concessionaire_id);
                setCategories(filteredCategories);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            subcategory_name,
            category_id,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/subcategory/updatesubcategory/${id}`, data, configheader)
            .then(() => {
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Category' title='Edit Category' mainTitle='Edit Category' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup className="mb-3"> <Label className="form-label">Category</Label>
                                                    <select name="category" id="category" className="form-control digits" required
                                                        value={category_id} onChange={handleCategoryChange}>
                                                        <option value="">Select Category</option>
                                                        {categories.map(category => (
                                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <Label>Subcategory Name</Label>
                                                    <input className="form-control" type="text" name="category" placeholder="Subcategory Name"
                                                        value={subcategory_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.category && 'Subcategory name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default SubcategoryEdit