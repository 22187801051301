import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, P } from '../../../AbstractElements';
import ManageAPIKey from './ManageApiKey';


const ApiKey = () => {

  return (
    <Fragment>
      <Breadcrumbs parent="API Key" title="API Key" mainTitle="API Key" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <ManageAPIKey />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default ApiKey