import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, CardFooter, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';
import { toast } from "react-toastify";
import { Typeahead } from 'react-bootstrap-typeahead';

const PropertyEdit = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [countries, setCountries] = useState([]);


    const [properties, setProperties] = useState({});
    const [property_name, setName] = useState('');
    const property_concessionaire_id = localStorage.getItem("concessionaireId");
    const [property_area, setArea] = useState('');
    const [property_mall_code, setMallCode] = useState('');
    const [property_address, setAddress] = useState('');
    const [property_mall_currency, setCurrency] = useState('');
    const [property_country_id, setCountryId] = useState('');
    const [property_stores, setStores] = useState('');
    const [property_disable, setDisable] = useState('');
    const [floors, setFloors] = useState([]);
    const [selectedFloors, setSelectedFloors] = useState([]);
    const [floorId, setFloorId] = useState([]);
    const login_id = localStorage.getItem("loginId");

    const { id } = useParams();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json'
        }
    };

    useEffect(() => {
        axios
            .get(`${config.hostname}/country`, configheader)
            .then((response) => {
                // Filter countries by country_concessionaire_id
                const filteredCountries = response.data.filter(country => country.country_concessionaire_id === property_concessionaire_id);
                setCountries(filteredCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios.get(`${config.hostname}/property/${id}`, configheader)
            .then((response) => {
                setProperties(response.data);
                setName(response.data.property_name);
                setArea(response.data.property_area);
                setMallCode(response.data.property_mall_code);
                setCurrency(response.data.property_mall_currency);
                setStores(response.data.property_stores);
                setAddress(response.data.property_address);
                setDisable(response.data.property_disable);
                const countryId = response.data.property_country_id._id;
                setCountryId(countryId);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloors`, {}, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    floor_id: item._id,
                    floor_name: item.floor_name
                }));
                setFloors(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios.post(`${config.hostname}/property/getpropertyfloors`, { property_id: id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    floor_id: item.floor_id._id,
                    floor_name: item.floor_id.floor_name
                }));
                setFloorId(transformedData.floor_id)
                setSelectedFloors(transformedData)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleCountryChange = (e) => {
        setCountryId(e.target.value);
    };

    const handleDisableChange = (value) => {
        const isDisable = value === 'true';
        setDisable(isDisable);
    };

    const handleFloorSelection = (selected) => {
        setSelectedFloors(selected);

        // Determine the newly unselected items
        const selectedIds = selected.map(item => item.floor_id);
        const newAvailableFloors = floors.filter(floor => !selectedIds.includes(floor.floor_id));

        // Determine the newly selected items
        const unselectedFloors = floors.filter(floor => selectedIds.includes(floor.floor_id));

        // Combine the newly unselected items with existing available partners
        const combinedFloors = [...newAvailableFloors, ...unselectedFloors];
        setFloors(combinedFloors);
        setFloorId(selectedIds)
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            property_name,
            property_area,
            property_mall_code,
            property_address,
            property_mall_currency,
            property_country_id,
            property_stores,
            property_disable,
            login_id
        };
        console.log(data);
        axios
            .post(`${config.hostname}/property/updateproperty/${id}`, data, configheader)
            .then(() => {
                const floorData = {
                    concessionaire_id: property_concessionaire_id,
                    property_id: id,
                    floor_id: floorId,
                    login_id,
                    property_name
                }
                if (floorId && floorId.length > 0) {
                    axios
                        .post(`${config.hostname}/property/updatefloors`, floorData, configheader)
                        .then(() => {
                            console.log('Added floors')
                        })
                        .catch((error) => {
                            toast.error('Error on Updating Floors')
                            console.log(error);

                        });
                }
                toast.success("Successfully updated");
            })
            .catch((error) => {
                toast.error("Error");
                console.log(error);
            });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col xl='4'>
                    <Fragment>
                        <Card>
                            <CardHeader>
                                <H4 attrH4={{ className: 'card-title mb-0' }}>{property_name}</H4>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row className='mb-2'>
                                        <div className='profile-title'>
                                            <div className='media'>
                                                <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/img001.jpg')}` }} />

                                            </div>
                                        </div>
                                    </Row>
                                    <FormGroup className='mb-3'>
                                        <H6 attrH6={{ className: 'form-label' }}>Address</H6>
                                        <Input type='textarea' className='form-control' rows='5' value={property_address}
                                            placeholder='Address' onChange={(e) => setAddress(e.target.value)} />
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Fragment>
                </Col>
                <Col xl='8'>
                    <Fragment>
                        <Form className="card" >
                            <CardHeader>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Property Details</H4>
                                <div className="card-options">
                                    <a className="card-options-collapse" href="#javascript">
                                        <i className="fe fe-chevron-up"></i>
                                    </a>
                                    <a className="card-options-remove" href="#javascript">
                                        <i className="fe fe-x"></i>
                                    </a>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup> <Label className="form-label">Property Name</Label>
                                            <Input className="form-control" type="text" value={property_name}
                                                placeholder='Property Name' onChange={(e) => setName(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6">
                                        <FormGroup> <Label className="form-label">Area</Label>
                                            <Input className="form-control" type="number" value={property_area}
                                                placeholder='Area' onChange={(e) => setArea(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Mall code</Label>
                                            <Input className="form-control" type="text" value={property_mall_code}
                                                placeholder='Mall Code' onChange={(e) => setMallCode(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Currency</Label>
                                            <Input className="form-control" type="text" value={property_mall_currency}
                                                placeholder='Currency' onChange={(e) => setCurrency(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Country</Label>
                                            <select name="country" id="propertycountry" className="form-control digits" required
                                                value={property_country_id} onChange={handleCountryChange}>
                                                <option value="">Select Country</option>
                                                {countries.map(country => (
                                                    <option key={country._id} value={country._id}>{country.country_name}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Stores</Label>
                                            <Input className="form-control" type="number" value={property_stores}
                                                placeholder='Stores' onChange={(e) => setStores(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Disabled</Label>
                                            <select name="activestatus" className="form-control digits" required
                                                value={property_disable}
                                                onChange={(e) => handleDisableChange(e.target.value)}>
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md='6'>
                                        <FormGroup className="mb-3">
                                            <Label className="form-label">Floors</Label>
                                            <Typeahead
                                                id="floor_id"
                                                name="floor_id"
                                                clearButton
                                                labelKey="floor_name"
                                                multiple
                                                options={floors}
                                                selected={selectedFloors}
                                                placeholder="Choose floors..."
                                                onChange={handleFloorSelection}
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.floor_name}</span>
                                                )}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Form>
                        <CardFooter className="text-end">
                            <Btn attrBtn={{ color: "primary", type: "submit" }}  >Update</Btn>
                        </CardFooter>
                    </Fragment>
                </Col>
            </Row>
        </Form>
    )
}

export default PropertyEdit