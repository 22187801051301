import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import ConcessionaireTable from './ConcessionaireTable';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const ConcessionaireList = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Concessionaire" title="Concessionaire List" mainTitle="Concessionaire List" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <ConcessionaireTable />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default ConcessionaireList