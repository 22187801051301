import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button, Container, CardFooter, Input } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';

const RevenuMethodAdd = () => {
    const concessionaire_id = localStorage.getItem('concessionaireId');
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem('sessiontoken');
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const [movedItems, setMovedItems] = useState([]);
    const [revenueShareMethods, setRevenueShareMethods] = useState([]);
    const [availableItems, setAvailableItems] = useState([]);

    useEffect(() => {
        axios
            .get(`${config.hostname}/revenuemethods/getrevenuemethods`, configheader)
            .then((response) => {
                setRevenueShareMethods(response.data);
                setAvailableItems(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairerevenuemethods/getmethods`, { concessionaire_id }, configheader)
            .then((response) => {
                const methods = response.data.map(method => ({
                    id: method.revenue_method_id._id,
                    text: method.revenue_method_id.description
                }));
                setMovedItems(methods);

                const updatedAvailableItems = revenueShareMethods.filter(ai =>
                    !methods.some(pi => pi.id === ai._id)
                );
                setAvailableItems(updatedAvailableItems);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [revenueShareMethods]);

    const handleSelect = (id) => {
        setSelectedItems(prev => prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]);
    };

    const moveSelectedItems = () => {
        const itemsToMove = availableItems.filter(item => selectedItems.includes(item._id));
        setMovedItems(prev => [...prev, ...itemsToMove.map(item => ({ id: item._id, text: item.description }))]);
        setSelectedItems([]);
        setAvailableItems(prev => prev.filter(item => !selectedItems.includes(item._id)));
        handleSave(itemsToMove.map(item => item._id));
    };

    const moveBackSelectedItems = () => {
        const itemsToMoveBack = movedItems.filter(item => selectedItems.includes(item.id));
        setAvailableItems(prev => [...prev, ...itemsToMoveBack.map(item => ({ _id: item.id, description: item.text }))]);
        setSelectedItems([]);
        setMovedItems(prev => prev.filter(item => !selectedItems.includes(item.id)));
        handleRemove(itemsToMoveBack.map(item => item.id));
    };

    const handleSave = (itemsToAdd) => {
        const data = {
            concessionaire_id,
            revenue_method_id: itemsToAdd,
            login_id
        };

        axios
            .post(`${config.hostname}/concessionairerevenuemethods/createmethod`, data, configheader)
            .then(() => {
                toast.success('Successfully Added');
            })
            .catch((error) => {
                toast.error('Error');
                console.log(error);
            });
    };

    const handleRemove = (itemsToRemove) => {
        const data = {
            concessionaire_id,
            revenue_method_id: itemsToRemove,
            login_id
        };

        axios({
            method: 'delete',
            url: `${config.hostname}/concessionairerevenuemethods/removemethod`,
            data: data,
            headers: configheader.headers
        })
            .then(() => {
                toast.success('Successfully Removed');
            })
            .catch((error) => {
                toast.error('Error');
                console.log(error);
            });
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                {availableItems.map(method => (
                                                    <div key={method._id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(method._id)}
                                                            onChange={() => handleSelect(method._id)}
                                                        /> {method.description}
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveSelectedItems} color="primary">Add Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                {movedItems.map((item) => (
                                                    <div key={item.id}>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedItems.includes(item.id)}
                                                            onChange={() => handleSelect(item.id)}
                                                        /> {item.text}
                                                    </div>
                                                ))}
                                            </CardBody>
                                            <CardFooter>
                                                <Button onClick={moveBackSelectedItems} color="primary" className="ml-2">Remove Selected</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default RevenuMethodAdd;

