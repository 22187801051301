import React from 'react'
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


export const exportToExcel = (reportHeading, metadata, reportColumns, reportData, fileName) => {
    // Prepare the data based on report columns
    const exportData = reportData.map(item => {
        const formattedItem = {};
        reportColumns.forEach(column => {
            formattedItem[column.header] = column.format ? column.format(item[column.key]) : item[column.key];
        });
        return formattedItem;
    });

    // Calculate totals
    const totalTransactionCount = reportData.reduce((sum, item) => sum + item.total_transaction_count, 0);
    const totalNetSales = reportData.reduce((sum, item) => sum + item.total_net_sales, 0);

    // Add totals row
    const totalsRow = reportColumns.reduce((acc, column) => {
        if (column.key === 'total_transaction_count') {
            acc[column.header] = totalTransactionCount;
        } else if (column.key === 'total_net_sales') {
            acc[column.header] = totalNetSales.toLocaleString();
        } else {
            acc[column.header] = '';
        }
        return acc;
    }, {});

    exportData.push(totalsRow);

    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([[]]);

    // Add the heading
    XLSX.utils.sheet_add_aoa(worksheet, [[reportHeading]], { origin: 'A1' });

    // Apply the heading style manually
    worksheet['A1'] = {
        t: 's',
        v: reportHeading,
        s: {
            font: { sz: 14, bold: true },
            alignment: { horizontal: 'center' }
        }
    };

    // Merge the heading cells
    worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: reportColumns.length - 1 } }];

    // Add metadata to the worksheet below the heading
    const metadataArray = metadata.map(item => [Array.isArray(item) ? item.join(': ') : item]);
    XLSX.utils.sheet_add_aoa(worksheet, metadataArray, { origin: 'A2' });

    // Add and style column headers
    const columnHeaders = [reportColumns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(worksheet, columnHeaders, { origin: `A${metadata.length + 2}` });

    // Add the data below the column headers
    XLSX.utils.sheet_add_json(worksheet, exportData, { origin: `A${metadata.length + 3}`, skipHeader: true });

    // Auto adjust column widths
    const columnWidths = reportColumns.map((column, index) => {
        const maxLength = Math.max(
            ...exportData.map(item => (item[column.header] ? item[column.header].toString().length : 0)),
            column.header.length
        );
        return { wch: maxLength + 2 }; // Add some padding for better readability
    });
    worksheet['!cols'] = columnWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, fileName);
};


export const exportToPDF = (reportHeading, metadata, reportColumns, reportData, fileName, landscape = false) => {

    const orientation = landscape ? 'landscape' : 'portrait';
    const doc = new jsPDF({
        orientation: orientation
    });

    // Add a heading
    doc.setFontSize(13);
    doc.setFont('helvetica', 'bold');
    doc.text(reportHeading, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

    // Reset font for metadata
    doc.setFontSize(8);
    doc.setFont('helvetica', 'normal');

    // Add additional information at the top of the PDF with reduced line height
    const lineHeight = 5;  // Reduced line height
    const startX = 10;
    let startY = 20;

    metadata.forEach((item, index) => {
        doc.text(Array.isArray(item) ? item.join(': ') : item, startX, startY + (index * lineHeight));
    });

    // Add a space before the table
    startY += metadata.length * lineHeight + 5;  // Reduced spacing before the table

    // Prepare table headers and rows
    const tableHeaders = reportColumns.map(column => column.header);
    const tableRows = reportData.map(item => reportColumns.map(column => {
        return column.format ? column.format(item[column.key]) : item[column.key];
    }));

    // Calculate totals
    const totalTransactionCount = reportData.reduce((sum, item) => sum + item.total_transaction_count, 0);
    const totalNetSales = reportData.reduce((sum, item) => sum + item.total_net_sales, 0);

    // Add totals row
    const totalsRow = reportColumns.map(column => {
        if (column.key === 'total_transaction_count') {
            return totalTransactionCount;
        } else if (column.key === 'total_net_sales') {
            return totalNetSales.toLocaleString();
        } else {
            return '';
        }
    });

    tableRows.push(totalsRow);

    doc.autoTable({
        startY: startY,
        head: [tableHeaders],
        body: tableRows,
        foot: [totalsRow],
        styles: {
            fontSize: 7,
            cellPadding: 2,
            overflow: 'linebreak'
        },
        headStyles: {
            fontSize: 7
        },
        footStyles: {
            fontSize: 5
        },
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        columnStyles: {
            // Assuming 'total_net_sales' is at index 10, set its width to prevent line breaks
            10: { cellWidth: 'auto' }
        },
        didDrawCell: function (data) {
            if (data.section === 'foot' && data.column.index === 10) {
                doc.setFillColor(255, 255, 255); // Reset the background color for the totals row
            }
        }
    });

    doc.save(fileName);
};




