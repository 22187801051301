import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import axios from 'axios';
import config from '../../../config';
import { Card, CardHeader, CardBody, FormGroup, Button, Label, Input, Form } from 'reactstrap';
import { toast } from 'react-toastify';

const APIKeyView = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [concessionaires, setConcessionaires] = useState([]);
    const [concessionaireId, setConcessionaireId] = useState('');
    const [tenantCode, setTenantCode] = useState('');
    const [apiKeyResult, setApiKeyResult] = useState(null);
    const [copied, setCopied] = useState(false);


    useEffect(() => {
        axios
            .get(`${config.hostname}/concessionaire`, configheader)
            .then((response) => {
                setConcessionaires(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleConcessionaireChange = (e) => {
        setConcessionaireId(e.target.value);
        setApiKeyResult(null)
    };

    const handleTCodeChange = (e) => {
        setTenantCode(e.target.value);
        setApiKeyResult(null)
    };

    const handleReset = (e) => {
        setConcessionaireId('');
        setTenantCode('')
        setApiKeyResult(null)
    };

    const handleViewApiKey = async (e) => {
        try {
            const response = await axios.post(
                `${config.hostname}/tenant/getapikeybytcode`,
                {
                    concessionaire_id: concessionaireId,
                    tenant_code: tenantCode
                },
                configheader
            );
            setApiKeyResult(response.data);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                toast.error(error.response.data.message || "API Key not found");
            } else {
                console.error("Error fetching API key:", error);
                toast.error(error.message || "An error occurred");
            }
        }
    };
    return (
        <div>
            <Card>
                <CardBody>
                    <Form className='theme-form' onSubmit={handleSubmit(handleViewApiKey)}>
                        <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
                            <div style={{ flex: 1 }}>
                                <FormGroup>
                                    <Label for="concessionaire">Select Concessionaire</Label>
                                    <Input
                                        type="select"
                                        name="concessionaire"
                                        id="concessionaire"
                                        value={concessionaireId}
                                        onChange={handleConcessionaireChange}
                                        required
                                        style={{ fontSize: '16px', padding: '10px', width: '100%' }}
                                    >
                                        <option value="">Select Concessionaire</option>
                                        {concessionaires.map(concessionaire => (
                                            <option key={concessionaire._id} value={concessionaire._id}>
                                                {concessionaire.concessionaire_name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </div>
                            <div style={{ flex: 1 }}>
                                <FormGroup>
                                    <Label for="tenant_code">Tenant Code</Label>
                                    <Input
                                        className={`form-control ${errors.tenant_code ? 'is-invalid' : ''}`}
                                        id="tenant_code"
                                        type="text"
                                        name="tenant_code"
                                        value={tenantCode}
                                        required
                                        placeholder="Tenant Code"
                                        onChange={handleTCodeChange}
                                        style={{ fontSize: '16px', padding: '10px', width: '100%' }}
                                    />
                                    {errors.tenant_code && <span className="text-danger">Tenant code is required</span>}
                                </FormGroup>
                            </div>
                        </div>

                        <div>
                            <Button
                                color="primary"
                                type='submit'
                            >
                                View API Key
                            </Button>
                            <Button
                                style={{ marginLeft: '10px' }}
                                color="primary"
                                onClick={handleReset}
                            >
                                Reset
                            </Button>
                        </div>


                    </Form>
                    {apiKeyResult && (
                        <div className="mt-4" style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <h4 style={{ marginRight: '10px' }}>API Key: {apiKeyResult.tenant_api_key}</h4>
                                <button
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'black',
                                        padding: '0',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(apiKeyResult.tenant_api_key);
                                        setCopied(true);
                                        setTimeout(() => setCopied(false), 2000); // Reset to "Copy" after 2 seconds
                                    }}
                                >
                                    {copied ? 'Copied!' : 'Copy'}
                                </button>
                            </div>
                            <ul>
                                {apiKeyResult.tenants.map(tenant => (
                                    <li key={tenant.tenant_code}>
                                        {tenant.tenant_store_name} - {tenant.tenant_code}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>

    )
}

export default APIKeyView