import React, { useState, useCallback } from 'react'


const TreeNode = ({ node, checkedItems, onToggle, onLevelChange, onCheck, level = 0, getSelectedLevel }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);


    const handleCheck = useCallback((nodeId) => {
        onToggle(nodeId, node.children);
        onLevelChange(level, nodeId);
        onCheck(nodeId);
        getSelectedLevel(level, nodeId, true)
        console.log("Node Id " + nodeId);
        console.log(node.children);
        console.log("Node Level " + level);
    }, [node.children, onCheck, onLevelChange, onToggle, level, getSelectedLevel]);

    const handleUncheck = useCallback((nodeId) => {
        onToggle(nodeId, node.children);
        getSelectedLevel(level, nodeId, false)
    }, [node.children, onToggle, level, getSelectedLevel]);



    return (
        <div style={{ marginLeft: `20px` }}>
            <div>
                <input
                    type="checkbox"
                    checked={!!checkedItems[node.id]}
                    // onChange={() => handleCheckToggle(node.id)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            handleCheck(node.id);
                        }
                        else {
                            handleUncheck(node.id);
                        }
                    }}
                />
                <span
                    onClick={node.children ? handleExpandClick : undefined}
                    style={{ cursor: node.children ? 'pointer' : 'default', marginLeft: '5px' }}
                >
                    {node.children ? (isExpanded ? '-' : '+') : ''} {node.text}
                </span>
            </div>
            {isExpanded && node.children && (
                <div>
                    {node.children.map(childNode => (
                        <TreeNode
                            key={childNode.id}
                            node={childNode}
                            checkedItems={checkedItems}
                            onToggle={onToggle}
                            onCheck={onCheck}
                            level={level + 1}
                            onLevelChange={onLevelChange}
                            getSelectedLevel={getSelectedLevel}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeNode



