import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Spinner } from '../../../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { exportToExcel, exportToPDF } from '../ExportReport';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const FbSalesReport = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [properties, setProperties] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [ReportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const today = new Date().toISOString().split('T')[0];
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                const filteredsubcategories = response.data.filter(subcategory => subcategory.category_id.category_name === 'F&B');
                setSubcategories(filteredsubcategories);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                const filterResponse = response.data.filter(tenant => tenant.tenant_category_id.category_name === 'F&B');
                setAllTenants(filterResponse);
                setTenants(filterResponse);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [property_id, subcategory_id, allTenants]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, { concessionaire_id }, configheader)
            .then((response) => {
                const deliveryPartnerNames = response.data.map(item => item.delivery_partner_id.delivery_partner_name)
                    .filter(name => name !== "Dine In");
                setDeliveryPartners(deliveryPartnerNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const generateReport = async (e) => {
        e.preventDefault();


        if (endDate < startDate) {
            setValidationMessage('End date cannot be less than start date');
            return;
        } else {
            setValidationMessage('');
        }

        const data = {
            concessionaire_id,
            property_access_id: property_access,
            start_date: startDate,
            end_date: endDate,
            property_id,
            subcategory_id,
            tenant_id,
        };
        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getfbsalessummary`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                console.log(response.data);
                setLoading(false)
                insertToLog(" generated F&B sales summary report")
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setTenantId('')
        setReportData([]);
        setCurrentPage(0);
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setTenantId('')
        setReportData([]);
        setCurrentPage(0);
    };

    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };


    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    const pageCount = Math.ceil(ReportData.length / itemsPerPage);

    const totalDineInCount = ReportData.reduce((sum, item) => sum + item.dine_in_count, 0);
    const totalDineinSales = ReportData.reduce((sum, item) => sum + item.dine_in_sales, 0);
    const totalDeliveryCount = ReportData.reduce((sum, item) => sum + item.total_delivery_partner_count, 0);
    const totalDeliverySales = ReportData.reduce((sum, item) => sum + item.total_delivery_partner_sales, 0);

    const sortedReportData = React.useMemo(() => {
        let sortableData = [...ReportData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [ReportData, sortConfig]);

    const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = key => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'asc') {
                return <FontAwesomeIcon icon={faSortUp} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
            if (sortConfig.direction === 'desc') {
                return <FontAwesomeIcon icon={faSortDown} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
        }

    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleExport = (format) => {
        if (ReportData.length > 0) {
            const metadata = [
                ['From: ', startDate, ' To:', endDate],
                ['Property', property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'],
                ['Subcategory', subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'],
                ['Tenant', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All']
            ];
            const reportColumns = [
                { header: 'Tenant Name', key: 'tenant_store_name' },
                { header: 'Tenant Code', key: 'tenant_code' },
                { header: 'Unit Code', key: 'tenant_unit_code' },
                { header: 'Property', key: 'property_name' },
                { header: 'Subcategory', key: 'subcategory_name' },
                { header: 'Dine In Count', key: 'dine_in_count' },
                { header: 'Dine In Sales', key: 'dine_in_sales' },
                { header: 'Delivery Count', key: 'total_delivery_partner_count' },
                { header: 'Delivery Sales', key: 'total_delivery_partner_sales' }

            ];

            deliveryPartners.forEach(partner => {
                const partnerKey = partner.toLowerCase().replace(/ /g, '_');
                console.log('partner key: ' + partnerKey)
                reportColumns.push(
                    { header: `${partner} Count`, key: `${partnerKey}_count` },
                    { header: `${partner} Sales`, key: `${partnerKey}_sales` }
                );
            });

            const preparedReportData = ReportData.map(item => {
                let preparedItem = { ...item };

                // Add delivery partner counts and sales to the prepared item
                deliveryPartners.forEach(partner => {
                    const partnerKey = partner.toLowerCase().replace(/ /g, '_');
                    preparedItem[`${partnerKey}_count`] = item.delivery_partner_counts ? item.delivery_partner_counts[`${partnerKey}_count`] || 0 : 0;
                    preparedItem[`${partnerKey}_sales`] = item.delivery_partner_sales ? parseFloat(item.delivery_partner_sales[`${partnerKey}_sales`] || 0).toFixed(2) : '0.00';
                });

                return preparedItem;
            });

            if (format === 'excel') {
                exportToExcel("F&B Sales Report", metadata, reportColumns, preparedReportData, "FBSalesSummary.xlsx");
                insertToLog(" exported F&B sales summary report to excel")
            } else if (format === 'pdf') {
                exportToPDF("F&B Sales Report", metadata, reportColumns, preparedReportData, "FBSalesSummary.pdf", true);
                insertToLog(" exported F&B sales summary report to pdf")
            }
        }
    };

    const reset = () => {
        setStartDate('')
        setEndDate('')
        setPropertyId('');
        setSubcategoryId('');
        setTenantId('');
        setReportData([])

    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={startDate}
                                        max={today} onChange={handleStartDateChange} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">End Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={endDate}
                                        max={today} onChange={handleEndDateChange} required />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table>

                                    <thead>
                                        <tr>
                                            <th scope="col" onClick={() => requestSort('tenant_store_name')}>Tenant Name {getSortIcon('tenant_store_name')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_group_name')}>Group Name {getSortIcon('tenant_group_name')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_code')}>Tenant Code {getSortIcon('tenant_code')}</th>
                                            <th scope="col" onClick={() => requestSort('tenant_unit_code')}>Unit Code {getSortIcon('tenant_unit_code')}</th>
                                            <th scope="col" onClick={() => requestSort('property_name')}>Property {getSortIcon('property_name')}</th>
                                            <th scope="col" onClick={() => requestSort('subcategory_name')}>Subcategory {getSortIcon('subcategory_name')}</th>
                                            <th scope="col" onClick={() => requestSort('dine_in_count')}>Dine In Count {getSortIcon('dine_in_count')}</th>
                                            <th scope="col" onClick={() => requestSort('dine_in_sales')}>Dine In Sales {getSortIcon('dine_in_sales')}</th>
                                            <th scope="col" onClick={() => requestSort('delivery_partner_count')}>Delivery Count</th>
                                            <th scope="col" onClick={() => requestSort('delivery_partner_sales')}>Delivery Sales </th>
                                            {deliveryPartners.map((partner, index) => (
                                                <React.Fragment key={index}>
                                                    <th scope="col" onClick={() => requestSort(`${partner}_count`)}>{partner.replace('_', ' ')} Count</th>
                                                    <th scope="col" onClick={() => requestSort(`${partner}_sales`)}>{partner.replace('_', ' ')} Sales</th>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.tenant_store_name}</td>
                                                <td>{item.tenant_group_name}</td>
                                                <td>{item.tenant_code}</td>
                                                <td>{item.tenant_unit_code}</td>
                                                <td>{item.property_name}</td>
                                                <td>{item.subcategory_name}</td>
                                                <td>{item.dine_in_count}</td>
                                                <td>{parseFloat(item.dine_in_sales).toFixed(2)}</td>
                                                <td>{item.total_delivery_partner_count}</td>
                                                <td>{parseFloat(item.total_delivery_partner_sales).toFixed(2)}</td>
                                                {deliveryPartners.map((partner, partnerIndex) => {
                                                    const partnerKeyCount = `${partner.toLowerCase().replace(/ /g, '_')}_count`;
                                                    const partnerKeySales = `${partner.toLowerCase().replace(/ /g, '_')}_sales`;
                                                    return (
                                                        <React.Fragment key={partnerIndex}>
                                                            <td>{item.delivery_partner_counts ? item.delivery_partner_counts[partnerKeyCount] || 0 : 0}</td>
                                                            <td>{item.delivery_partner_sales ? parseFloat(item.delivery_partner_sales[partnerKeySales] || 0).toFixed(2) : 0.00}</td>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="6">Total</td>
                                            <td>{totalDineInCount}</td>
                                            <td>{totalDineinSales.toLocaleString()}</td>
                                            <td>{totalDeliveryCount}</td>
                                            <td>{totalDeliverySales.toLocaleString()}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                            {ReportData.length > 50 && (
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            )}
                        </Col>
                    </CardBody>
                </Card>
            )}
        </Fragment>
    )
}

export default FbSalesReport