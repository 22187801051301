import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import ManageUsers from './ManageUsers';

const Users = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Admin Settings" title="Users" mainTitle="Users" />
            <Container fluid={true}>
                <ManageUsers />
            </Container>
        </Fragment>
    )
}
export default Users