import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import ViewLog from './ViewLog';

const Log = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Admin Settings" title="Log" mainTitle="View Log" />
            <Container fluid={true}>
                <ViewLog />
            </Container>
        </Fragment>
    )
}

export default Log