import React, { Fragment, useState } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';

const NewCountry = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [country_name, setName] = useState('');
    const [country_currency, setCurrency] = useState('');
    const [country_code, setCode] = useState('');
    const country_concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            country_name,
            country_code,
            country_currency,
            country_concessionaire_id,
            login_id
        };
        console.log(data);


        axios
            .post(`${config.hostname}/country`, data, configheader)
            .then(() => {
                toast.success("Successfully Added");
                setName('');
                setCode('');
                setCurrency('');
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Country' title='Add New Country' mainTitle='Add New Country' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Country Name</Label>
                                                    <input className="form-control" type="text" name="country" placeholder="Country Name"
                                                        value={country_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.concessionairename && 'Country name is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Country Code</Label>
                                                    <input className="form-control" type="text" name="countrycode" placeholder="Country Code"
                                                        value={country_code} onChange={(e) => setCode(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.email && 'Country code is required'}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <Label>Currency</Label>
                                                    <input className="form-control" type="text" name="phone" placeholder="Currency" required
                                                        value={country_currency} onChange={(e) => setCurrency(e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.phone && 'Currency is required'}</span>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                {/* <Btn attrBtn={{ color: 'success', className: 'me-3', onClick: (e) => handleSubmit(e) }} >{Add} </Btn> */}
                                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >{Add} </Btn>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/country/viewcountry/:layout`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewCountry