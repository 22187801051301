import React, { useEffect, useState, useContext } from 'react'
import { Col, Card, CardHeader, CardBody } from 'reactstrap'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import CustomizerContext from "../../../_helper/Customizer";


const BackendHomeView = () => {

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: token
        }
    };

    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const login_id = localStorage.getItem("loginId");
    const [concessionaires, setConcessionaires] = useState([])

    useEffect(() => {
        axios
            .post(`${config.hostname}/users/getconcessionaireaccess`, { user_id: login_id }, configheader)
            .then((response) => {
                setConcessionaires(response.data.concessionaire_access);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const handleCardClick = (concessionaireId) => {
        localStorage.setItem('concessionaireId', concessionaireId);
        localStorage.setItem("accessDashboard", "1")
        localStorage.setItem("accessAnalytics", "1")
        localStorage.setItem("accessReports", "1")
        localStorage.setItem("accessDataGrid", "1")
        localStorage.setItem("accessGeneralSettings", "1")
        localStorage.setItem("manageGeneralSettings", "1")
        localStorage.setItem("accessTenants", "1")
        localStorage.setItem("manageTenants", "1")
        localStorage.setItem("manageConfigurations", "1")
        localStorage.setItem("manageUsers", "1")
        localStorage.setItem("manageUserProfile", "1")
        localStorage.setItem("accessLogs", "1")
        localStorage.setItem("manageScheduleReports", "1");
        localStorage.setItem("tenantPortalManage", "1");
        // navigate(`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layoutURL}`); //admin
        window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard/sales/${layoutURL}`
    };

    const handleViewAPIKey = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/viewapikey/${layoutURL}`);
    }

    return (
        <>
            <Col className=' d-flex justify-content-end align-items-center'>
                <button className='btn btn-primary' onClick={handleViewAPIKey}>View API Key</button>
            </Col >
            {concessionaires.map((concessionaire) => (
                <Col key={concessionaire._id} className='col-xxl-4 col-sm-6 box-col-6'>
                    <Card className='profile-box' style={{ cursor: 'pointer' }} onClick={() => handleCardClick(concessionaire._id)}>
                        <CardBody>
                            <h5>{concessionaire.concessionaire_name}</h5>
                        </CardBody>
                    </Card>
                </Col>
            ))}

        </>
    )
}

export default BackendHomeView