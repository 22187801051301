import React, { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Form, Label, FormGroup, Button, Input } from "reactstrap";
import axios from "axios";
import config from "../../../../config";
import { Typeahead } from 'react-bootstrap-typeahead';


const BrandInfo = ({ setSteps, setFormdata, formdata }) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue, clearErrors
    } = useForm();
    const onSubmit = (data) => {
        setFormdata((prev) => ({ ...prev, ...data }));
        setSteps((prev) => prev + 1);
    };

    const tenant_concessionaire_id = localStorage.getItem("concessionaireId");

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const [tenant_country_id, setCountryId] = useState('');
    //const [tenant_property_id, setPropertyId] = useState('');
    const [countries, setCountries] = useState([]);
    const [properties, setProperties] = useState([]);
    const [tenant_franchisee, setFranchisee] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [groupNames, setGroupNames] = useState('');


    const handleCountryChange = (e) => {
        const newCountryId = e.target.value;
        setCountryId(newCountryId);
        formdata.tenant_country_id = newCountryId;
    };

    const handlePropertyChange = (e) => {
        formdata.tenant_property_id = e.target.value;
        formdata.property_mall_code = properties.find(property => property._id === e.target.value).property_mall_code;
    };

    const handleFranchiseeChange = (e) => {
        setFranchisee(e.target.value);
    };

    const handleLogoChange = (e) => {
        setSelectedFile(e.target.files[0]);
        formdata.tenant_logo = e.target.files[0];
    };


    useEffect(() => {
        axios
            .post(`${config.hostname}/country/getcountrybyconcessionaire`, { country_concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: tenant_concessionaire_id }, configheader)
            .then((response) => {
                const filteredProperties = response.data.filter(property => property.property_country_id._id === tenant_country_id);
                setProperties(filteredProperties);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [tenant_country_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id }, configheader)
            .then((response) => {
                setGroupNames(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    return (
        <Fragment>
            <Row>
                <Col md="12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="form-bookmark needs-validation">
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_store_name">{'Store Name'}</Label>
                                    <input className={`form-control ${errors.tenant_store_name && "is-invalid"}`}
                                        id="tenant_store_name" type="text" name="tenant_store_name" defaultValue={formdata.tenant_store_name || ""}
                                        {...register("tenant_store_name", { required: true })} placeholder="Store Name" />
                                    <span className="text-danger">{errors.tenant_store_name && "Store name is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_brand_name">{'Brand Name'}</Label>
                                    <input className={`form-control ${errors.tenant_brand_name && "is-invalid"}`}
                                        id="tenant_brand_name" type="text" name="tenant_brand_name" defaultValue={formdata.tenant_brand_name || ""}
                                        {...register("tenant_brand_name", { required: true })} placeholder="Brand Name" />
                                    <span className="text-danger">{errors.tenant_brand_name && "Brand name is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_group_name">Group Name</Label>

                                    <Controller
                                        name="tenant_group_name"
                                        control={control}
                                        //rules={{ required: true }}
                                        render={({ field }) => (
                                            <Typeahead
                                                id="tenant_group_name"
                                                labelKey="tenant_group_name"
                                                multiple={false}
                                                allowNew
                                                newSelectionPrefix="Add a new group: "
                                                //options={groupNames}
                                                options={groupNames.length > 0 ? groupNames : [{ tenant_group_name: "No options available" }]}
                                                placeholder="Group Name"
                                                onChange={(selected) => {
                                                    const value = selected.length > 0 ? selected[0].customOption ? selected[0].tenant_group_name : selected[0] : '';
                                                    field.onChange(value);
                                                    if (value) {
                                                        clearErrors("tenant_group_name");
                                                    }
                                                }}
                                                selected={field.value ? [field.value] : []}
                                                onInputChange={(input) => {
                                                    setValue("tenant_group_name", input);
                                                    if (input) {
                                                        clearErrors("tenant_group_name");
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <span className="text-danger">
                                        {errors.tenant_group_name && "Group Name is required"}
                                    </span> */}
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_email_id">Email</Label>
                                    <input className={`form-control ${errors.tenant_email_id && "is-invalid"}`}
                                        id="tenant_email_id" type="text" name="tenant_email_id" defaultValue={formdata.tenant_email_id || ""}
                                        {...register("tenant_email_id", { required: true })} placeholder="Email" />
                                    <span className="text-danger">{errors.tenant_email_id && "Email is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_country_id">Country</Label>
                                    <select name="tenant_country_id" id="tenant_country_id" className={`form-control ${errors.tenant_country_id && "is-invalid"}`}
                                        {...register("tenant_country_id", { required: true })}
                                        defaultValue={formdata.tenant_country_id} onChange={handleCountryChange}>
                                        <option value="">Select Country</option>
                                        {countries.map(country => (
                                            <option key={country._id} value={country._id}>{country.country_name}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_country_id && "Country is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_property_id">Property</Label>
                                    <select name="tenant_property_id" id="tenant_property_id" className={`form-control ${errors.tenant_property_id && "is-invalid"}`}
                                        {...register("tenant_property_id", { required: true })}
                                        defaultValue={formdata.tenant_property_id} onChange={handlePropertyChange}>
                                        <option value="">Select Property</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{errors.tenant_property_id && "Property is required"}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_franchisee">Franchisee</Label>
                                    <select name="tenant_franchisee" id="tenant_franchisee" className="form-control digits"
                                        {...register("tenant_franchisee", { required: true })}
                                        value={formdata.tenant_franchisee} onChange={handleFranchiseeChange}>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>

                                    </select>
                                    <span className="text-danger">{errors.tenant_franchisee && "Store name is required"}</span>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_address">Address</Label>
                                    <input className={`form-control ${errors.tenant_address && "is-invalid"}`}
                                        id="tenant_address" type="text" name="tenant_address" defaultValue={formdata.tenant_address || ""}
                                        {...register("tenant_address")} placeholder="Address" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_logo">Logo</Label>
                                    {/* <Input className="form-control" type="file" /> */}
                                    <input className={`form-control ${errors.tenant_logo && "is-invalid"}`}
                                        id="tenant_logo" type="file" name="tenant_logo" onChange={handleLogoChange}
                                        accept="image/*"
                                        {...register("tenant_logo")} placeholder="Logo" />
                                </FormGroup>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                        <div className="text-end btn-mb">
                            <Button className="primary" color="primary">Next</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    )
}

export default BrandInfo