
import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import { Form, Row } from 'reactstrap';
import { X } from 'react-feather';
import { Link } from 'react-router-dom';
import CustomContext from '../../_helper/Customizer';
import Leftbar from './Leftbar/index';
import RightHeader from './RightHeader/index';
import { MenuTenant } from '../Sidebar/MenuTenant';
import { MenuAdmin } from '../Sidebar/MenuAdmin';
import { MenuSadmin } from '../Sidebar/MenuSadmin';
import { MenuCustom } from '../Sidebar/MenuCustom';
import { Loading } from '../../Constant';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Header = () => {
  const id = window.location.pathname.split('/').pop();
  const layout = id;
  const { toggleIcon } = useContext(CustomContext);
  const userType = localStorage.getItem("usertype");

  const accessPermissions = {
    accessDashboard: localStorage.getItem("accessDashboard"),
    accessAnalytics: localStorage.getItem("accessAnalytics"),
    accessReports: localStorage.getItem("accessReports"),
    accessDataGrid: localStorage.getItem("accessDataGrid"),
    accessGeneralSettings: localStorage.getItem("accessGeneralSettings"),
    manageGeneralSettings: localStorage.getItem("manageGeneralSettings"),
    accessTenants: localStorage.getItem("accessTenants"),
    manageTenants: localStorage.getItem("manageTenants"),
    manageConfigurations: localStorage.getItem("manageConfigurations"),
    manageUsers: localStorage.getItem("manageUsers"),
    manageUserProfile: localStorage.getItem("manageUserProfile"),
    accessLogs: localStorage.getItem("accessLogs"),
    manageScheduleReports: localStorage.getItem("manageScheduleReports"),
    tenantPortalManage: localStorage.getItem("tenantPortalManage")
  };


  const filterMenuItems = (menuItems) => {
    return menuItems
      .filter((menu) => {
        // Top-level filtering based on permissions
        if (menu.menutitle === "DashBoard" && accessPermissions.accessDashboard === "1") return true;
        if (menu.menutitle === "Analytics" && accessPermissions.accessAnalytics === "1") return true;
        if (menu.menutitle === "Reports" && accessPermissions.accessReports === "1") return true;
        if (menu.menutitle === "Data Grid" && accessPermissions.accessDataGrid === "1") return true;
        if (menu.menutitle === "") return true;

        // General Settings filtering
        if (menu.menutitle === "General Settings") {

          // Show all items if access or manage general settings is enabled
          if (accessPermissions.accessGeneralSettings === "1" || accessPermissions.manageGeneralSettings === "1") {
            return true; // Show all items
          } else {
            // Check for tenants permission
            menu.Items = menu.Items.filter((item) => {
              if (item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1")) {
                return true; // Show Tenants item if either tenant permission is granted
              }
              return false; // Otherwise, don't show
            });
            return menu.Items.length > 0; // Return true if "Tenants" is available
          }
        }

        // Configurations
        if (menu.menutitle === "Configurations" && accessPermissions.manageConfigurations === "1") return true;

        // Admin Settings
        if (menu.menutitle === "Admin Settings") {
          return (
            accessPermissions.manageUsers === "1" ||
            accessPermissions.manageUserProfile === "1" ||
            accessPermissions.accessLogs === "1" ||
            accessPermissions.manageScheduleReports === "1" ||
            accessPermissions.tenantPortalManage === "1"
          );
        }

        return false; // Default case if no permissions match
      })
      .map((menu) => {
        // Further filtering for Admin Settings after initial filter
        if (menu.menutitle === "Admin Settings") {
          menu.Items = menu.Items.filter((item) => {
            if (item.title === "User Profiles" && accessPermissions.manageUserProfile === "1") return true;
            if (item.title === "Users" && accessPermissions.manageUsers === "1") return true;
            if (item.title === "Logs" && accessPermissions.accessLogs === "1") return true;
            if (item.title === "Schedule Reports" && accessPermissions.manageScheduleReports === "1") return true;
            if (item.title === "Tenant Portal" && accessPermissions.tenantPortalManage === "1") return true;
            return false;
          });
        }

        if (menu.menutitle === "General Settings" && accessPermissions.accessGeneralSettings !== "1" && accessPermissions.manageGeneralSettings !== "1") {
          // Ensure only Tenants are shown if no general settings access
          menu.Items = menu.Items.filter((item) => {
            return item.title === "Tenants" && (accessPermissions.accessTenants === "1" || accessPermissions.manageTenants === "1");
          });
        }

        return menu;
      });
  };





  const [mainmenu, setMainMenu] = useState(MenuCustom);

  useEffect(() => {
    let selectedMenu;
    switch (userType) {
      case "admin":
        selectedMenu = MenuAdmin;
        break;
      case "Backend User":
        selectedMenu = filterMenuItems(MenuAdmin);
        break;
      case "sadmin":
        selectedMenu = MenuSadmin;
        break;
      case "tenant":
        selectedMenu = MenuTenant;
        break;
      default:
        selectedMenu = filterMenuItems(MenuCustom);
    }
    setMainMenu(selectedMenu);
  }, [userType]);

  const [searchValue, setsearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(false);
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);
  const { customizer } = useContext(CustomContext);

  const layout_type = customizer.settings.layout_type;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];

    mainmenu.map((menuItems) => {
      menuItems.Items.filter((mItems) => {
        if (mItems.title.toLowerCase().includes(keyword) && mItems.type === 'link') {
          items.push(mItems);
        }
        if (!mItems.children) return false;
        mItems.children.filter((subItems) => {
          if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
            subItems.icon = mItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((suSubItems) => {
            if (suSubItems.title.toLowerCase().includes(keyword)) {
              suSubItems.icon = mItems.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        checkSearchResultEmpty(items);
        setsearchValue(items);
        return mItems;
      });
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector('.empty-menu').classList.add('is-open');
    } else {
      setSearchResultEmpty(false);
      document.querySelector('.empty-menu').classList.remove('is-open');
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector('.Typeahead-menu').classList.add('is-open');
    document.body.classList.add(`${layout_type}`);
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue('');
    document.querySelector('.Typeahead-menu').classList.remove('is-open');
    document.body.classList.add(`${layout_type}`);
    document.body.classList.remove('offcanvas');
  };

  return (
    <Fragment>
      <div className={`page-header ${toggleIcon ? 'close_icon' : ''}`}>
        <Row className='header-wrapper m-0'>
          <Form className='form-inline search-full col' action='#' method='get'>
            <div className='form-group w-100'>
              <div className='Typeahead Typeahead--twitterUsers'>
                <div className='u-posRelative'>
                  <input className='Typeahead-input form-control-plaintext w-100' id='demo-input' type='search' placeholder='Search Cuba ..' defaultValue={searchValue} onChange={(e) => handleSearchKeyword(e.target.value)} />
                  <div className='spinner-border Typeahead-spinner' role='status'>
                    <span className='sr-only'>{Loading}</span>
                  </div>
                  <X className='close-search' onClick={() => document.querySelector('.search-full').classList.remove('open')} />
                </div>
                <div className='Typeahead-menu' id='search-outer'>
                  <div className='header-search-suggestion custom-scrollbar'>
                    {searchValue
                      ? searchValue.map((data, index) => {
                        return (
                          <div className='ProfileCard u-cf' key={index}>
                            <div className='ProfileCard-details'>
                              <div className='ProfileCard-realName'>
                                <Link to={data.path + '/' + layout} className='realname  w-100 d-flex justify-content-start gap-2' onClick={removeFix}>
                                  <SvgIcon style={{ width: '16px', height: '16px' }} className='stroke-icon' iconId={`stroke-${data.icon}`} />
                                  <SvgIcon style={{ width: '16px', height: '16px' }} className='fill-icon' iconId={`fill-${data.icon}`} />
                                  {data.title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : ''}
                  </div>
                </div>
                <div className='Typeahead-menu empty-menu'>
                  <div className='tt-dataset tt-dataset-0'>
                    <div className='EmptyMessage'>{'Opps!! There are no result found.'}</div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <Leftbar />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;

