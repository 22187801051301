import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Breadcrumbs, Btn } from '../../../../AbstractElements'
import { Add, Cancel } from '../../../../Constant';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label } from 'reactstrap';
import { toast } from "react-toastify";
import axios from 'axios';
import config from '../../../../config';

const NewSubCategory = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");

    if (manageGeneralSettings !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();

    const [subcategory_name, setName] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_id = localStorage.getItem("loginId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            subcategory_name,
            subcategory_concessionaire_id: concessionaire_id,
            category_id,
            login_id
        };

        axios
            .post(`${config.hostname}/subcategory`, data, configheader)
            .then(() => {
                toast.success("Successfully Added");
                setName('');
                setCategoryId('');
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    };

    return (
        <Fragment>
            <Breadcrumbs parent='Category' title='Add New Subcategory' mainTitle='Add New Subcategory' />
            <Container fluid={true}>
                <Row>
                    <Col sm='12'>
                        <Card>
                            <CardBody>
                                <Form className='theme-form' onSubmit={handleSubmit} >
                                    <Fragment>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Category</Label>
                                                    <select name="category" id="category" className="form-control digits" required
                                                        value={category_id} onChange={handleCategoryChange}>
                                                        <option value="">Select Category</option>
                                                        {categories.map(category => (
                                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                                        ))}
                                                    </select>
                                                    <span style={{ color: 'red' }}>{errors.title && 'Group is required'}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Subcategory Name</Label>
                                                    <input className="form-control" type="text" name="subcategory" placeholder="Subategory Name"
                                                        value={subcategory_name} onChange={(e) => setName(e.target.value)} required
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.category && 'Subcategory name is required'}</span>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </Fragment>
                                    <Row>
                                        <Col>
                                            <div className='text-end'>
                                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >{Add} </Btn>
                                                <Link to={`${process.env.PUBLIC_URL}/admin/subcategory/viewsubcategory/:layout`}>
                                                    <Btn attrBtn={{ color: 'danger' }}>{Cancel}</Btn>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default NewSubCategory