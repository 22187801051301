import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Spinner } from '../../../../AbstractElements';
import { Card, CardHeader, CardBody, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const SadminLogView = () => {
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('');
    const [userProfileId, setUserProfileId] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [reportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const loginId = localStorage.getItem("loginId")
    const today = new Date().toISOString().split('T')[0];

    // const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/users/getusertypeid`, { user_type: 'Backend User' }, configheader)
            .then((response) => {
                setUserProfileId(response.data.userTypeId);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    useEffect(() => {
        if (userProfileId !== '') {
            axios
                .post(`${config.hostname}/users/getusersbytype`, { user_type_id: userProfileId }, configheader)
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [userProfileId]);

    const pageCount = Math.ceil(reportData.length / itemsPerPage);
    const currentPageData = reportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleUserChange = (e) => {
        // console.log('user id:' + e.target.value)
        setUserId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const generateReport = async (e) => {
        e.preventDefault();

        if (endDate < startDate) {
            setValidationMessage('End date cannot be less than start date');
            return;
        } else {
            setValidationMessage('');
        }

        const data = {
            start_date: startDate,
            end_date: endDate,
            created_by: userId
        };
        setLoading(true)
        axios
            .post(`${config.hostname}/log/logsadmin`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                insertToLog(" generated log report")
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            module: "Super Admin",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }



    const handleExport = (format) => {

        if (reportData.length > 0) {
            const formattedReportData = reportData.map((item) => ({
                ...item,
                created_date: moment(item.created_date).format('DD/MM/YYYY HH:mm:ss'),
                'created_by.userFirstName': item.created_by?.userFirstName || '', // Safely access nested field
            }));

            const metadata = [
                ['From: ', startDate, ' To:', endDate],

            ];
            const reportColumns = [
                { header: 'Date', key: 'created_date' },
                { header: 'Module', key: 'module' },
                { header: 'Created By', key: 'created_by.userFirstName' },
                { header: 'Activity', key: 'task' },
            ];
            if (format === 'excel') {
                exportToExcel("Log Report", metadata, reportColumns, formattedReportData, "SadminLogReport.xlsx");
                insertToLog(" exported log report to excel")
            } else if (format === 'pdf') {
                exportToPDF("Log Report", metadata, reportColumns, formattedReportData, "SadminLogReport.pdf");
                insertToLog(" exported log report to pdf")
            }
        }
    };

    const exportToExcel = (reportHeading, metadata, reportColumns, reportData, fileName) => {
        // Prepare the data based on report columns
        const exportData = reportData.map(item => {
            const formattedItem = {};
            reportColumns.forEach(column => {
                formattedItem[column.header] = column.format ? column.format(item[column.key]) : item[column.key];
            });
            return formattedItem;
        });

        // Calculate totals
        const totalTransactionCount = reportData.reduce((sum, item) => sum + item.total_transaction_count, 0);
        const totalNetSales = reportData.reduce((sum, item) => sum + item.total_net_sales, 0);

        // Add totals row
        const totalsRow = reportColumns.reduce((acc, column) => {
            if (column.key === 'total_transaction_count') {
                acc[column.header] = totalTransactionCount;
            } else if (column.key === 'total_net_sales') {
                acc[column.header] = totalNetSales.toLocaleString();
            } else {
                acc[column.header] = '';
            }
            return acc;
        }, {});

        exportData.push(totalsRow);

        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([[]]);

        // Add the heading
        XLSX.utils.sheet_add_aoa(worksheet, [[reportHeading]], { origin: 'A1' });

        // Apply the heading style manually
        worksheet['A1'] = {
            t: 's',
            v: reportHeading,
            s: {
                font: { sz: 14, bold: true },
                alignment: { horizontal: 'center' }
            }
        };

        // Merge the heading cells
        worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: reportColumns.length - 1 } }];

        // Add metadata to the worksheet below the heading
        const metadataArray = metadata.map(item => [Array.isArray(item) ? item.join(': ') : item]);
        XLSX.utils.sheet_add_aoa(worksheet, metadataArray, { origin: 'A2' });

        // Add and style column headers
        const columnHeaders = [reportColumns.map(column => column.header)];
        XLSX.utils.sheet_add_aoa(worksheet, columnHeaders, { origin: `A${metadata.length + 2}` });

        // Add the data below the column headers
        XLSX.utils.sheet_add_json(worksheet, exportData, { origin: `A${metadata.length + 3}`, skipHeader: true });

        // Auto adjust column widths
        const columnWidths = reportColumns.map((column, index) => {
            const maxLength = Math.max(
                ...exportData.map(item => (item[column.header] ? item[column.header].toString().length : 0)),
                column.header.length
            );
            return { wch: maxLength + 2 }; // Add some padding for better readability
        });
        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
        XLSX.writeFile(workbook, fileName);
    };


    const exportToPDF = (reportHeading, metadata, reportColumns, reportData, fileName, landscape = false) => {

        const orientation = landscape ? 'landscape' : 'portrait';
        const doc = new jsPDF({
            orientation: orientation
        });

        // Add a heading
        doc.setFontSize(13);
        doc.setFont('helvetica', 'bold');
        doc.text(reportHeading, doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });

        // Reset font for metadata
        doc.setFontSize(8);
        doc.setFont('helvetica', 'normal');

        // Add additional information at the top of the PDF with reduced line height
        const lineHeight = 5;  // Reduced line height
        const startX = 10;
        let startY = 20;

        metadata.forEach((item, index) => {
            doc.text(Array.isArray(item) ? item.join(': ') : item, startX, startY + (index * lineHeight));
        });

        // Add a space before the table
        startY += metadata.length * lineHeight + 5;  // Reduced spacing before the table

        // Prepare table headers and rows
        const tableHeaders = reportColumns.map(column => column.header);
        const tableRows = reportData.map(item => reportColumns.map(column => {
            return column.format ? column.format(item[column.key]) : item[column.key];
        }));

        // Calculate totals
        const totalTransactionCount = reportData.reduce((sum, item) => sum + item.total_transaction_count, 0);
        const totalNetSales = reportData.reduce((sum, item) => sum + item.total_net_sales, 0);

        // Add totals row
        const totalsRow = reportColumns.map(column => {
            if (column.key === 'total_transaction_count') {
                return totalTransactionCount;
            } else if (column.key === 'total_net_sales') {
                return totalNetSales.toLocaleString();
            } else {
                return '';
            }
        });

        tableRows.push(totalsRow);

        doc.autoTable({
            startY: startY,
            head: [tableHeaders],
            body: tableRows,
            foot: [totalsRow],
            styles: {
                fontSize: 7,
                cellPadding: 2,
                overflow: 'linebreak'
            },
            headStyles: {
                fontSize: 7
            },
            footStyles: {
                fontSize: 5
            },
            margin: { top: 10, right: 10, bottom: 10, left: 10 },
            columnStyles: {
                // Assuming 'total_net_sales' is at index 10, set its width to prevent line breaks
                10: { cellWidth: 'auto' }
            },
            didDrawCell: function (data) {
                if (data.section === 'foot' && data.column.index === 10) {
                    doc.setFillColor(255, 255, 255); // Reset the background color for the totals row
                }
            }
        });

        doc.save(fileName);
    };


    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" defaultValue={startDate}
                                        max={today} onChange={handleStartDateChange} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">End Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" defaultValue={endDate}
                                        max={today} onChange={handleEndDateChange} required />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">User</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={userId} onChange={handleUserChange}>
                                        <option value="">All</option>
                                        <option value={loginId}>Super Admin</option>
                                        {users.map(user => (
                                            <option key={user._id} value={user._id}>{user.userFirstName}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Card>
                    <CardHeader>
                        <Row className="justify-content-end">
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('excel'),
                                        onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                </Btn>
                                {tooltip === 'Export to Excel' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                            <Col xs="auto" style={{ position: 'relative' }}>
                                <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        onClick: () => handleExport('pdf'),
                                        onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                </Btn>
                                {tooltip === 'Export to PDF' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table>

                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col" >Concessionaire</th>
                                            <th scope="col" >Module</th>
                                            <th scope="col" >Created By</th>
                                            <th scope="col" >Activity</th>
                                            <th scope="col">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{moment(item.created_date).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                <td>{item.concessionaire_id ? item.concessionaire_id.concessionaire_name : ''}</td>
                                                <td>{item.module}</td>
                                                <td>{item.created_by.userFirstName}</td>
                                                <td>{item.task}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {reportData.length > 50 && (
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                />
                            )}
                        </Col>
                    </CardBody>
                </Card>
            )}
        </Fragment>
    )
}

export default SadminLogView