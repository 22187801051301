import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Btn } from '../../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';

const style2 = { width: 60, fontSize: 14, padding: 4 };

const CountryTable = () => {

    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");

    if ((manageGeneralSettings !== "1" && accessGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();


    const concessionaire_id = localStorage.getItem("concessionaireId");
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };
    useEffect(() => {
        axios
            .post(`${config.hostname}/country/getcountrybyconcessionaire`, { country_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);



    const countryColumns = [

        {
            name: 'Country Name',
            selector: (row) => row.country_name,
            sortable: true,
            center: false,
            wrap: true,
            minWidth: '300px',
        },
        {
            name: 'Country Code',
            selector: (row) => row.country_code,
            sortable: true,
            center: false,
            minWidth: '200px',
            maxWidth: '250px',
        },
        {
            name: 'Currency',
            selector: (row) => row.country_currency,
            sortable: true,
            center: false,
            minWidth: '120px',
            maxWidth: '150px',
        },]

    if (manageGeneralSettings === '1') {
        countryColumns.push({
            name: 'Action',
            cell: (row) => (
                <div>
                    <span><Btn attrBtn={{ style: style2, color: 'primary', className: 'fa fa-pencil', type: 'button', size: 'sm', onClick: () => handleEditClick(row) }}>Edit </Btn></span>
                </div>
            ),
            sortable: false,
            center: false,
            minWidth: '300px',
            maxWidth: '360px',
        });
    }

    const handleViewClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/country/countrydetail/${row._id}`);

    }
    const handleEditClick = (row) => {
        navigate(`${process.env.PUBLIC_URL}/admin/country/editcountry/${row._id}`);

    }

    const handleAddClick = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/country/addcountry/default`);

    }


    return (
        <Fragment>
            {manageGeneralSettings === "1" && (
                <div className="text-end mb-3">
                    <Btn attrBtn={{ color: "primary", className: "fa fa-plus", marginLeft: '10px', onClick: () => handleAddClick() }}  >Add New Country</Btn>
                </div>
            )}
            <div className='table-responsive product-table'>
                <DataTable
                    pagination columns={countryColumns}
                    data={countries}
                    highlightOnHover={true}
                    striped={true}
                    responsive={true}
                    onRowClicked={(row) => handleViewClick(row)}>
                </DataTable>
            </div>
        </Fragment>
    );
}

export default CountryTable