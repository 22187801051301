import React, { Fragment } from 'react'
import { Breadcrumbs } from '../../../../AbstractElements';
import { Container } from 'reactstrap';
import ErrorLogView from './ErrorLogView';

const ErrorLog = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Settings" title="Error Log" mainTitle="Error Log" />
            <Container fluid={true}>
                <ErrorLogView />
            </Container>
        </Fragment>
    )
}

export default ErrorLog