import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Input, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";
import config from "../../../../config";

import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";
import SalesComparisonMom from "./SalesComparisonMom";
import YearOnYearTable from "./YearOnYearTable";
import MonthOnMonthTable from "./MonthOnMonthTable";
import TopFiveTenants from "./TopFiveTenants";
import Top5Categories from "./Top5Categories";
import TradeDensity from "./TradeDensity";
import DeliveryPartnerSales from "./DeliveryPartnerSales";
import RestaurantSales from "./RestaurantSales";
import SubcategorySales from "./SubcategorySales";
import Joyride from 'react-joyride';

const DashboardAdmin = () => {

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const [properties, setProperties] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPropertyMallCode, setSelectedPropertyMallCode] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState('');
  const concessionaire_id = localStorage.getItem("concessionaireId");
  const property_id = localStorage.getItem("propertyAccess");
  const showGuideTour = localStorage.getItem("showGuideTour");

  const token = localStorage.getItem("sessiontoken");
  const configheader = {
    headers: {
      Authorization: `${token}`
    }
  };


  useEffect(() => {
    axios
      .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id }, configheader)
      .then((response) => {
        setProperties(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
    console.log('Selected Year:' + e.target.value);

  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value)
  };


  const handlePropertyChange = (e) => {
    setSelectedPropertyMallCode(e.target.value)
    const selectedproperty = properties.filter(property => property.property_mall_code === e.target.value);
    const propertyId = selectedproperty._id
    setSelectedPropertyId(propertyId)
  };


  const tourSteps = [
    {
      target: '.filters-select', // Add class for year selection
      content: 'Select the filters to view the dashboard according to your preferences.',
    },
    {
      target: '.widgets-wrapper', // Widgets wrapper
      content: 'This section provides key sales and revenue metrics.',
    },
    {
      target: '.sales-comparison', // Sales Comparison
      content: 'Compare sales month-on-month for a quick performance overview.',
    },
    {
      target: '.mom-comparison', // MOM Table
      content: 'Here is the month-on-month sales analysis to understand the percentage of growth compared to the previous month.',
    },
    {
      target: '.yoy-comparison', // Year-on-Year Table
      content: 'View year-on-year comparisons to track long-term performance.',
    },
    {
      target: '.category-sales', // Category Sales
      content: 'View sales by subcategory with filter options by category.',
    },
    {
      target: '.top-tenants', // Top 5 Tenants
      content: 'See the top 5 tenants by sales or growth percentage.',
    },
    {
      target: '.top-categories', // Top 5 Categories
      content: 'These are the top 5 categories by sales or growth percentage',
    },
    {
      target: '.trading-density', // Trade Density
      content: 'This highlights the trend in trading density.',
    },
    {
      target: '.fb-sales',
      content: 'This shows the dine-in and dine-out sales for F&B tenants.',
    },
    {
      target: '.delivery-sales',
      content: 'Here, you can see the sales of each delivery partner.',
    },

  ];


  return (
    <Fragment>
      {showGuideTour === "1" && (
        <Joyride
          steps={tourSteps}
          continuous
          showProgress
          showSkipButton
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <Card>
        <CardBody>
          <Row className="filters-select" >
            <span className="text-end" style={{ fontSize: '0.9em', color: '#777', marginLeft: '10px' }}> *All values are in millions</span>
            <Col md='4' >
              <Input className="form-control form-control-primary-fill" name="select" type="select"
                onChange={handleYearChange}>
                <option value="">Select Year</option>
                <option value="">All</option>
                <option value={currentYear}>{currentYear}</option>
                <option value={previousYear}>{previousYear}</option>
              </Input>
            </Col>
            <Col md='4'>
              <Input className="form-control form-control-success-fill" name="select" type="select"
                onChange={handleMonthChange}>
                <option value="">Select Month</option>
                <option value="">All</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </Input>
            </Col>
            <Col md='4'>
              <Input className="form-control form-control-info-fill" name="select" type="select"
                onChange={handlePropertyChange}>
                <option value="">Select Property</option>
                <option value="">All</option>
                {properties.map((property) => (
                  <option key={property.property_mall_code} value={property.property_mall_code}>
                    {property.property_name}
                  </option>
                ))}
              </Input>
            </Col>

          </Row>
        </CardBody>
      </Card>
      {/* </Breadcrumbs> */}
      <Container fluid={true}>
        <Row className="widget-grid">
          <GreetingCard />
          <WidgetsWrapper year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} propertyId={selectedPropertyId} className='widgets-wrapper' />
          <div className="sales-comparison">
            <SalesComparisonMom year={selectedYear} propertyMallCode={selectedPropertyMallCode} />
          </div>
          <div className="mom-comparison">
            <MonthOnMonthTable year={selectedYear} propertyMallCode={selectedPropertyMallCode} />
          </div>
          <YearOnYearTable year={selectedYear} propertyMallCode={selectedPropertyMallCode} className={'yoy-comparison'} />
          <SubcategorySales year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} className={'category-sales'} />
          <TopFiveTenants year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} className="top-tenants" />
          <Top5Categories year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} className="top-categories" />
          <div className="trading-density">
            <TradeDensity year={selectedYear} propertyMallCode={selectedPropertyMallCode} />
          </div>
          <RestaurantSales year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} className='fb-sales' />
          <DeliveryPartnerSales year={selectedYear} month={selectedMonth} propertyMallCode={selectedPropertyMallCode} className='delivery-sales' />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DashboardAdmin;
