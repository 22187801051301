import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import CountryEdit from './CountryEdit';

const EditCountry = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle='View Group' parent='Group' title='Group Detail' />
            <Container fluid={true}>
                <div className='edit-profile'>
                    <Row>
                        <CountryEdit />
                    </Row>
                </div>
            </Container>
        </Fragment>
    )
}

export default EditCountry