export const MenuSadmin = [

    {
        menutitle: "DashBoard",
        menucontent: "Ready to use Apps",
        Items: [
            { path: `${process.env.PUBLIC_URL}/controlpanel/dashboard/default`, icon: "file", title: "Default", type: "link" },
        ],
    },
    {
        menutitle: "Concessionaire",
        menucontent: "Ready to use Apps",
        Items: [

            { path: `${process.env.PUBLIC_URL}/controlpanel/concessionaire/viewconcessionaire`, icon: "file", title: "Concessionaires", type: "link" },
        ],


    },

    {
        menutitle: "Admin Settings",
        menucontent: "Ready to use Apps",
        Items: [

            { path: `${process.env.PUBLIC_URL}/controlpanel/users`, icon: "user", title: "Users", type: "link" },
            { path: `${process.env.PUBLIC_URL}/controlpanel/log`, icon: "project", title: "Log", type: "link" },
            { path: `${process.env.PUBLIC_URL}/controlpanel/errorlog`, icon: "button", title: "Error Log", type: "link" },
        ],
    },



];
