import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Widgets2Data, Widgets2Data2, WidgetsData, WidgetsData2, WidgetsData3, WidgetsData4 } from '../../../../Data/DefaultDashboard';
import { Widgets2ChartData, Widgets2ChartData2, Widgets2ChartData2Widgets, Widgets2ChartDataWidgets } from '../../../../Data/DefaultDashboard/Chart';
import Widgets1 from '../../../Common/CommonWidgets/Widgets1';
import Widgets2 from '../../../Common/CommonWidgets/Widgets2';
import axios from 'axios';
import config from '../../../../config';


const WidgetsWrapper = ({ year, month, propertyMallCode, propertyId, className }) => {

  const token = localStorage.getItem("sessiontoken");
  const configheader = {
    headers: {
      Authorization: `${token}`
    }
  };
  const [total_tenants, setTotalTenants] = useState('');
  const [current_sales, setCurrentSales] = useState('');
  const [previous_sales, setPreviousSales] = useState('');
  const [growth_percentage, setGrowthPercentage] = useState('');
  const [current_revenue, setCurrentRevenue] = useState('');
  const [previous_revenue, setPreviousRevenue] = useState('');
  const [growth_revenue, setGrowthRevenue] = useState('');
  const concessionaire_id = localStorage.getItem("concessionaireId");
  const property_id = localStorage.getItem("propertyAccess");

  const [apiUrl, setApiUrl] = useState('');
  const [apiData, setApiData] = useState([]);
  const currentYear = year === '' ? new Date().getFullYear() : year;
  const previousYear = currentYear - 1;

  useEffect(() => {
    if (year !== '' || month !== '' || propertyMallCode !== '') {
      setApiUrl('dashboardfiltered')
      const data1 = {
        concessionaire_id,
        property_id,
        year,
        month,
        propertyMallCode,
        propertyId
      }
      setApiData(data1)
    }
    else {
      setApiUrl('dashboard')
      const data1 = {
        concessionaire_id,
        property_id
      }
      setApiData(data1)
    }
  }, [year, month, propertyMallCode, propertyId]);

  useEffect(() => {
    if (apiUrl !== '') {
      axios
        .post(`${config.hostname}/${apiUrl}`, apiData, configheader)
        .then((response) => {
          setTotalTenants(response.data.totalTenants);
          setCurrentSales(response.data.totalSales);
          setPreviousSales(response.data.previousYearSales);
          setGrowthPercentage(response.data.growthpercentage);

          setCurrentRevenue(response.data.currentYearRevenue);
          setPreviousRevenue(response.data.previousYearRevenue);
          setGrowthRevenue(response.data.growthRevenue);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [apiUrl, apiData]);


  const WidgetsData = {
    title: 'Total Tenants',
    // gros: 50,
    total: total_tenants,
    color: 'secondary',
    icon: 'customers',
  };
  const WidgetsData2 = {
    title: 'Traffic',
    // gros: 20,
    total: '-',
    color: 'warning',
    icon: 'user-visitor',
  };
  const WidgetsData3 = {
    title: 'Sales ' + currentYear,
    gros: growth_percentage,
    total: current_sales,
    color: 'primary',
    icon: 'profit',
  };
  const WidgetsData4 = {
    title: 'Sales ' + previousYear,
    // gros: 70,
    total: previous_sales,
    color: 'success',
    icon: 'profit',
  };
  const WidgetsData1WidgetsPage = [
    {
      title: 'Purchase',
      gros: 50,
      total: 10_000,
      color: 'secondary',
      icon: 'cart',
    },
    {
      title: 'Sales return',
      gros: 20,
      total: 7000,
      color: 'warning',
      icon: 'return-box',
    },
    {
      title: 'Sales',
      gros: 70,
      total: 4_200,
      color: 'primary',
      icon: 'tag',
    },
    {
      title: 'Purchase rate',
      gros: 70,
      total: 5700,
      color: 'success',
      icon: 'rate',
    },
  ];

  const Widgets2Data = {
    title: 'Revenue ' + currentYear,
    total: current_revenue,
    gros: growth_revenue,
    color: 'primary',
    icon: 'sale',
  };
  const Widgets2Data2 = {
    title: 'Revenue ' + previousYear,
    total: previous_revenue,
    gros: '',
    color: 'primary',
    icon: 'sale',
  };
  const Widgets2DataWidgets = {
    title: 'Orders',
    total: '1,80k',
    chart: Widgets2ChartDataWidgets,
  };
  const Widgets2Data2Widgets = {
    title: 'Profit',
    total: '6,90k',
    chart: Widgets2ChartData2Widgets,
  };

  const LightCardData = [
    {
      title: 'Income',
      icon: 'income',
      price: '$22,678',
      gros: '+$456',
      color: 'success',
    },
    {
      title: 'Expense',
      icon: 'expense',
      price: '$12,057',
      gros: '+$256',
      color: 'danger',
    },
    {
      title: 'Cashback',
      icon: 'doller-return',
      price: '$8,475',
    },
  ];
  const LightCardData2 = [
    {
      title: 'Orders',
      icon: 'orders',
      price: '10,098',
      color: 'success',
    },
    {
      title: 'Earning',
      icon: 'expense',
      price: '$12,057',
      color: 'danger',
    },
    {
      title: 'Refunds',
      icon: 'doller-return',
      price: '3,001',
    },
  ];

  const ActivityData = [
    {
      title: 'Updated Product',
      subTitle: '8th March, 2022',
      time: '1 day ago',
      dis: 'Quisque a consequat ante sit amet magna...',
      color: 'primary',
    },
    {
      title: 'Tello just like your product',
      subTitle: '15th Oct, 2022 ',
      time: 'Today',
      dis: 'Quisque a consequat ante sit amet magna...',
      color: 'warning',
    },
    {
      title: 'Tello just like your product',
      subTitle: '20th Sep, 2022',
      time: '12:00 PM',
      dis: 'Quisque a consequat ante sit amet magna...',
      color: 'secondary',
    },
  ];

  const RecentSalesData = [
    {
      image: '1.jpg',
      title: 'Jane Cooper',
      subTitle: '10 minutes ago',
      badge: '$200.00',
    },
    {
      image: '2.jpg',
      title: 'Brooklyn Simmons',
      subTitle: '19 minutes ago',
      badge: '$970.00',
    },
    {
      image: '3.jpg',
      title: 'Leslie Alexander',
      subTitle: '2 hours ago',
      badge: '$300.00',
    },
    {
      image: '4.jpg',
      title: 'Travis Wright',
      subTitle: '8 hours ago',
      badge: '$450.00',
    },
    {
      image: '5.jpg',
      title: ' Mark Green',
      subTitle: '1 day ago',
      badge: '$768.00',
    },
  ];

  const NoteLabels = [
    {
      title: 'SAAS',
      color: 'primary',
    },
    {
      title: 'E-Commerce',
      color: 'success',
    },
    {
      title: 'Crypto',
      color: 'warning',
    },
    {
      title: 'Project',
      color: 'info',
    },
    {
      title: 'NFT',
      color: 'secondary',
    },
    {
      title: '+9',
      color: 'light',
    },
  ];
  return (
    <>
      <Col xxl='auto' xl='3' sm='6' className={`box-col-6 ${className}`}>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData2} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='3' sm='6' className={`box-col-6 ${className}`}>
        <Row>
          <Col xl='12'>
            <Widgets1 data={WidgetsData3} />
          </Col>
          <Col xl='12'>
            <Widgets1 data={WidgetsData4} />
          </Col>
        </Row>
      </Col>
      <Col xxl='auto' xl='12' sm='6' className={`box-col-6 ${className}`}>
        <Row>
          <Col xxl='12' xl='6' className='box-col-12'>
            <Widgets1 data={Widgets2Data} />
          </Col>
          <Col xxl='12' xl='6' className='box-col-12'>
            <Widgets1 chartClass='profit-chart ' data={Widgets2Data2} />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default WidgetsWrapper;
