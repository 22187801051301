import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { H6, Image } from '../../../AbstractElements';
import fireImage from '../../../assets/images/giftools.gif';
import graphImage from '../../../assets/images/egoalimg/graphimg.gif';
import graph2 from '../../../assets/images/egoalimg/graph3.gif'
import Slider from 'react-slick';
import axios from 'axios';
import config from '../../../config';
import { notificationSliderOption } from './NotificationSliderOption';

const NotificationSlider = () => {

  const concessionaire_id = localStorage.getItem("concessionaireId");

  const [dayTopperName, setDayTopperName] = useState('')
  const [dayTopperSales, setDayTopperSales] = useState('')
  const [monthTopperName, setMOnthTopperName] = useState('')
  const [monthTopperSales, setMonthTopperSales] = useState('')
  const [yearTopperName, setYearTopperName] = useState('')
  const [yearTopperSales, setYearTopperSales] = useState('')

  const token = localStorage.getItem("sessiontoken");
  const configheader = {
    headers: {
      Authorization: `${token}`
    }
  };

  useEffect(() => {
    axios
      .post(`${config.hostname}/dashboard/topperformer`, { concessionaire_id }, configheader)
      .then((response) => {
        const { topPerformerYesterday, topPerformerMonth, topPerformerYear } = response.data;
        if (topPerformerYesterday) {
          setDayTopperName(topPerformerYesterday.tenant_name);
          setDayTopperSales(topPerformerYesterday.totalNetSales);
        }

        if (topPerformerMonth) {
          setMOnthTopperName(topPerformerMonth.tenant_name);
          setMonthTopperSales(topPerformerMonth.totalNetSales);
        }

        if (topPerformerYear) {
          setYearTopperName(topPerformerYear.tenant_name);
          setYearTopperSales(topPerformerYear.totalNetSales);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <div className='notification-slider overflow-hidden '>
      <Slider className='m-0' {...notificationSliderOption}>
        <div className='d-flex h-100'>
          <Image attrImage={{ src: graph2, alt: 'gif' }} />
          <H6 attrH6={{ className: 'mb-0 f-w-400  ms-1' }}>
            <span className='f-light'>Top performer of the year is </span>
            <span className='font-primary '> {` ${yearTopperName} `} </span>
            <span className='f-light'>with the sale of  </span>
            <span className='font-primary '> {` ${yearTopperSales} `}Millions </span>
          </H6>
          {/* <i className='icon-arrow-top-right f-light' /> */}
        </div>
        <div className='d-flex h-100'>
          <Image attrImage={{ src: graph2, alt: 'gif' }} />
          <H6 attrH6={{ className: 'mb-0 f-w-400  ms-1' }}>
            <span className='f-light'>Top performer of the month is </span>
            <span className='font-primary '> {` ${monthTopperName} `} </span>
            <span className='f-light'>with the sale of  </span>
            <span className='font-primary '> {` ${monthTopperSales} Millions`} </span>
          </H6>
        </div>
        <div className='d-flex h-100'>
          <Image attrImage={{ src: graph2, alt: 'gif' }} />
          <H6 attrH6={{ className: 'mb-0 f-w-400  ms-1' }}>
            <span className='f-light'>Top Performer of the day is</span>
            <span className='font-primary '> {` ${dayTopperName} `} </span>
            <span className='f-light'>with the sale of  </span>
            <span className='font-primary '> {` ${dayTopperSales} Millions`} </span>
          </H6>
        </div>
      </Slider>
    </div>
  );
};

export default NotificationSlider;
