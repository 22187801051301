import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Input, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";
import config from "../../../../config";
import WidgetsWrapper from "./WidgetsWraper";
import SalesComparison from "./SalesComparison";
import MonthOnMonthTable from "./MonthOnMonthTable";

const TenantDashboard = () => {

    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const [tenants, setTenants] = useState([]);
    const [tenantName, setTenantName] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id }, configheader)
            .then((response) => {
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleTenantChange = (e) => {
        const selectedTenantId = e.target.value;
        const selectedTenant = tenants.find(tenant => tenant._id === selectedTenantId);

        if (selectedTenant) {
            setTenantId(selectedTenant._id);
            setTenantName(selectedTenant.tenant_store_name);
        } else {
            setTenantId('');
            setTenantName('');
        }
    };

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <Row >
                        <span className="text-end" style={{ fontSize: '0.9em', color: '#777', marginLeft: '10px' }}> *All values are in millions</span>
                        <Col md='4' >
                            <Input className="form-control form-control-primary-fill" name="select" type="select"
                                value={tenantId} onChange={handleTenantChange}>
                                <option value="">Select Tenant</option>
                                {tenants.map(tenant => (
                                    <option key={tenant._id} value={tenant._id}>
                                        {`${tenant.tenant_store_name} - ${tenant.tenant_property_id?.property_name || ' '}`}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col md='4'>
                            <Input className="form-control form-control-success-fill" name="startDate" type="date"
                                onChange={(e) => setStartDate(e.target.value)}>
                            </Input>
                        </Col>
                        <Col md='4'>
                            <Input className="form-control form-control-info-fill" name="endDate" type="date"
                                onChange={(e) => setEndDate(e.target.value)}>
                            </Input>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
            <Container fluid={true}>
                <Row className="widget-grid">
                    <WidgetsWrapper tenantId={tenantId} startDate={startDate} endDate={endDate} />
                    <SalesComparison tenantId={tenantId} />
                    <MonthOnMonthTable tenantId={tenantId} />
                </Row>
            </Container>
        </Fragment>
    )
}

export default TenantDashboard