import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Btn, Spinner } from '../../../AbstractElements';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import axios from 'axios';
import config from '../../../config';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


const DataGridView = () => {
    if (localStorage.getItem("accessDataGrid") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const gridRef = useRef(null);
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const today = new Date().toISOString().split('T')[0];
    const [validationMessage, setValidationMessage] = useState('');
    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairedeliverypartner/getdeliverypartner`, { concessionaire_id }, configheader)
            .then((response) => {
                const deliveryPartnerNames = response.data.map(item => item.delivery_partner_id.delivery_partner_name)
                    .filter(name => name !== "Dine In");
                setDeliveryPartners(deliveryPartnerNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const generateReport = async (e) => {
        e.preventDefault();
        if (endDate < startDate) {
            setValidationMessage('End date cannot be less than start date');
            return;
        } else {
            setValidationMessage('');
        }

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            start_date: startDate,
            end_date: endDate
        };

        setLoading(true);
        axios
            .post(`${config.hostname}/datagrid/getdatagrid`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false);
                insertToLog(" viewed data grid");
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false);
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Data Grid",
            task: login_name + task
        };
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => console.log("inserted to log table"))
            .catch((error) => {
                toast.error('Error');
                console.log(error);
            });
    };

    const columns = [
        { field: 'tenant_store_name', headerName: 'Tenant Name', width: 150 },
        { field: 'tenant_group_name', headerName: 'Group Name', width: 200 },
        { field: 'tenant_code', headerName: 'Tenant Code', width: 150 },
        { field: 'tenant_unit_code', headerName: 'Unit Code', width: 150 },
        { field: 'tenant_status', headerName: 'Status', width: 150 },
        { field: 'property_name', headerName: 'Property', width: 150 },
        { field: 'subcategory_name', headerName: 'Subcategory', width: 200 },
        { field: 'floor', headerName: 'Floor', width: 100 },
        { field: 'transaction_date', headerName: 'Date', width: 150 },
        { field: 'transaction_count', headerName: 'Transaction Count', width: 150 },
        {
            field: 'net_sales',
            headerName: 'Net Sales',
            width: 150,
            type: 'number',
            valueFormatter: (params) => params?.value != null ? params.value.toFixed(2) : '0.00',
        },
    ];

    deliveryPartners.forEach((partner) => {
        const normalizedPartner = partner.toLowerCase().replace(/\s+/g, '_');
        columns.push({ field: `${normalizedPartner}_count`, headerName: `${partner} Count`, width: 150 });
        columns.push({
            field: `${normalizedPartner}_sales`,
            headerName: `${partner} Sales`,
            width: 150,
            type: 'number',
            valueFormatter: (params) => params?.value != null ? params.value.toFixed(2) : '0.00',
        });
    });

    const exportToCSV = () => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'grid_data.csv', // Name of the CSV file
            columnSeparator: ',' // Optional: You can set a different separator
        });
    };

    const reset = () => {
        setStartDate('')
        setEndDate('')
        setReportData([])
    };

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="startdate">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={startDate}
                                        max={today} onChange={handleStartDateChange} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="enddate">End Date</Label>
                                    <input className="form-control digits"
                                        id="enddate" type="date" name="enddate" value={endDate}
                                        max={today} onChange={handleEndDateChange} required />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Load Data</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
            {loading ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>
            ) : (
                // <div style={{ height: 600, width: '100%' }}>
                //     <DataGrid
                //         rows={reportData}
                //         columns={columns}
                //         pageSize={10}
                //         rowsPerPageOptions={[10, 20, 50]}
                //         pagination
                //         disableSelectionOnClick
                //         sortingOrder={['asc', 'desc']}
                //         getRowId={(row) => row.tenant_code} // Use a unique field as the ID
                //     />
                // </div>

                <div>
                    <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: exportToCSV }} >Export to CSV</Btn>
                    <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={reportData}
                            columnDefs={columns}
                            pagination={true}
                            paginationPageSize={10}
                            gridOptions={{
                                sideBar: {
                                    toolPanels: ['columns'],  // Column selection panel
                                    defaultToolPanel: 'columns'  // Open the "Columns" panel by default
                                },
                                pagination: true,
                                paginationPageSize: 10,
                            }}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                floatingFilter: true,
                                resizable: true,
                            }}
                        />
                    </div>
                </div>

            )}
        </Fragment>
    );
};

export default DataGridView;


