import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import Chart from 'react-apexcharts';
import HeaderCard from '../../../Common/Component/HeaderCard';
import config from '../../../../config';
import axios from 'axios';
import configDB from '../../../../Config/ThemeConfig';

const TradeDensity = ({ year, propertyMallCode }) => {
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const currentYear = new Date().getFullYear();
    const selectedYear = year === '' ? currentYear : year;
    const [currentYearData, setCurrentYearData] = useState([]);
    const [previousYearData, setPreviousYearData] = useState([]);
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState([]);

    const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };


    useEffect(() => {
        if (year !== '' || propertyMallCode !== '') {
            setApiUrl('dashboardfiltered/tradingdensity')
            const data1 = {
                concessionaire_id,
                property_id,
                year,
                propertyMallCode
            }
            setApiData(data1)
        }
        else {
            setApiUrl('dashboard/tradingdensity')
            const data2 = {
                concessionaire_id,
                property_id
            }
            setApiData(data2)
        }
    }, [year, propertyMallCode]);

    useEffect(() => {
        if (apiUrl !== '') {
            axios.post(`${config.hostname}/${apiUrl}`, apiData, configheader)
                .then((response) => {
                    setCurrentYearData(response.data.currentYearTradeDensity);
                    setPreviousYearData(response.data.previousYearTradeDensity);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [apiUrl, apiData]);

    const prepareChartData = () => {
        const months = currentYearData.map(item => item.month);
        const currentYearSeries = currentYearData.map(item => item.tradeDensity);
        const previousYearSeries = previousYearData.map(item => item.tradeDensity);

        return {
            months,
            series: [
                { name: selectedYear, data: currentYearSeries },
                { name: selectedYear - 1, data: previousYearSeries }
            ]
        };
    };


    const { months, series } = prepareChartData();

    const areaSpaline = {
        series: series,
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: [primary, '#1a78d6'], //#a927f9 #7366ff
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'category',
                categories: months
            },
            tooltip: {
                x: {
                    format: 'MMMM'
                },
            },
        },
    };

    return (
        <Fragment>
            <Col sm='12' >
                <Card>
                    <HeaderCard title='Trading Density' />
                    <CardBody>
                        <Chart options={areaSpaline.options} series={areaSpaline.series} height="350" type="area" />
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default TradeDensity