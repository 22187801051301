import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Btn, H4, P } from "../AbstractElements";
import { ForgotPassword, Password, RememberPassword } from "../Constant";

import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";

import CustomizerContext from "../_helper/Customizer";
import OtherWay from "./OtherWay";
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import EgoalIcon from '../assets/images/logo/egoallogo.png';
import { Image } from '../AbstractElements';
import config from '../config';
import configDB from '../Config/ThemeConfig';
import CryptoJS from 'crypto-js';

const Signin = ({ selected }) => {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("loginName"));
  const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus(); // Set focus when the component mounts
  }, []);

  useEffect(() => {
    localStorage.setItem("profileURL", man);
    localStorage.setItem("Name", '');
  }, [value, name]);


  const loginAuth = async (e) => {
    e.preventDefault();
    const encryptedPassword = CryptoJS.AES.encrypt(password, 'S7h^k9Lz*R!8rL1uNb#X@zPQ5uT3gBsH').toString();
    axios.post(`${config.hostname}/auth/login`, { username, password: encryptedPassword })
      // axios.post(`${config.hostname}/auth/login`, { username, password })
      .then(response => {
        localStorage.setItem("login", true);
        localStorage.setItem("sessiontoken", response.data.token);
        localStorage.setItem("usertype", response.data.user_role);
        localStorage.setItem("usertypeId", response.data.user_type_id);
        localStorage.setItem("concessionaireId", response.data.user_concessionaire_id);
        localStorage.setItem("concessionaireName", response.data.user_concessionaire_name);
        localStorage.setItem("loginId", response.data.user_id);
        localStorage.setItem("loginName", response.data.first_name);
        localStorage.setItem("tenantId", response.data.user_tenant_id);
        localStorage.setItem("propertyAccess", response.data.property_id);
        const userTypeId = response.data.user_type_id;
        const token = localStorage.getItem("sessiontoken");
        const configheader = {
          headers: {
            Authorization: `${token}`
          }
        };

        if (response.data.user_role === "admin") { //admin
          localStorage.setItem("accessDashboard", "1")
          localStorage.setItem("accessAnalytics", "1")
          localStorage.setItem("accessReports", "1")
          localStorage.setItem("accessDataGrid", "1")
          localStorage.setItem("accessGeneralSettings", "1")
          localStorage.setItem("manageGeneralSettings", "1")
          localStorage.setItem("accessTenants", "1")
          localStorage.setItem("manageTenants", "1")
          localStorage.setItem("manageConfigurations", "1")
          localStorage.setItem("manageUsers", "1")
          localStorage.setItem("manageUserProfile", "1")
          localStorage.setItem("accessLogs", "1")
          localStorage.setItem("manageScheduleReports", "1")
          localStorage.setItem("tenantPortalManage", "1")
          history(`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layoutURL}`); //admin

        }
        else if (response.data.user_role === "sadmin") {
          history(`${process.env.PUBLIC_URL}/controlpanel/dashboard/default/${layoutURL}`)   //super admin
        }
        else if (response.data.user_role === "tenant") {
          history(`${process.env.PUBLIC_URL}/tenant/home/${layoutURL}`)  //Tenant
        }
        else if (response.data.user_role === "Backend User") {
          history(`${process.env.PUBLIC_URL}/admin/home/${layoutURL}`)  //Backend Users
        }
        else {
          axios.post(`${config.hostname}/userpermissions/getpermissionbyusertype`, { user_type_id: userTypeId }, configheader)
            .then((response) => {
              localStorage.setItem("accessDashboard", response.data[0].access_dashboard)
              localStorage.setItem("accessAnalytics", response.data[0].access_analytics)
              localStorage.setItem("accessReports", response.data[0].access_report)
              localStorage.setItem("accessDataGrid", response.data[0].access_data_grid)
              localStorage.setItem("accessGeneralSettings", response.data[0].acess_general_settings)
              localStorage.setItem("manageGeneralSettings", response.data[0].manage_general_settings)
              localStorage.setItem("accessTenants", response.data[0].access_tenants)
              localStorage.setItem("manageTenants", response.data[0].manage_tenants)
              localStorage.setItem("manageConfigurations", response.data[0].manage_configurations)
              localStorage.setItem("manageUsers", response.data[0].manage_users)
              localStorage.setItem("manageUserProfile", response.data[0].manage_user_profile)
              localStorage.setItem("accessLogs", response.data[0].access_logs)
              localStorage.setItem("manageScheduleReports", response.data[0].manage_schedule_reports)
              localStorage.setItem("tenantPortalManage", response.data[0].manual_portal_access)

              history(`${process.env.PUBLIC_URL}/admin/dashboard/sales/${layoutURL}`);    //admin users
            })
            .catch((error) => {
              console.log(error);
              toast.error("error on fetching permissions");
            });

        }
        setName(response.data.name);
        //toast.success("Successfully logged in!..");
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error');
      });

  };

  const handleEmailClick = async (e) => {
    e.preventDefault();
    axios.post(`${config.hostname}/users/sendotp`, { email })
      .then((response) => {
        if (response.data === 'Email not found') {
          toast.error(response.data)
        }
        setSentEmail(true)
        setVerifyOtp(false)
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error');
      });

  }

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    axios.post(`${config.hostname}/users/verifyotp`, { username: email, otp })
      .then(() => {

        setVerifyOtp(true)
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error');
      });

  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    axios.post(`${config.hostname}/users/resetpassword`, { username: email, newPassword })
      .then(() => {
        toast.success('Password reseted successfully')
        setSentEmail(false);
        setVerifyOtp(false);
        setOtp('');
        setEmail('')
        setNewPassword('')
        toggleModal();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error');
      });

  }


  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  {/* <H4>{selected === "simpleLogin" ? "" : "Sign In With Simple Login"}</H4> */}
                  <Image attrImage={{ className: 'img-fluid d-inline', src: `${EgoalIcon}`, alt: '' }} />
                  {/* <P>{"Enter your email & password to login"}</P> */}
                  <FormGroup>
                    <Label className="col-form-label">Username</Label>
                    <Input className="form-control" type="text" ref={inputRef} onChange={(e) => setUserName(e.target.value)} value={username} />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">Password</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)} value={password}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            if (password === 'egoal@1') {
                              toggleModal(); // reset password for first time login'
                              localStorage.setItem("showGuideTour", "1");
                            } else {
                              localStorage.setItem("showGuideTour", "0");
                              loginAuth(e); // Otherwise, proceed with loginAuth
                            }
                          }
                        }} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                    </div>
                    <a className="link" style={{ cursor: 'pointer' }} onClick={toggleModal}>
                      {ForgotPassword}
                    </a>
                    <Btn attrBtn={{
                      color: "primary", className: "d-block w-100 mt-2", onClick: (e) => {
                        if (password === 'egoal@1') {
                          toggleModal(); // reset password for first time login'
                          localStorage.setItem("showGuideTour", "1");
                        } else {
                          localStorage.setItem("showGuideTour", "0");
                          loginAuth(e);
                        }
                      },
                    }}>Sign In</Btn>
                  </div>
                  <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Reset Password</ModalHeader>
                    <ModalBody>
                      <FormGroup>
                        <Label className="mb-3" for="email">Enter your email address and we'll send you an email with instructions to reset your password.</Label>
                        <Input className="mb-3" type="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                        <Btn attrBtn={{ color: "primary", onClick: (e) => handleEmailClick(e) }}> {sentEmail === true ? 'Resend OTP' : 'Send OTP'}</Btn>
                      </FormGroup>

                      {sentEmail === true && (
                        <Row>
                          <FormGroup>
                            <Label className="mb-3" for="email">Enter OTP</Label>
                            <Input className="mb-3" type="text" id="otp" onChange={(e) => setOtp(e.target.value)} value={otp} placeholder="Enter OTP" />
                            <Btn attrBtn={{ color: "primary", onClick: (e) => handleVerifyOTP(e), disabled: verifyOtp }}>Verify OTP</Btn>
                          </FormGroup>
                        </Row>
                      )}
                      {verifyOtp === true && (
                        <Row>
                          <FormGroup>
                            <Label className="mb-3" for="newpass">Enter New Password</Label>
                            <Input className="mb-3" type="password" id="newPassword" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} placeholder="Enter New Password" />
                            <Btn attrBtn={{ color: "primary", onClick: (e) => handleResetPassword(e) }}>Reset Password</Btn>
                          </FormGroup>
                        </Row>
                      )}
                    </ModalBody>
                  </Modal>
                  {/* <OtherWay /> */}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
